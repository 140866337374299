import PropTypes from 'prop-types';
import { classes } from '@/helpers/styling';

const Spinner = ({
  isCentered, // Should the spinner be centered inside its parent?
  width // Custom width
}) => {
  const style = {};
  const wrapperStyle = {};

  if (width) {
    const topStyle = `calc(50% - ${ width / 2 }px)`;

    wrapperStyle.top = topStyle;
    wrapperStyle.right = topStyle;
    wrapperStyle.bottom = topStyle;
    wrapperStyle.left = topStyle;

    const widthStyle = `${ width }px`;

    style.width = widthStyle;
    style.height = widthStyle;
  }

  return (
    <div id='nprogress-custom'>
      <div
        aria-busy
        aria-live='polite'
        className={ classes('spinner', { '--is-centered': isCentered }) }
        style={ wrapperStyle }
      >
        <div
          className='spinner-icon'
          style={ style }
        />
      </div>
    </div>
  );
};

Spinner.propTypes = {
  isCentered: PropTypes.bool,
  width: PropTypes.number
};

Spinner.defaultProps = {
  isCentered: false,
  width: null
};

export default Spinner;