export const T_LOCALE_NEXT_DEFAULT = 'default';
export const T_LOCALE_ENGLISH = 'en';
export const T_LOCALE_ENGLISH_GB = 'en-GB';
export const T_LOCALE_ENGLISH_US = 'en-US';
export const T_LOCALE_FRENCH = 'fr';
export const T_LOCALE_SPANISH = 'es';

export const T_LOCALE_BASE = T_LOCALE_ENGLISH;

export const T_LOCALES_CONFIG = {
  [T_LOCALE_ENGLISH]: {
    locale: T_LOCALE_ENGLISH,
    displayInLanguageSelector: true
  },
  [T_LOCALE_ENGLISH_US]: {
    locale: T_LOCALE_ENGLISH_US
  },
  [T_LOCALE_ENGLISH_GB]: {
    locale: T_LOCALE_ENGLISH_GB
  },
  [T_LOCALE_FRENCH]: {
    locale: T_LOCALE_FRENCH,
    displayInLanguageSelector: true
  },
  [T_LOCALE_SPANISH]: {
    locale: T_LOCALE_SPANISH,
    displayInLanguageSelector: true
  }
};

export const T_LOCALES_EQUIVALENCY_SETS = [
  [T_LOCALE_ENGLISH, T_LOCALE_ENGLISH_GB],
  [T_LOCALE_ENGLISH, T_LOCALE_ENGLISH_US]
];

const T_LOCALES = Object.values(T_LOCALES_CONFIG).map(({ locale }) => locale);

export const T_LOCALES_LANGUAGE_SELECTOR = Object.values(T_LOCALES_CONFIG)
  .filter(({ displayInLanguageSelector }) => !!displayInLanguageSelector)
  .map(({ locale }) => locale);

export const T_LOCALES_WITH_DEFAULT = [
  ...T_LOCALES,
  T_LOCALE_NEXT_DEFAULT // Used for redirects
];

export default T_LOCALES;
