import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  shallowEqual, useSelector, useDispatch
} from 'react-redux';
import {
  selectKeakieCreateLoginStatus, selectLoginInfo, selectLoginType
} from '@/state/selectors/user';
import { updateFacebookUserInfo } from '@/state/account/actions';
import CONFIG from '@/config/global';
import { LOGIN_TYPE_FACEBOOK } from '@/config/login';

// Move this to environment variables
const appId = CONFIG.facebookAppId;

// Load the facebook SDK using a script tag
const setupFacebookSDK = ({ onSdkLoaded, language }) => {
  const id = 'facebook-jssdk';
  const element = document.getElementsByTagName('script')[0];
  const firstScriptInDOM = element;
  let js = element;

  if (document.getElementById(id)) { return; }

  // Add the script tag for loading the FB SDK
  js = document.createElement('script');
  js.type = 'text/javascript';
  js.id = id;
  js.onload = onSdkLoaded;
  js.rel = 'preload';
  js.src = `https://connect.facebook.net/${ language }/sdk.js`;
  js.preconnect = true;
  // Insert the script in the DOM as the first script
  firstScriptInDOM.parentNode.insertBefore(js, firstScriptInDOM);
};

const fetchFacebookUserInfo = (callback) => {
  window.FB.api(
    '/me',
    { fields: 'email,picture' },
    ({
      error,
      email,
      picture
    }) => callback({
      error,
      email,
      picture
    })
  );
};

const selector = (state) => ({
  keakieCreateLoginStatus: selectKeakieCreateLoginStatus(state),
  userInfo: selectLoginInfo(state),
  loginType: selectLoginType(state)
});

const useFacebookLogin = ({
  xfbml = false,
  cookie = false,
  onLoginLoading = undefined,
  onLoginSuccess = undefined,
  onLoginFailure = undefined,
  versionNumber = '3.1',
  language = 'en_US',
  scope = 'email',
  loginOptions = undefined
} = {}) => {
  const [login, setLogin] = useState(null);
  const [sdkIsLoaded, setSdkIsLoaded] = useState(false);
  const [sdkInitialised, setSdkInitialised] = useState(false);

  const {
    keakieCreateLoginStatus, userInfo, loginType
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const isFacebookLogin = loginType === LOGIN_TYPE_FACEBOOK;
  const userInfoFetched = userInfo.fetched;
  const hasKeakieLogin = keakieCreateLoginStatus.success;

  const loginOptionsToUse = useMemo(() => ({
    scope,
    ...loginOptions
  }), [loginOptions, scope]);

  // Login is loading if we are loading/initialising the SDK or checking the current login status
  const loading = !sdkInitialised || !sdkIsLoaded;

  // Function to call on login failure
  const loginFailureCallback = useCallback((result) => {
    if (onLoginFailure) {
      onLoginFailure(result);
    }
  }, [onLoginFailure]);

  // Function to call on login when not a failure
  const loginCallback = useCallback((result) => {
    setLogin(true);

    if (result.status === 'connected') {
      setLogin(result);

      if (onLoginSuccess) {
        onLoginSuccess(result);
      }
    }
  }, [onLoginSuccess]);

  // Initialise the facebook SDK
  const initialiseFacebookSDK = useCallback(() => {
    window.FB.init({
      version: `v${ versionNumber }`,
      appId,
      xfbml,
      cookie
    });
  }, [cookie, xfbml, versionNumber]);
  
  // Handle the response from login. If successful, fetch the user info
  const handleLoginResponse = useCallback(async ({ authResponse, status }) => {
    if (!authResponse) {
      loginFailureCallback({ status });
    } else {
      fetchFacebookUserInfo(({
        error,
        email,
        picture
      }) => loginCallback({
        status,
        authResponse: {
          ...authResponse,
          ...(error
            ? {}
            : {
              email: email || '',
              profileImgSrc: picture?.data?.url || ''
            }
          )
        }
      }));
    }
  }, [loginCallback, loginFailureCallback]);

  // Login to facebook
  const handleLogin = useCallback(() => {
    if (sdkIsLoaded) {
      onLoginLoading();
    
      window.FB.login(
        loginResponse => handleLoginResponse(loginResponse),
        loginOptionsToUse
      );
    }
  }, [onLoginLoading, loginOptionsToUse, handleLoginResponse, sdkIsLoaded]);

  // Login to facebook
  const handleLogout = useCallback(() => {
    window.FB.logout(
      () => login(null)
    );
  }, [login]);

  // On mounting the provider:
  // 1. Setup the SDK
  // 2. Add the initialisation function for the SDK
  useEffect(() => {
    setupFacebookSDK({
      onSdkLoaded: () => setSdkIsLoaded(true),
      language
    });

    window.fbAsyncInit = () => {
      initialiseFacebookSDK();
      setSdkInitialised(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sdkIsLoaded && isFacebookLogin && hasKeakieLogin && !userInfoFetched) {
      window.FB.getLoginStatus(() => {
        fetchFacebookUserInfo(({
          email,
          picture
        }) => dispatch(updateFacebookUserInfo({
          email: email || '',
          profileImgSrc: picture?.data?.url || ''
        })));
      });
    }
  }, [isFacebookLogin, hasKeakieLogin, userInfoFetched, dispatch, sdkIsLoaded]);

  return {
    login,
    sdkIsLoaded,
    onLogin: handleLogin,
    onLogout: handleLogout,
    loading
  };
};

export default useFacebookLogin;