import {
  BP_WIDTH_MEDIUM_DESKTOP,
  BP_WIDTH_MOBILE,
  BP_WIDTH_SMALL_DESKTOP,
  BP_WIDTH_TABLET
} from '@/config/breakpoints';

// SCREEN WIDTH KEYS
// These keys represent screen widths for the app which are mapped to image sizes/qualities
export const XLARGE = 'XLARGE';
export const LARGE = 'LARGE';
export const MEDIUM = 'MEDIUM';
export const SMALL = 'SMALL';
export const XSMALL = 'XSMALL';
export const TILESMALL = 'TILE';

// IMAGE TYPE KEYS
export const HERO = 'hero';
export const SLATE = 'slate';
export const SQUARE = 'square';
export const PROFILE = 'profile';
export const SHARING = 'sharing';

// API ASSET KEYS
// These keys hold the strings that are used in the assets object from the API
// HERO
export const XLARGE_HERO_LABEL = 'hero_xl';
export const LARGE_HERO_LABEL = 'hero_l';
export const MEDIUM_HERO_LABEL = 'hero_m';
export const SMALL_HERO_LABEL = 'hero_s';
export const XSMALL_HERO_LABEL = 'hero_xs';
export const TILE_HERO_LABEL = 'hero_tile';
// SLATE
export const LARGE_SLATE_LABEL = 'slate_l';
export const SMALL_SLATE_LABEL = 'slate_s';
export const TILE_SLATE_LABEL = 'slate_tile';
// SQUARE
export const LARGE_SQUARE_LABEL = 'square_l';
export const SMALL_SQUARE_LABEL = 'square_s';
export const TILE_SQUARE_LABEL = 'square_tile';
// PROFILE
export const PROFILE_LABEL = 'profile';
// FACEBOOK HEADER
export const SHARING_LABEL = 'social_media_preview';

// DEFINE THE SCREEN WIDTH RANGES
export const screenWidths = [
  {
    key: XLARGE,
    range: { min: BP_WIDTH_MEDIUM_DESKTOP + 1 }
  },
  {
    key: LARGE,
    range: {
      min: BP_WIDTH_SMALL_DESKTOP + 1,
      max: BP_WIDTH_MEDIUM_DESKTOP
    }
  },
  {
    key: MEDIUM,
    range: {
      min: BP_WIDTH_TABLET + 1,
      max: BP_WIDTH_SMALL_DESKTOP
    }
  },
  {
    key: SMALL,
    range: {
      min: BP_WIDTH_MOBILE + 1,
      max: BP_WIDTH_TABLET
    }
  },
  {
    key: XSMALL,
    range: {
      min: 0,
      max: BP_WIDTH_MOBILE
    }
  }
];

// MAP SCREEN SIZE KEYS TO API ASSET KEYS
export const screenSizeToAssetKeyMap = {
  [HERO]: {
    [XLARGE]: {
      default: LARGE_HERO_LABEL,
      highDensity: XLARGE_HERO_LABEL,
      quality: 6
    },
    [LARGE]: {
      default: MEDIUM_HERO_LABEL,
      highDensity: LARGE_HERO_LABEL,
      quality: 5
    },
    [MEDIUM]: {
      default: MEDIUM_HERO_LABEL,
      quality: 4
    },
    [SMALL]: {
      default: SMALL_HERO_LABEL,
      quality: 3
    },
    [XSMALL]: {
      default: XSMALL_HERO_LABEL,
      quality: 2
    },
    [TILESMALL]: {
      default: TILE_HERO_LABEL,
      quality: 1
    }
  },
  [SLATE]: {
    [XLARGE]: {
      default: SMALL_SLATE_LABEL,
      highDensity: LARGE_SLATE_LABEL,
      quality: 6
    },
    [LARGE]: {
      default: SMALL_SLATE_LABEL,
      highDensity: LARGE_SLATE_LABEL,
      quality: 5
    },
    [MEDIUM]: {
      default: SMALL_SLATE_LABEL,
      quality: 4
    },
    [SMALL]: {
      default: SMALL_SLATE_LABEL,
      quality: 3
    },
    [XSMALL]: {
      default: SMALL_SLATE_LABEL,
      quality: 2
    },
    [TILESMALL]: {
      default: TILE_SLATE_LABEL,
      quality: 1
    }
  },
  [SQUARE]: {
    [XLARGE]: {
      default: SMALL_SQUARE_LABEL,
      highDensity: LARGE_SQUARE_LABEL,
      quality: 6
    },
    [LARGE]: {
      default: SMALL_SQUARE_LABEL,
      highDensity: LARGE_SQUARE_LABEL,
      quality: 5
    },
    [MEDIUM]: {
      default: SMALL_SQUARE_LABEL,
      quality: 4
    },
    [SMALL]: {
      default: SMALL_SQUARE_LABEL,
      quality: 3
    },
    [XSMALL]: {
      default: SMALL_SQUARE_LABEL,
      quality: 2
    },
    [TILESMALL]: {
      default: TILE_SQUARE_LABEL,
      quality: 1
    }
  },
  [PROFILE]: {
    [XLARGE]: {
      default: PROFILE_LABEL,
      quality: 6
    },
    [LARGE]: {
      default: PROFILE_LABEL,
      quality: 5
    },
    [MEDIUM]: {
      default: PROFILE_LABEL,
      quality: 4
    },
    [SMALL]: {
      default: PROFILE_LABEL,
      quality: 3
    },
    [XSMALL]: {
      default: PROFILE_LABEL,
      quality: 2
    },
    [TILESMALL]: {
      default: PROFILE_LABEL,
      quality: 1
    }
  },
  [SHARING]: {
    [XLARGE]: {
      default: SHARING_LABEL,
      quality: 6
    },
    [LARGE]: {
      default: SHARING_LABEL,
      quality: 5
    },
    [MEDIUM]: {
      default: SHARING_LABEL,
      quality: 4
    },
    [SMALL]: {
      default: SHARING_LABEL,
      quality: 3
    },
    [XSMALL]: {
      default: SHARING_LABEL,
      quality: 2
    },
    [TILESMALL]: {
      default: SHARING_LABEL,
      quality: 1
    }
  }
};