import {
  useCallback, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';

const AppFilterProvider = ({ children }) => {
  const [filtered, setFiltered] = useState(false);

  const toggleAppFilter = useCallback(() => {
    setFiltered(!filtered);
  }, [filtered]);

  const enableAppFilter = useCallback(() => {
    setTimeout(() => {
      setFiltered(true);
    }, 0); // Use timeout to make sure this works if triggered on initial mount
  }, []);

  const disableAppFilter = useCallback(() => {
    setFiltered(false);
  }, []);
  
  const value = useMemo(() => ({
    appIsFiltered: filtered,
    enableAppFilter,
    disableAppFilter,
    toggleAppFilter
  }), [filtered, enableAppFilter, disableAppFilter, toggleAppFilter]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

AppFilterProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default AppFilterProvider;