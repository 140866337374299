import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';
import { truthyOrZero } from '@/helpers/math';

// Slice a genres to a limited length based on screen size
export const sliceGenres = ({ genres, limit }) => {
  return genres
    ? genres.slice(0, limit || 2)
    : null;
};

// Compute whether slate overlay info should be hidden
export const isSlateOverlayInfoHidden = ({ width }) => {
  return width < 1000;
};

// Formats information in a Channel or ChannelPreview node
export const formatChannel = (channel) => {
  return channel;
};

// Formats information in an Episode or EpisodePreview node
export const formatEpisode = (track) => {
  const {
    show,
    episode_number,
    reference_number,
    title,
    tracklist: tracklistForTrack
  } = track || {};
  const { title: showTitle } = show || {};

  let episodeNumber;

  if (truthyOrZero(episode_number)) {
    episodeNumber = episode_number;
  } else if (truthyOrZero(reference_number)) {
    episodeNumber = reference_number;
  }

  const showTitleRegex = new RegExp((showTitle || '').trim(), 'i');
  const episodeTitleWithoutShowTitle = (title || '').replace(showTitleRegex, '').trim();

  let tracklist = [];

  if (tracklistForTrack) {
    tracklist = sortBy(
      uniqBy(tracklistForTrack, (t) => `${ t.start_time }-${ t.end_time }`),
      (t) => t.start_time
    );
  }

  return {
    ...track,
    tracklist,
    show: formatChannel(show),
    episode_number: episodeNumber,
    title: episodeTitleWithoutShowTitle || title // Only display the formatted episode title if it's non empty
  };
};

export const formatUser = (user) => {
  const {
    type,
    user_type
  } = user || {};

  return {
    ...user,
    type: type || user_type
  };
};

export const isEntityNewlyReleased = ({ releaseDate, hoursThrehold = 7 * 24 } = {}) => {
  if (!releaseDate) {
    return false;
  }

  const hoursSinceRelease = Math.abs((new Date()).getTime() - (new Date(releaseDate)).getTime()) / 3600000;

  return hoursSinceRelease <= hoursThrehold;
};

export const isShowNewlyReleased = ({ releaseDate } = {}) => isEntityNewlyReleased({
  releaseDate,
  hoursThrehold: 28 * 24 // 28 days
});

export const isEpisodeNewlyReleased = ({ releaseDate } = {}) => isEntityNewlyReleased({
  releaseDate,
  hoursThrehold: 14 * 24 // 28 days
});