import forEach from 'lodash.foreach';
import { truthyOrZeroOrEmptyString } from '../math';
import {
  CHANNEL_KEY,
  DISCOVER_KEY,
  EPISODE_KEY,
  GENRE_KEY,
  LIBRARY_KEY,
  SIGN_IN_KEY,
  NOT_FOUND_KEY,
  PRIVACY_POLICY_KEY,
  PROFILE_KEY,
  SEARCH_KEY
} from '@/config/routeKeys';

const objectIsTruthy = object => Object.values(object).every(v => truthyOrZeroOrEmptyString(v));

const toPath = urlKey => paramsObj => {
  let url = urlKey;

  forEach(paramsObj, (value, key) => {
    url = url.replace(`:${ key }`, value);
  });

  if (!objectIsTruthy(paramsObj)) {
    return '';
  }

  return url;
};

const pageKeys = {
  discover: DISCOVER_KEY,
  login: SIGN_IN_KEY,
  privacyPolicy: PRIVACY_POLICY_KEY,
  profile: PROFILE_KEY,
  search: SEARCH_KEY,
  shows: (channel_slug) => toPath(CHANNEL_KEY)({ channel_slug }),
  episode: (channel_slug, season, episode_number) => toPath(EPISODE_KEY)({
    channel_slug,
    season,
    episode_number
  }),
  genre: (genre_slug, isNew = false) => toPath(GENRE_KEY)({
    genre_slug,
    new: isNew ? 'new' : ''
  }),
  library: LIBRARY_KEY,
  notFound: NOT_FOUND_KEY
};

export default pageKeys;