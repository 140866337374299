export const GET_FULL_EPISODE_LOADING = 'audio/GET_FULL_EPISODE_LOADING';
export const GET_FULL_EPISODE_SUCCESS = 'audio/GET_FULL_EPISODE_SUCCESS';
export const GET_FULL_EPISODE_FAILURE = 'audio/GET_FULL_EPISODE_FAILURE';

export const PLAY_AUDIO = 'audio/PLAY_AUDIO';
export const PAUSE_AUDIO = 'audio/PAUSE_AUDIO';
export const PLAY_EPISODE = 'audio/PLAY_EPISODE';
export const PLAY_EPISODE_FROM_TRACKLIST = 'audio/PLAY_EPISODE_FROM_TRACKLIST';
export const PLAY_EPISODE_FROM_CUSTOM_QUEUE = 'audio/PLAY_EPISODE_FROM_CUSTOM_QUEUE';
export const NEXT_TRACK = 'audio/NEXT_TRACK';
export const PREVIOUS_TRACK = 'audio/PREVIOUS_TRACK';

export const CHANGE_TRACKLIST = 'audio/CHANGE_TRACKLIST';
export const ADD_EPISODE_TO_CUSTOM_QUEUE = 'audio/ADD_EPISODE_TO_CUSTOM_QUEUE';
export const REMOVE_EPISODE_FROM_CUSTOM_QUEUE = 'audio/REMOVE_EPISODE_FROM_CUSTOM_QUEUE';
export const MOVE_CUSTOM_QUEUE_EPISODE_POSITION = 'audio/MOVE_CUSTOM_QUEUE_EPISODE_POSITION';
export const CLEAR_CUSTOM_QUEUE = 'audio/CLEAR_CUSTOM_QUEUE';
export const ADD_EPISODE_TO_HISTORY = 'audio/ADD_EPISODE_TO_HISTORY';
export const REMOVE_EPISODE_FROM_HISTORY = 'audio/REMOVE_EPISODE_FROM_HISTORY';

export const RESET_AUDIO = 'audio/RESET_AUDIO';

export const TIME_UPDATE = 'audio/TIME_UPDATE';
export const SEEK_AUDIO = 'audio/SEEK_AUDIO';
export const SET_VOLUME = 'audio/UPDATE_VOLUME';
export const TOGGLE_MUTED = 'audio/TOGGLE_MUTED';
export const SET_STREAMING_CONTEXT = 'audio/SET_STREAMING_CONTEXT';
export const TOGGLE_META_LOADED = 'audio/TOGGLE_META_LOADED';
export const TOGGLE_ERROR = 'audio/TOGGLE_ERROR';
export const TOGGLE_STALLED = 'audio/TOGGLE_STALLED';
export const TOGGLE_CAN_PLAY = 'audio/TOGGLE_CAN_PLAY';
export const READY_STATE_CHANGED = 'audio/READY_STATE_CHANGED';
export const SET_OVERRIDE_SEEK_KEY = 'audio/SET_OVERRIDE_SEEK_KEY';
export const SET_QUEUE_MODE = 'audio/SET_QUEUE_MODE';

export const EPISODE_MANIFEST_NOT_FOUND = 'audio/EPISODE_MANIFEST_NOT_FOUND';