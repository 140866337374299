import * as breakpoints from '@/sass/variables/breakpoints.module.scss';

// KEYS
export const BP_WIDTH_KEY_SMALL_MOBILE = 'var-bp-width-small-mobile';
export const BP_WIDTH_KEY_MOBILE = 'var-bp-width-mobile';
export const BP_WIDTH_KEY_TABLET = 'var-bp-width-tablet';
export const BP_WIDTH_KEY_SMALL_DESKTOP = 'var-bp-width-small-desktop';
export const BP_WIDTH_KEY_MEDIUM_DESKTOP = 'var-bp-width-medium-desktop';
export const BP_WIDTH_KEY_LARGE_DESKTOP = 'var-bp-width-large-desktop';
// Additional breakpoint key for tageting either small mobile OR mobile
export const BP_WIDTH_KEY_SMALL_MOBILE_OR_MOBILE = 'var-bp-width-small-mobile-or-mobile';

// VALUES (Upper bounds)
export const BP_WIDTH_SMALL_MOBILE = Number(breakpoints[BP_WIDTH_KEY_SMALL_MOBILE]);
export const BP_WIDTH_MOBILE = Number(breakpoints[BP_WIDTH_KEY_MOBILE]);
export const BP_WIDTH_TABLET = Number(breakpoints[BP_WIDTH_KEY_TABLET]);
export const BP_WIDTH_SMALL_DESKTOP = Number(breakpoints[BP_WIDTH_KEY_SMALL_DESKTOP]);
export const BP_WIDTH_MEDIUM_DESKTOP = Number(breakpoints[BP_WIDTH_KEY_MEDIUM_DESKTOP]);
export const BP_WIDTH_LARGE_DESKTOP = Number(breakpoints[BP_WIDTH_KEY_LARGE_DESKTOP]);

const BREAKPOINTS = {
  [BP_WIDTH_KEY_SMALL_MOBILE]: BP_WIDTH_SMALL_MOBILE,
  [BP_WIDTH_KEY_MOBILE]: BP_WIDTH_MOBILE,
  [BP_WIDTH_KEY_TABLET]: BP_WIDTH_TABLET,
  [BP_WIDTH_KEY_SMALL_DESKTOP]: BP_WIDTH_SMALL_DESKTOP,
  [BP_WIDTH_KEY_MEDIUM_DESKTOP]: BP_WIDTH_MEDIUM_DESKTOP,
  [BP_WIDTH_KEY_LARGE_DESKTOP]: BP_WIDTH_LARGE_DESKTOP
};

export default BREAKPOINTS;