import CONFIG from '@/config/global';

// Returns a page title with the app name appended eg "Discover | Keakie"
export const createPageTitle = (title) => {
  // Append the app name before the page title
  return title
    ? `${ CONFIG.appName } | ${ title }`
    : CONFIG.appName;
};

export const stringContainsOther = (string, stringToInclude) => (string || '').toLowerCase().trim().includes((stringToInclude || '').toLowerCase().trim());

export const combineWordsIntoListString = ({ words = [], useConnective = false } = {}) => words
  .map((w) => (w || '').trim())
  .filter((w) => w)
  .reduce(
    (combined, word, index) => {
      let connectingString = '';

      if ((words.length > 1) && (index === words.length - 1)) {
        connectingString = useConnective ? ' & ' : ', ';
      } else if (index !== 0) {
        connectingString = ', ';
      }

      const addition = word
        ? `${ connectingString }${ word }`
        : '';

      return `${ combined }${ addition }`;
    },
    ''
  );

export const addFullStopIfNoPunctionationAtEnd = (text = '') => {
  const trimmedText = text.trim();
  const lastCharacter = trimmedText.slice(-1);

  if (
    ['.', '?', '!'].includes(lastCharacter)
  ) {
    return trimmedText;
  }
  return `${ trimmedText }.`;
};

export const combineSentences = (sentences = []) => sentences
  .map((s) => (s || '').trim())
  .filter((s) => s)
  .reduce(
    (combined, addition, index) => (
      addition
        ? `${ combined }${ index === 0 ? '' : ' ' }${ addFullStopIfNoPunctionationAtEnd(addition) }`
        : combined
    ),
    ''
  );