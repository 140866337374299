import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { LOGOUT, HOST } } = CONFIG;

// Logout of a keakie user session
const logout = () => {
  return callApi({
    url: HOST(LOGOUT) + LOGOUT,
    method: 'DELETE',
    errorOn404: true
  });
};

export default logout;