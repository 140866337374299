import PropTypes from 'prop-types';
import React, { memo } from 'react';
import NextImage from '../NextImage';
import { extractHighAndLowResImages } from '../helpers';
import styles from './styles.module.scss';
import {
  LARGE,
  MEDIUM,
  SLATE,
  SMALL,
  TILESMALL,
  XLARGE,
  XSMALL
} from '@/config/assets';
import { classes } from '@/helpers/styling';

const DEFAULT_OBJECT = {};

const SlateImage = React.forwardRef(({
  assetsObj,
  assetSize,
  className,
  imageClassName,
  loading,
  forceHighDensity,
  onClick,
  disableBorderRadius,
  ...props
}, ref) => {
  const { srcLowRes, srcHighRes } = extractHighAndLowResImages({
    assetSizeKey: assetSize,
    assetsObj,
    imageType: SLATE,
    forceAssetSizeKey: assetSize,
    forceHighDensity
  });

  return (
    <NextImage
      className={ classes(
        styles.slateImageComponent,
        className
      ) }
      disableBorderRadius={ disableBorderRadius }
      imageClassName={ imageClassName }
      loading={ loading }
      onClick={ onClick }
      ref={ ref }
      srcHighRes={ srcHighRes }
      srcLowRes={ srcLowRes }
      { ...props }
    />
  );
});

SlateImage.propTypes = {
  assetsObj: PropTypes.object,
  assetSize: PropTypes.oneOf([XLARGE, LARGE, MEDIUM, SMALL, XSMALL, TILESMALL]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  forceHighDensity: PropTypes.bool,
  disableBorderRadius: PropTypes.bool
};

SlateImage.defaultProps = {
  assetsObj: DEFAULT_OBJECT,
  assetSize: undefined,
  loading: false,
  onClick: undefined,
  className: '',
  imageClassName: '',
  forceHighDensity: false,
  disableBorderRadius: false
};

export default memo(SlateImage);