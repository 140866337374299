import { extractImgFromAssetObj } from '@/helpers/assets';

export const extractHighAndLowResImages = ({
  assetSizeKey,
  assetsObj,
  imageType,
  forceAssetSizeKey,
  forceHighDensity,
  isInitialRender
}) => {
  const srcLowRes = extractImgFromAssetObj({
    assetSizeKey,
    assetsObj,
    imageType,
    useTile: true,
    isInitialRender
  }).image;

  const srcHighRes = extractImgFromAssetObj({
    assetSizeKey,
    assetsObj,
    imageType,
    forceAssetSizeKey,
    forceHighDensity,
    isInitialRender
  }).image;

  return {
    srcLowRes,
    srcHighRes
  };
};