import { getProcessEnv } from '@/helpers/env.mjs';

// Get the environment variables
const { HTTPS, API_URL } = getProcessEnv();

const httpPrefix = HTTPS ? 'https' : 'http';

const apiPaths = {
  GET_USER: '/accounts/me',
  UPDATE_ACCOUNT: '/accounts/me',
  LOGIN: '/edna/create',
  CHECK_LOGIN_STATUS: '/edna/check',
  LOGOUT: '/edna/revoke',
  GET_DISCOVER: '/discover',
  GET_DISCOVER_GENRE: '/discover/genre/:genre_slug',
  GET_STORIES: '/stories',
  SET_STORIES: '/stories',
  GET_RANDOM_STORY: '/story',
  GET_CHANNELS: '/shows',
  GET_CHANNELS_BY_SEARCH: '/shows/search/:query',
  GET_CHANNELS_BY_GENRE_ID: '/shows/genre/:genre_id',
  GET_CHANNELS_BY_SEED: '/seed/:seed',
  GET_CHANNEL_BY_SLUG: '/shows/lookup/:channel_slug',
  GET_SHOWS: '/shows',
  GET_CHANNEL_USER_EDGE: '/shows/:channel_id/me',
  GET_EPISODES: '/episodes',
  GET_EPISODES_BY_SEARCH: '/episodes/search/:query',
  GET_EPISODES_BY_CHANNEL_ID: '/shows/:channel_id/episodes',
  GET_EPISODES_BY_CHANNEL_ID_AND_SEASON:
    '/shows/:channel_id/season/:season/episodes',
  GET_EPISODE: '/episodes/lookup/:channel_slug/:season/:episode_number',
  GET_EPISODE_USER_EDGE: '/episodes/:episode_id/me',
  GET_GENRES: '/genres',
  GET_GENRE_BY_SLUG: '/genres/lookup/:genre_slug',
  LIBRARY: '/library/me',
  EVENTS_KEAKIE: '/events/edna',
  EVENTS_CHANNEL: '/events/channel',
  EVENTS_ROW_IMPRESSION: '/events/discover',
  EVENTS_STREAM: '/events/stream',
  EVENTS_ERROR: '/events/errors',
};

// Creates a host function that can dynamically create a host string based on an api path that is passed to it.
const createHOSTFunction = (host) => {
  return () => {
    return `${httpPrefix}://${API_URL}`;
  };
};

const API_CONFIG = {
  base: {
    ...apiPaths,
    HOST: createHOSTFunction(),
    PROXY_HOST: createHOSTFunction(`${httpPrefix}://${API_URL}`),
  }
};

// Merge the base config with that of the current NODE_ENV value
const ENV_API_CONFIG = API_CONFIG.base;

export default ENV_API_CONFIG;