let localStorageIsAvailable = false;
let localStorageChecked = false;

// Returns true if local storage exists
export const localStorageExists = (getStorage = () => localStorage) => {
  // If already checked for and available, return true
  if (localStorageChecked && localStorageIsAvailable) {
    return true;
  }
  
  // If already checked for and unavailable, return false
  if (localStorageChecked && !localStorageIsAvailable) {
    return false;
  }

  // If we haven't yet checked for local storage:
  // Test setting and removing an item from local storage
  try {
    const key = '__key_testing_for_local_storage__';
    getStorage().setItem(key, key);
    getStorage().removeItem(key);
    localStorageIsAvailable = true;
    localStorageChecked = true;
    return true;
  } catch (e) {
    localStorageIsAvailable = false;
    localStorageChecked = true;
    return false;
  }
};

// Clear local storage entirely for the current website, if local storage is available
export const clearLocalStorage = () => {
  if (localStorageExists()) {
    try {
      localStorage.clear();
    } catch (e) {
      // No need to handle errors yet
    }
  }
};

// Gets a local storage item for a given key
export const getLocalStorageItem = (key) => {
  if (localStorageExists()) {
    try {
      const parsed = JSON.parse(localStorage.getItem(key));

      return parsed;
    } catch (e) {
      return undefined;
    }
  }

  return undefined;
};

// Sets a key value pair in local storage
export const setLocalStorageItem = ({ key, value }) => {
  if (localStorageExists()) {
    const serialized = JSON.stringify(value);

    try {
      localStorage.setItem(key, serialized);
    } catch (e) {
      // No need to handle errors yet
    }
  }
};

// Updates a key value pair in local storage with an updater function
// so that state can be set based on the existing value
export const updateLocalStorageItem = ({ key, updater }) => {
  if (localStorageExists()) {
    const currentItem = getLocalStorageItem(key);

    setLocalStorageItem({
      key,
      value: updater(currentItem)
    });
  }
};