import PropTypes from 'prop-types';
import React from 'react';
import { classes } from '@/helpers/styling';
import Span from '@/components/component-library/Span';
import FONT_SIZES, {
  FONT_SIZE_040,
  FONT_SIZE_050,
  FONT_SIZE_060,
  FONT_SIZE_070,
  FONT_SIZE_080,
  FONT_SIZE_090,
  FONT_SIZE_100,
  FONT_SIZE_110,
  FONT_SIZE_120,
  FONT_SIZE_140,
  FONT_SIZE_160
} from '@/config/fontSizes';

export {
  FONT_SIZE_040,
  FONT_SIZE_050,
  FONT_SIZE_060,
  FONT_SIZE_070,
  FONT_SIZE_080,
  FONT_SIZE_090,
  FONT_SIZE_100,
  FONT_SIZE_110,
  FONT_SIZE_120,
  FONT_SIZE_140,
  FONT_SIZE_160
};

const FONT_SIZES_ARRAY = Object.values(FONT_SIZES);

const LoadingTextWrapper = React.forwardRef(({
  size,
  children,
  className,
  style,
  fixed
}, ref) => (
  <div
    className={ classes(
      'text-loading-wrapper',
      `text-loading-wrapper-${ FONT_SIZES[size] }`,
      { '--is-fixed': fixed },
      className
    ) }
    ref={ ref }
    style={ style }
  >
    { children }
  </div>
));

LoadingTextWrapper.propTypes = {
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  fixed: PropTypes.bool
};

LoadingTextWrapper.defaultProps = {
  size: FONT_SIZE_060,
  className: '',
  style: {},
  fixed: false
};

const Text = React.forwardRef(({
  size,
  fixed,
  selectable,
  bold,
  loading,
  loadingGradientSize,
  children,
  style,
  overflowHidden,
  maximumLineCount,
  className,
  useHeadings,
  useSpan,
  useInput,
  useHeaderFont,
  ...props
}, ref) => {
  const propsToPass = {
    ...props,
    ref: loading ? null : ref,
    children: loading ? null : children,
    style: loading ? {} : style,
    className: classes(
      'text',
      `text-${ FONT_SIZES[size] }`,
      {
        [className]: !loading,
        '--prevent-overflow': !loading && overflowHidden,
        '--is-fixed': fixed,
        '--is-selectable': selectable,
        '--is-bold': bold,
        '--is-loading': loading,
        [`--is-wrapped-${ maximumLineCount }`]: !loading && !!maximumLineCount,
        '--use-header-font': useHeaderFont
      }
    )
  };

  const defaultComponent = <p { ...propsToPass } />;

  let textToRender = defaultComponent;

  if (useInput) {
    textToRender = <input { ...propsToPass } />;
  } else if (useSpan) {
    textToRender = <Span { ...propsToPass } />;
  } else if (useHeadings) {
    switch (loading ? (loadingGradientSize || size) : size) {
      case FONT_SIZE_160: {
        textToRender = <h1 { ...propsToPass } />;
        break;
      }
      case FONT_SIZE_140: {
        textToRender = <h2 { ...propsToPass } />;
        break;
      }
      case FONT_SIZE_120: {
        textToRender = <h3 { ...propsToPass } />;
        break;
      }
      case FONT_SIZE_110: {
        textToRender = <h4 { ...propsToPass } />;
        break;
      }
      case FONT_SIZE_100: {
        textToRender = <h5 { ...propsToPass } />;
        break;
      }
      case FONT_SIZE_090: {
        textToRender = <h6 { ...propsToPass } />;
        break;
      }
      default:
        textToRender = defaultComponent;
    }
  }

  return loading
    ? (
      <LoadingTextWrapper
        className={ loading ? className : '' }
        fixed={ fixed }
        ref={ ref }
        size={ size }
        style={ style }
      >
        { textToRender }
      </LoadingTextWrapper>
    )
    : textToRender;
});

Text.propTypes = {
  size: PropTypes.oneOf(FONT_SIZES_ARRAY),
  fixed: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  maximumLineCount: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  selectable: PropTypes.bool,
  bold: PropTypes.bool,
  loading: PropTypes.bool,
  loadingGradientSize: PropTypes.oneOf(FONT_SIZES_ARRAY),
  useHeadings: PropTypes.bool,
  useSpan: PropTypes.bool,
  useInput: PropTypes.bool,
  useHeaderFont: PropTypes.bool
};

Text.defaultProps = {
  size: FONT_SIZE_060,
  fixed: false,
  overflowHidden: false,
  maximumLineCount: undefined,
  children: null,
  style: {},
  className: '',
  selectable: false,
  bold: false,
  loading: false,
  loadingGradientSize: null,
  useHeadings: true,
  useSpan: false,
  useInput: false,
  useHeaderFont: false
};

export default Text;