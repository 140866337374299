import { getProcessEnv } from '@/helpers/env';
import { isBrowser } from '@/helpers/browser';
import { sendBeaconRequest } from '@/helpers/api';

const { ENABLE_INTERCOM } = getProcessEnv();

// Taken from https://github.com/devrnt/react-use-intercom/blob/5f93b7fdb73db9753a707cd68f9bbc8c98078a0f/src/initialize.ts
export const initializeIntercom = ({ appId, delay = 0 }) => {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function (...args) {
      i.c(...args);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      setTimeout(() => {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${ appId }`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }, delay);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

export const sendEventToIntercom = ({
  eventName,
  metadata = {},
  userId,
  isClosingBrowser = false
}) => {
  if (isBrowser() && ENABLE_INTERCOM) {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    const body = {
      'event_name': eventName,
      'created_at': currentTime,
      'user_id': userId,
      'metadata': { ...metadata }
    };

    if (isClosingBrowser) {
      sendBeaconRequest({
        url: '/api/intercom/events',
        body
      });
    } else if (window.Intercom && (typeof window.Intercom === 'function')) {
      window.Intercom('trackEvent', eventName, metadata);
    }
  }
};