import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { shallowEqual, useSelector } from 'react-redux';
import { Provider } from './context';
import { selectPreviousPath } from '@/state/selectors/routes';
import useInitialRender from '@/hooks/browser/useInitialRender';
import { routerPushLocale } from '@/helpers/routing';

const selector = (state) => ({ previousPath: selectPreviousPath(state) });

const LanguageProvider = ({
  children,
  userPreferredLocale: preferredLocale
}) => {
  const router = useRouter();
  const { previousPath } = useSelector(selector, shallowEqual);
  const { isFirstRender } = useInitialRender();

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [languageCookie, setLanguageCookie] = useState(Cookies.get('NEXT_LOCALE'));
  const [languageChangeInProgress, setLanguageChangeInProgress] = useState(true);
  const [enableMultiLanguageSupport, setEnableMultiLanguageSupport] = useState(false);

  const currentLocale = 'en';
  const resolvedLanguage = 'en';
  const hasLanguageCookie = !!languageCookie;

  useEffect(() => {
    if (languageChangeInProgress && enableMultiLanguageSupport !== undefined) {
      if (isFirstRender) {
        setLanguageChangeInProgress(false);
      } else if (!isFirstRender) {
        if (previousPath) {
          setLanguageChangeInProgress(false);
        } else {
          setTimeout(() => {
            setLanguageChangeInProgress(false);
          }, 5000);
        }
      }
    }
  }, [previousPath, isFirstRender, languageChangeInProgress, enableMultiLanguageSupport]);

  const handleEnableMultiLanguageSupport = useCallback(() => setEnableMultiLanguageSupport(true), []);

  const handleSaveLanguage = useCallback((newLocale) => {
    if (newLocale) {
      document.cookie = `NEXT_LOCALE=${newLocale}; path=/`;
      setLanguageCookie(newLocale);
    }
  }, []);

  const handleOpenLanguageSelector = useCallback(() => {
    setLanguageSelectorOpen(true);
  }, []);

  const handleCloseLanguageSelector = useCallback(() => {
    setLanguageSelectorOpen(false);
  }, []);

  const handleSelectLanguage = useCallback((newLocale) => {
    if (newLocale) {
      handleSaveLanguage(newLocale);

      if (currentLocale !== newLocale) {
        routerPushLocale({
          router,
          locale: newLocale
        });
      }
    } else {
      handleSaveLanguage(resolvedLanguage);
    }

    handleCloseLanguageSelector();
  }, [router, handleCloseLanguageSelector, handleSaveLanguage, currentLocale, resolvedLanguage]);

  const handleCloseLanguageSelectorWithoutSelection = useCallback(() => {
    handleCloseLanguageSelector();
  }, [handleCloseLanguageSelector]);

  const value = useMemo(() => ({
    enableMultiLanguageSupport,
    resolvedLanguage,
    preferredLocale,
    languageSelectorOpen,
    languageSelectorLocale: resolvedLanguage,
    languageSelectorPromptMode: false,
    languageChangeInProgress,
    handleOpenLanguageSelector,
    handleCloseLanguageSelectorWithoutSelection,
    handleSelectLanguage,
    handleSaveLanguage,
    handleEnableMultiLanguageSupport
  }), [
    enableMultiLanguageSupport,
    preferredLocale,
    languageSelectorOpen,
    resolvedLanguage,
    languageChangeInProgress,
    handleOpenLanguageSelector,
    handleCloseLanguageSelectorWithoutSelection,
    handleSelectLanguage,
    handleSaveLanguage,
    handleEnableMultiLanguageSupport
  ]);

  return <Provider value={value}>{children}</Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  userPreferredLocale: PropTypes.string
};

LanguageProvider.defaultProps = { userPreferredLocale: undefined };

export default LanguageProvider;
