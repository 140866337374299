import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import Span from '@/components/component-library/Span';
import { openInNewTab } from '@/helpers/routing';
import { classes } from '@/helpers/styling';

const Link = React.forwardRef(({
  className,
  style,
  onClick,
  children,
  label,
  href,
  openInNewTab: openLinkInNewTab,
  shallow,
  isDisabled,
  ...props
}, ref) => {
  const commonClassName = classes(styles.customLink, className, { [styles.isDisabled]: isDisabled });

  const handleClick = useCallback((e) => {
    if (href && openLinkInNewTab) {
      e.preventDefault();

      openInNewTab(href);
    }

    if (onClick) {
      onClick(e);
    }
  }, [onClick, href, openLinkInNewTab]);

  const handleMouseDown = (e) => e.preventDefault(); // Stop blur events from preventing the anchor tag on click

  if (!href) {
    return (
      <Span
        aria-label={ label }
        className={ commonClassName }
        onClick={ isDisabled ? undefined : handleClick }
        ref={ ref }
        style={ style }
      >
        { children }
      </Span>
    );
  }

  return (
    <NextLink
      href={ isDisabled ? '' : href }
      passHref
      shallow={ shallow }
      { ...props }
    >
      <a
        aria-label={ label }
        className={ commonClassName }
        onClick={ isDisabled ? undefined : handleClick }
        onMouseDown={ handleMouseDown }
        ref={ ref }
        style={ style }
      >
        { children }
      </a>
    </NextLink>
  );
});

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  href: PropTypes.string,
  shallow: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  isDisabled: PropTypes.bool
};

Link.defaultProps = {
  className: '',
  style: {},
  onClick: undefined,
  label: '',
  href: '',
  shallow: true,
  openInNewTab: false,
  isDisabled: false
};

export default Link;