import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Share = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 19.58 19.07'
  >
    <g>
      <path
        d='M5.61,6.44l3-3v9.53a1,1,0,0,0,2,0V3.41l3,3a1,1,0,0,0,.71.3,1,1,0,0,0,.7-.3,1,1,0,0,0,0-1.41L10.35.29A1,1,0,0,0,9.64,0a1,1,0,0,0-.71.29L4.2,5A1,1,0,0,0,5.61,6.44Z'
        fill={ color }
      />
      <path
        d='M18.58,8.71a1,1,0,0,0-1,1v7.36H2V9.71a1,1,0,0,0-2,0v8.36a1,1,0,0,0,1,1H18.58a1,1,0,0,0,1-1V9.71A1,1,0,0,0,18.58,8.71Z'
        fill={ color }
      />
    </g>
  </svg>
);

Share.propTypes = { ...iconPropTypes };

Share.defaultProps = { ...iconDefaultProps };

export default Share;