import PropTypes from 'prop-types';

const STYLE_PROP = { overflow: 'visible' };

export const iconPropTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export const iconDefaultProps = {
  className: '',
  color: 'var(--svg-color)'
};

export const iconCommonProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  'aria-hidden': 'true',
  focusable: 'false',
  role: 'img',
  style: STYLE_PROP
};