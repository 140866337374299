/* eslint no-console: "off" */
import { getBrowser } from '@/helpers/browser';
import sendErrorEvent from '@/helpers/api/events/sendErrorEvent';

const { NODE_ENV } = process.env;

// Logs useful information about the app, such as the environment and browser
export const consoleLogInformation = () => {
  if (NODE_ENV !== 'production') {
    // Log the NODE_ENV variable
    console.log(
      `%cENV: ${ NODE_ENV }`,
      'font-weight: 700;'
    );
    // Log the browser details
    console.log(
      `%cBrowser information: %c${
        JSON.stringify(
          getBrowser(),
          null,
          2
        )
      }`,
      'font-weight: 700;',
      'font-weight: 400;'
    );
  }
};

// LOG LEVELS
const LOG = 'LOG';
const WARN = 'WARN';
const ERROR = 'ERRROR';

// Custom loggin function
const logFunction = ({
  key = 'unknown', // key for this log
  info = '', // information about the log
  level = LOG, // LOG LEVEL based on those above
  skipApiCall = false, // set to true if no API call should be made
  err = {} // error information for the log
}) => {
  const logObject = {
    key,
    info,
    err
  };

  // Logging for development modes
  if (NODE_ENV !== 'production') {
    if (level === LOG) {
      console.log(logObject);
    } else if (level === WARN) {
      console.warn(logObject);
    } else if (level === ERROR) {
      console.error(logObject);
    }
  }
  
  // Logging for production mode
  if (NODE_ENV === 'production') {
    // Do not log in production
  }

  // If the log level is error, send an api error event
  if (!skipApiCall) {
    if (level === ERROR) {
      sendErrorEvent(logObject).catch(_ => _);
    }
  }
};

export const log = ({ key = 'unknown', info = {} }) => logFunction({
  key,
  level: LOG,
  info,
  skipApiCall: true
});

export const logWarn = ({ key = 'unknown', info = {} }) => logFunction({
  key,
  level: WARN,
  info,
  skipApiCall: true
});

export const logError = ({
  key = 'unknown',
  info = '',
  err = {},
  skipApiCall = false
}) => {
  return logFunction({
    key,
    info,
    err,
    level: ERROR,
    skipApiCall
  });
};