import pick from 'lodash.pick';
import initialState from './initialState';
import { mapValues, size } from '@/helpers/lodash';
import { setStatus } from '@/helpers/state';

// Helper reducer function for resetting library search results
export const resetTracksState = (state) => ({
  ...initialState,
  ...pick(
    state,
    ['searchFilters', 'textSearchFilter']
  )
});

const updateTracks = ({
  episodeIdToRemove,
  currentTracks,
  state,
  newEpisode = null
}) => {
  let newTracks = currentTracks;

  if (newEpisode) {
    newTracks = [
      newEpisode,
      ...newTracks
    ];
  }

  if (episodeIdToRemove) {
    newTracks = currentTracks.filter((t) => t.id !== episodeIdToRemove);
  }
  
  return {
    ...state,
    tracks: newTracks
  };
};

export const updateLibrary = ({
  state,
  searchKey = '',
  status,
  tracks = [],
  newEpisode = null,
  episodeIdToRemove = null,
  notFound = false,
  timestamp
}) => {
  const currentKeyState = (state.keys || {})[searchKey] || {};
  const currentTracks = currentKeyState.tracks || [];

  if (notFound) {
    return {
      ...state,
      keys: {
        ...state.keys,
        [searchKey]: {
          ...currentKeyState,
          ...(status ? setStatus('status', status, { notFound: true }) : {})
        }
      }
    };
  }

  const newTracks = (size(tracks)
    ? [
      // ...currentTracks, // REMOVE THIS AS WE DON'T HAVE PAGINATION
      ...tracks
    ] : currentTracks) || [];

  const newState = {
    ...currentKeyState,
    tracks: newTracks,
    ...(status ? setStatus('status', status, (status === 'success') ? { timestamp } : {}) : {})
  };

  if (episodeIdToRemove || newEpisode) {
    if (size(state.keys) > 30) {
      return resetTracksState({
        ...state,
        ...newState
      });
    }

    return {
      ...state,
      keys: mapValues(state.keys, (keyState, key) => {
        if (key === searchKey) {
          return {
            ...newState,
            ...updateTracks({
              episodeIdToRemove,
              currentTracks: newTracks,
              state: newState,
              newEpisode
            })
          };
        }

        return {
          ...updateTracks({
            episodeIdToRemove,
            currentTracks: keyState.tracks,
            state: keyState,
            newEpisode
          })
        };
      })
    };
  }

  return {
    ...state,
    keys: {
      ...state.keys,
      [searchKey]: {
        ...newState,
        ...updateTracks({
          currentTracks: newTracks,
          state: newState,
          searchKey
        })
      }
    }
  };
};