// COMMON
export const GA_DIM_KEAKIE_APP = 'keakie_app';

// AUDIO
export const GA_DIM_SEEK_DIRECTION = 'seek_direction';
export const GA_DIM_TRACK_ARTIST = 'track_artist';
export const GA_DIM_TRACK_TITLE = 'track_title';

// SHOWS
export const GA_DIM_SHOW_ID = 'show_id';
export const GA_DIM_SHOW_TITLE = 'show_title';

// GENRES
export const GA_DIM_GENRE_ID = 'genre_id';
export const GA_DIM_GENRE_TITLE = 'genre_title';
export const GA_DIM_GENRE_PARENT_ID = 'parent_genre_id';
export const GA_DIM_GENRE_PARENT_TITLE = 'parent_genre_title';
export const GA_DIM_GENRES = 'genres';
export const GA_DIM_IS_NEW = 'is_new';

// EPISODE
export const GA_DIM_EPISODE_ID = 'episode_id';
export const GA_DIM_EPISODE_NUMBER = 'episode_number';
export const GA_DIM_EPISODE_SEASON_NUMBER = 'episode_season_number';

// SEARCH
export const GA_DIM_SEARCH_TERM = 'search_term';
export const GA_DIM_SEARCH_FILTER = 'search_filter';

// AUTH
export const GA_DIM_AUTH_TYPE = 'auth_type';