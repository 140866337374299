import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Queue = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 18.47 16.64'
  >
    <g>
      <path
        d='M8.57,3.35h8.9a1,1,0,0,0,0-2H8.57a1,1,0,0,0,0,2Z'
        fill={ color }
      />
      <path
        d='M17.47,8H1a1,1,0,0,0,0,2H17.47a1,1,0,0,0,0-2Z'
        fill={ color }
      />
      <path
        d='M17.47,14.64H1a1,1,0,0,0,0,2H17.47a1,1,0,0,0,0-2Z'
        fill={ color }
      />
      <circle
        cx='2.57'
        cy='2.35'
        fill={ color }
        r='2.35'
      />
    </g>
  </svg>
);

Queue.propTypes = { ...iconPropTypes };

Queue.defaultProps = { ...iconDefaultProps };

export default Queue;