import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { size, isEqual } from '@/helpers/lodash';
import { truthyOrZero } from '@/helpers/math';
import FEATURES_CONFIG from '@/config/feature-toggles/config';

// Pulls a subset of an array by slicing it. start and end indexes are included.
// Eg sliceArrayBetweenIndexes({ array: [0, 1, 2], start: 1, end: 2 }) => [1, 2]
export const sliceArrayBetweenIndexes = ({
  array = null,
  start = null,
  end = null
}) => {
  // If the passed array is non empty then it can be sliced
  if (size(array)) {
    const hasStartIndex = truthyOrZero(start);
    const hasEndIndex = truthyOrZero(end);

    // Make sure that the start and end indexes we use are in the bounds of the array
    const startIndex = Math.max(start, 0);
    const endIndex = Math.min(end, size(array) - 1);

    // If there is a start and end index, slice between them
    if (hasStartIndex && hasEndIndex) {
      return array.slice(startIndex, endIndex + 1);
    }
    
    // If there is only a start index, slice from that index to the end of the array
    if (hasStartIndex) {
      return array.slice(startIndex);
    }
    
    // If there is only a end index, slice from the start of the array to that index
    if (hasEndIndex) {
      return array.slice(0, endIndex + 1);
    }
  }

  // By default, return the passed array or an empty array
  return array || [];
};

// Removes an element from an array.
// Uses the passed index to remove the element.
// If no index is supplied, uses a passed element by checking for equality
export const removeFromArray = ({
  array = null,
  index = null,
  element = null
}) => {
  if (size(array)) {
    if (truthyOrZero(index)) {
      return [
        ...array.slice(0, index),
        ...array.slice(index + 1, array.length)
      ];
    }
    
    return array.filter((e) => !isEqual(e, element));
  }

  return [];
};

// Formats a TimedRanges object into a form we use for streaming events:
// An array of objects of the format: { s: 2.3, e: 2.5 }
export const formatTimedRanges = (timedRangesObject = {}) => {
  const { length } = timedRangesObject;

  if (!length) { return []; }

  return [...Array(length)].map((v, i) => {
    return {
      s: Number(timedRangesObject.start(i).toFixed(2)),
      e: Number(timedRangesObject.end(i).toFixed(2))
    };
  });
};

// Truncate a string with a ... if it is longer than a given character length
export const truncateText = (text = '', characterLimit = text.length) => {
  const trimmedText = (text || '').trim();

  return (trimmedText.length > characterLimit)
    ? `${ trimmedText.substring(0, Math.max(0, characterLimit - 3)).trim() }...`
    : trimmedText;
};

// Wrap a promise with a timeout. If the timeout is reached then the promise will be rejected.
// For example, this is useful for adding a timeout to network requests
export const promiseWithTimeout = (promise, ms = 0) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('timeout'));
    }, ms);

    promise.then(resolve, reject);
  });
};

// Adds N zeroes to the start of a number/string. Eg 5 => '0005'
export const addZeroes = (number = 0, targetLength = 2) => {
  const string = number.toString();
  const stringLength = string.length;

  // If the string is already at the target length, do not add any zeroes
  if (stringLength >= targetLength) { return string; }
  
  // Create a string with as many zeroes as needed to make up the target length
  const zeroes = [...Array(targetLength - stringLength)].map(() => 0).join('');

  return `${ zeroes }${ string }`;
};

/* eslint-disable no-bitwise */
export const hashCode = (string = '') => {
  let hash = 0; let i; let chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const initialiseABFeature = ({ featureKey = '' } = {}) => {
  if (!featureKey) {
    return false;
  }

  const {
    key: previousKey, enabled: previousEnabled, ...previousCustomProperties
  } = getLocalStorageItem(featureKey) || {};
  const userPercentage = FEATURES_CONFIG?.[featureKey]?.userPercentage || 0;

  if (
    previousEnabled === null // Feature flag was not previously set
      || !truthyOrZero(previousKey) // There is no key on the feature flag
      || (previousKey !== userPercentage) // The key has changed (we have changed the user percentage)
  ) {
    // Update the feature flag with new value
    const shouldEnable = (Math.random() * 100) <= userPercentage;

    setLocalStorageItem({
      key: featureKey,
      value: {
        enabled: shouldEnable,
        key: userPercentage,
        ...previousCustomProperties
      }
    });
  
    return {
      enabled: shouldEnable,
      customProperties: previousCustomProperties
    };
  }

  return {
    enabled: previousEnabled || false,
    customProperties: previousCustomProperties
  };
};

export const updateABFeature = ({
  featureKey = '', shouldEnable, customProperties = {}
} = {}) => {
  if (featureKey && shouldEnable !== undefined) {
    const featureFlagObject = getLocalStorageItem(featureKey);

    setLocalStorageItem({
      key: featureKey,
      value: {
        ...featureFlagObject,
        enabled: shouldEnable,
        ...customProperties
      }
    });
  }
};

export const safeJSON = {
  parse: (json) => {
    try {
      return JSON.parse(json);
    } catch {
      return {};
    }
  }
};