import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Provider } from './context';
import { getProcessEnv } from '@/helpers/env';
import {
  Pixel,
  setFBPixel as setFBPixelGlobal,
  sendCustomEventToFB,
  sendPageViewEventToFB,
  resetSentEventsForPixelIds
} from '@/helpers/events-fb-pixel';

const { NODE_ENV, FB_PIXEL_ID } = getProcessEnv();

const KEAKIE_PIXEL_ID = FB_PIXEL_ID;

const PIXEL_CONFIG = {
  autoConfig: true,
  debug: NODE_ENV === 'development'
};

const initialisedPixels = [];

const initialisePixelId = ({ pixelId } = {}) => {
  const pixelIdToUse = pixelId || KEAKIE_PIXEL_ID;

  if (!initialisedPixels.includes(pixelIdToUse)) {
    if (Pixel && pixelIdToUse) {
      Pixel.init(
        pixelIdToUse,
        null,
        PIXEL_CONFIG
      );

      // Disable automatic events, we handle them manually!
      window.fbq.disablePushState = true;

      initialisedPixels.push(pixelIdToUse);
    }
  }
};

const loadPixel = async () => {
  try {
    const { default: pixel } = await import('react-facebook-pixel');

    return pixel;
  } catch (err) {
    return undefined;
  }
};

const FBPixelProvider = ({ children }) => {
  const [pixelLoaded, setPixelLoaded] = useState();
  const { asPath } = useRouter();

  const initialisePixelById = useCallback(({ pixelId } = {}) => {
    if (pixelLoaded) {
      initialisePixelId({ pixelId });
    }
  }, [pixelLoaded]);

  const sendFBPixelCustomEvent = useCallback(({
    eventName, metadata, pixelId
  } = {}) => {
    initialisePixelById({ pixelId });

    if (Pixel && pixelLoaded && eventName) {
      sendCustomEventToFB({
        eventName,
        metadata,
        pixelId
      });
    }
  }, [pixelLoaded, initialisePixelById]);

  const sendFBPixelPageView = useCallback(({ pixelId } = {}) => {
    initialisePixelById({ pixelId });

    if (Pixel && pixelLoaded) {
      sendPageViewEventToFB({ pixelId });
    }
  }, [pixelLoaded, initialisePixelById]);

  useEffect(() => {
    if (KEAKIE_PIXEL_ID) {
      const load = async () => {
        const loadedPixel = await loadPixel();
  
        if (loadedPixel) {
          setPixelLoaded(true);
          setFBPixelGlobal(loadedPixel);
        }
      };
  
      load();
    }
  }, []);

  useEffect(() => {
    if (pixelLoaded && Pixel) {
      // Clear sent pixel events on page change
      resetSentEventsForPixelIds();

      // Track page views
      sendFBPixelPageView();
    }
  }, [asPath, pixelLoaded, sendFBPixelPageView]);

  const value = useMemo(() => ({
    sendFBPixelCustomEvent,
    sendFBPixelPageView,
    initialisePixelId
  }), [sendFBPixelCustomEvent, sendFBPixelPageView]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

FBPixelProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FBPixelProvider;