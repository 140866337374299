export const APPLE_REDIRECT_CLIENT_KEY = '/apple-redirect-client';
export const NOT_FOUND_KEY = '/not-found';
export const DISCOVER_KEY = '/discover';
export const CHANNEL_KEY = '/shows/:channel_slug';
export const EPISODE_KEY = '/shows/:channel_slug/:season/:episode_number';
export const SEARCH_KEY = '/search';
export const GENRE_KEY = '/genre/:genre_slug/:new';
export const LIBRARY_KEY = '/my-library';
export const PROFILE_KEY = '/profile';
export const PRIVACY_POLICY_KEY = '/privacy-policy';
export const SIGN_IN_KEY = '/sign-in';
export const UPDATE_STORIES_KEY = '/admin/stories';
export const APP_DOWNLOAD_KEY = '/download';
export const APP_DOWNLOAD_PARTNER_PROGRAM_KEY = '/download/shows/:channel_slug';