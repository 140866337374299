import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const GoToChannel = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 20.14 16.32'
  >
    <g>
      <circle
        cx='2.27'
        cy='8.16'
        fill={ color }
        r='2.27'
      />
      <path
        d='M20,8.52l0-.06a.77.77,0,0,0,.06-.3.73.73,0,0,0-.06-.3l0-.06a.83.83,0,0,0-.13-.18s0,0,0,0L15.27,3.44a.75.75,0,1,0-1,1.11l3.17,2.86h-7A8.18,8.18,0,0,0,2.27,0a1,1,0,0,0,0,2,6.16,6.16,0,0,1,0,12.32,1,1,0,1,0,0,2A8.18,8.18,0,0,0,10.4,8.91h7l-3.17,2.85a.76.76,0,0,0-.06,1.06.75.75,0,0,0,.56.25.73.73,0,0,0,.5-.19L19.9,8.72s0,0,0,0A.83.83,0,0,0,20,8.52Z'
        fill={ color }
      />
    </g>
  </svg>
);

GoToChannel.propTypes = { ...iconPropTypes };

GoToChannel.defaultProps = { ...iconDefaultProps };

export default GoToChannel;