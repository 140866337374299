import { map } from '@/helpers/lodash';

export const FILTER_KEY_GENRE = 'genre';
export const FILTER_KEY_FORMAT = 'format';

// Filters config for the library page.
// Formats are predefined but genres will be populated from the API
const libraryFilters = {
  [FILTER_KEY_GENRE]: {
    label: 'Genres',
    values: []
  },
  [FILTER_KEY_FORMAT]: {
    label: 'Formats',
    values: [
      {
        id: 'all',
        value: 'All formats'
      },
      {
        id: 'mix',
        value: 'Mix'
      },
      {
        id: 'podcast',
        value: 'Podcast'
      },
      {
        id: 'radio',
        value: 'Radio'
      }
    ]
  }
};

// Appends genres to the library genre filter
export const createGenreFilterOptions = ({ genres, includeAllOption = true }) => ({
  ...libraryFilters[FILTER_KEY_GENRE],
  values: genres
    ? [
      ...(includeAllOption ? [{
        id: 'all',
        value: 'All genres'
      }] : []),
      ...map(genres, ({
        id, title, slug
      }) => ({
        id: id.toString(),
        value: title,
        slug
      }))
    ]
    : []
});

// Appends genres to the library genre filter and returns the full library filters config
export const createLibraryFiltersConfig = ({ genres }) => ({
  ...libraryFilters,
  [FILTER_KEY_GENRE]: { ...createGenreFilterOptions({ genres }) }
});

export default libraryFilters;