import {
  useState,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash.sortby';
import { Provider } from './context';
import useAppFilter from '@/hooks/layout/useAppFilter';

const ModalProvider = ({ children }) => {
  const [openModalZIndexes, setOpenModalZIndexes] = useState([]);
  const { enableAppFilter, disableAppFilter } = useAppFilter();

  const openModal = useCallback(({ id, zIndex }) => setOpenModalZIndexes((oldOpenModalZIndexes) => {
    const newOpenModalZIndexes = sortBy([
      ...oldOpenModalZIndexes,
      {
        id,
        zIndex
      }
    ], ({ zIndex: z }) => -z);

    enableAppFilter();

    return newOpenModalZIndexes;
  }), [enableAppFilter]);

  const closeModal = useCallback(({ id }) => {
    setOpenModalZIndexes((oldOpenModalZIndexes) => {
      const newOpenModalZIndexes = oldOpenModalZIndexes.filter(
        ({ id: idInArray }) => idInArray !== id
      );

      if (!newOpenModalZIndexes.length) {
        disableAppFilter();
      }

      return newOpenModalZIndexes;
    });
  }, [disableAppFilter]);

  const value = useMemo(() => ({
    openModal,
    closeModal,
    openModalZIndexes
  }), [
    openModal,
    closeModal,
    openModalZIndexes
  ]);

  return <Provider value={ value }>{ children }</Provider>;
};

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ModalProvider;