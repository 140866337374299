// ADMIN STATUS
// Use an obscure name so that it's not obvious that this controls admin status
export const FEATURE_KEY_ADMIN_STATUS = 'ft-kacieftu';

// DEV DIAGNOSTIC STATUS
export const FEATURE_KEY_DIAGNOSTICS_STATUS = 'ft-diagnostics';

// UNLIMITED SKIPS
export const FEATURE_KEY_UNLIMITED_SKIPS = 'ft-unlimited-s';

// LANGUAGE
export const FEATURE_KEY_CUSTOM_LANGUAGE = 'ft-custom-language';

const CONFIG = {
  [FEATURE_KEY_ADMIN_STATUS]: { userPercentage: 0 },
  [FEATURE_KEY_DIAGNOSTICS_STATUS]: { userPercentage: 0 },
  [FEATURE_KEY_UNLIMITED_SKIPS]: { userPercentage: 0 },
  [FEATURE_KEY_CUSTOM_LANGUAGE]: { userPercentage: 0 }
};

export default CONFIG;