import PropTypes from 'prop-types';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import Portal from '@/components/app/Portal';
import { classes } from '@/helpers/styling';
import { truthyOrZero } from '@/helpers/math';

// This is a full screen overlay/modal that is inserted into a
// react portal in the DOM.
const Overlay = ({
  id,
  commonClassName,
  commonStyle,
  children,
  backgroundClassName,
  backgroundStyle,
  disableBackground,
  className,
  style,
  zIndex: zIndexProp,
  onClick,
  onBlur,
  contentRef
}) => {
  const zIndex = (truthyOrZero(zIndexProp) ? zIndexProp : style.zIndex) || 100;

  const backgroundStyles = useMemo(() => ({
    ...commonStyle,
    ...backgroundStyle,
    ...(truthyOrZero(zIndex) ? { zIndex } : {})
  }), [commonStyle, backgroundStyle, zIndex]);

  const contentStyles = useMemo(() => ({
    ...commonStyle,
    ...style,
    ...(truthyOrZero(zIndex) ? { zIndex } : {})
  }), [commonStyle, style, zIndex]);

  return (
    <Portal selectorId='overlay'>
      { !disableBackground && (
        <div
          className={ classes(
            styles.overlayBackground,
            commonClassName,
            backgroundClassName
          ) }
          id={ id ? `${ id }-overlay-background` : null }
          style={ backgroundStyles }
        />
      ) }
      <div
        className={ classes(
          styles.overlay,
          commonClassName,
          className
        ) }
        id={ id ? `${ id }-overlay-content` : null }
        onBlur={ onBlur }
        onClick={ onClick }
        ref={ contentRef }
        style={ contentStyles }
        tabIndex={ 0 } // Prevents bugs with clicking on elements within the overlay
      >
        { children }
      </div>
    </Portal>
  );
};

Overlay.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  commonClassName: PropTypes.string,
  commonStyle: PropTypes.object,
  backgroundClassName: PropTypes.string,
  backgroundStyle: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  zIndex: PropTypes.number,
  disableBackground: PropTypes.bool
};

Overlay.defaultProps = {
  id: null,
  commonClassName: '',
  commonStyle: {},
  backgroundClassName: '',
  backgroundStyle: {},
  className: '',
  style: {},
  onClick: undefined,
  onBlur: undefined,
  contentRef: null,
  zIndex: undefined,
  disableBackground: false
};

export default Overlay;