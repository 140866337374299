import { APPLE_SIGN_IN_LOCAL_STORAGE_KEY } from '@/config/login';
import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/localStorage';

export const getAppleLoginDetails = () => getLocalStorageItem(APPLE_SIGN_IN_LOCAL_STORAGE_KEY) || {};

export const setAppleLoginDetails = ({
  subject,
  email,
  firstName,
  lastName
}) => setLocalStorageItem({
  key: APPLE_SIGN_IN_LOCAL_STORAGE_KEY,
  value: {
    subject,
    email,
    firstName,
    lastName
  }
});

export const clearAppleLoginDetails = () => setLocalStorageItem({
  key: APPLE_SIGN_IN_LOCAL_STORAGE_KEY,
  value: {}
});