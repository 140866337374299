import { useDispatch } from 'react-redux';
import { generateHref } from '@/helpers/routing';
import { goToChannel } from '@/helpers/pages/channel';
import { share } from '@/helpers/share';
import useLibraryUserEdge from '@/hooks/api/useLibraryUserEdge';
import { addEpisodeToCustomQueue, removeEpisodeFromCustomQueue } from '@/state/audio/actions';
import { addToLibrary, removeFromLibrary } from '@/state/library/actions';
import { EPISODE_KEY } from '@/config/routeKeys';
import { channelRecommendationTypes } from '@/config/events-keakie';

// This hook contains all state logic for the episode menu overlay
const useEpisodeMenuState = ({
  episode,
  episodeQueueKey,
  episodeEdges,
  onClose,
  skipLibraryCall = false,
  onClickNavigationOption
}) => {
  const dispatch = useDispatch();

  // Extract necessary information from the episode
  const {
    id,
    title,
    show,
    episode_number,
    season
  } = episode || {};
  const {
    assets = {}, id: channelId, slug: channelSlug
  } = show || {};

  const { edge, edgeStatus } = useLibraryUserEdge({
    episodeId: id,
    episodeEdges,
    shouldCall: !skipLibraryCall
  });

  const queueAndLibraryParams = {
    key: episodeQueueKey,
    id,
    title,
    assets,
    episode
  };

  const handleAddToLibrary = () => {
    onClose();
    dispatch(addToLibrary(queueAndLibraryParams));
  };

  const handleRemoveFromLibrary = () => {
    onClose();
    dispatch(removeFromLibrary(queueAndLibraryParams));
  };

  const handleAddToQueue = () => {
    onClose();
    dispatch(addEpisodeToCustomQueue({
      ...queueAndLibraryParams,
      episode
    }));
  };

  const handleRemoveFromQueue = () => {
    onClose();
    dispatch(removeEpisodeFromCustomQueue(queueAndLibraryParams));
  };

  const handleGoToChannel = () => {
    onClose();
    if (onClickNavigationOption) {
      onClickNavigationOption();
    }

    goToChannel({
      channelId,
      channelSlug,
      recommendationId: id,
      recommendationType: channelRecommendationTypes.EPISODE
    });
  };

  const handleShare = () => {
    onClose();
    const shareUrlParams = {
      channel_slug: channelSlug,
      episode_number,
      season
    };
    
    share({
      url: generateHref(EPISODE_KEY, shareUrlParams),
      tooltipText: 'Copied to clipboard'
    });
  };

  return {
    handleAddToLibrary,
    handleRemoveFromLibrary,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleGoToChannel,
    handleShare,
    edge,
    edgeStatus
  };
};

export default useEpisodeMenuState;
