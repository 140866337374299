import initialState from './initialState';
import {
  SET_ROUTE_LOADING,
  SET_ROUTE_LOADING_COMPLETE,
  SET_SHOW_ROUTE_LOADER,
  UPDATE_ROUTER_PATH
} from './types';

const reducer = (state = { ...initialState }, action) => {
  if (!state || !action) { return state; }
  
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ROUTER_PATH: {
      const { previousPath } = payload;

      return {
        ...state,
        previousPath
      };
    }
    case SET_ROUTE_LOADING: {
      const { value } = payload;

      return {
        ...state,
        routeLoading: value
      };
    }
    case SET_ROUTE_LOADING_COMPLETE: {
      return {
        ...state,
        routeLoading: false
      };
    }
    case SET_SHOW_ROUTE_LOADER: {
      const { value } = payload;

      return {
        ...state,
        showRouteLoader: value
      };
    }
    default:
      return state;
  }
};

export default reducer;