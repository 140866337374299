import {
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import useAudioElement from '@/hooks/audio/useAudioElement';

const AirplayProvider = ({ children }) => {
  const [airplayAvailable, setAirplayAvailable] = useState(false); // Is airplay available in the browser?

  const { audioRef, audioRefReady } = useAudioElement();

  const handleAirplayAvailable = useCallback((event) => {
    switch (event.availability) {
      case 'available':
        setAirplayAvailable(true);
        break;
      case 'not-available':
        setAirplayAvailable(false);
        break;
      default:
        break;
    }
  }, []);

  // Listen for the airplay feature
  useEffect(() => {
    const audioElement = audioRef.current;

    if (window.WebKitPlaybackTargetAvailabilityEvent && audioRefReady) {
      audioElement.addEventListener(
        'webkitplaybacktargetavailabilitychanged',
        handleAirplayAvailable
      );
    }

    return () => {
      if (window.WebKitPlaybackTargetAvailabilityEvent && audioRefReady) {
        audioElement.removeEventListener(
          'webkitplaybacktargetavailabilitychanged',
          handleAirplayAvailable
        );
      }
    };
  }, [audioRef, handleAirplayAvailable, audioRefReady]);

  const value = useMemo(() => ({
    airplayAvailable,
    audioRef
  }), [
    airplayAvailable,
    audioRef
  ]);

  return <Provider value={ value }>{ children }</Provider>;
};

AirplayProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AirplayProvider;