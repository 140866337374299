import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Add = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 19.41 19.41'
  >
    <g>
      <path
        d='M18.66,8.91H10.5V.75A.75.75,0,0,0,9,.75V8.91H.75a.75.75,0,0,0,0,1.5H9v8.25a.75.75,0,0,0,1.5,0V10.41h8.16a.75.75,0,0,0,0-1.5Z'
        fill={ color }
      />
    </g>
  </svg>
);

Add.propTypes = { ...iconPropTypes };

Add.defaultProps = { ...iconDefaultProps };

export default Add;