import { shallowEqual, useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  selectCacheHydrationComplete,
  selectKeakieCheckLoginStatus,
  selectLoginInfo,
  selectKeakieCreateLoginStatus,
  selectLoginType,
  selectUser,
  selectUserId,
  selectExternalLoginIsLoggedIn
} from '@/state/selectors/user';
import CONFIG from '@/config/global';
import { LOGIN_TYPE_FACEBOOK } from '@/config/login';

const { allowAppUsageWithoutLogin } = CONFIG;

const USER_STATUS_SUCCESS_OBJECT = { success: true };

const selector = (state) => ({
  user: selectUser(state),
  userId: selectUserId(state),
  keakieCreateLoginStatus: selectKeakieCreateLoginStatus(state),
  keakieCheckLoginStatus: selectKeakieCheckLoginStatus(state),
  userInfo: selectLoginInfo(state),
  loginType: selectLoginType(state),
  cacheHydrationComplete: selectCacheHydrationComplete(state),
  isLoggedInWithProvider: selectExternalLoginIsLoggedIn(state)
});

const useUser = () => {
  const {
    userId,
    user,
    keakieCreateLoginStatus,
    keakieCheckLoginStatus,
    loginType,
    cacheHydrationComplete,
    userInfo,
    isLoggedInWithProvider
  } = useSelector(selector, shallowEqual);

  const failedLogin = (keakieCheckLoginStatus.error || keakieCheckLoginStatus.success) && keakieCreateLoginStatus.error;
  const userStatus = !allowAppUsageWithoutLogin ? keakieCreateLoginStatus : USER_STATUS_SUCCESS_OBJECT;
  const facebookProfileImgSrc = userInfo?.profileImgSrc;

  const userFormatted = useMemo(() => ({
    ...user,
    ...(loginType === LOGIN_TYPE_FACEBOOK ? { assets: { profile: { jpg: facebookProfileImgSrc } } } : {})
  }), [user, facebookProfileImgSrc, loginType]);

  return useMemo(() => ({
    userId,
    user: userFormatted,
    userStatus,
    keakieCheckLoginStatus,
    failedLogin,
    loginType,
    userInfo,
    cacheHydrationComplete,
    isLoading: userStatus.loading || !userId,
    isError: userStatus.error,
    isLoggedInWithProvider
  }), [
    userId,
    userFormatted,
    userStatus,
    keakieCheckLoginStatus,
    failedLogin,
    loginType,
    userInfo,
    cacheHydrationComplete,
    isLoggedInWithProvider
  ]);
};

export default useUser;