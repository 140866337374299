import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';
import { LOGIN_TYPE_SHADOW } from '@/config/login';

const { api: { LOGIN, HOST } } = CONFIG;

// Create a login session with Keakie
// This could be shadow, facebook or apple, with an access token only in the latter two cases
const login = ({
  type = LOGIN_TYPE_SHADOW,
  accessToken,
  firstName,
  lastName
}) => {
  return callApi({
    url: HOST(LOGIN) + LOGIN,
    method: 'POST',
    body: {
      user_type: type,
      ...(accessToken ? { access_token: accessToken } : {}),
      ...(firstName ? { first_name: firstName } : {}),
      ...(lastName ? { last_name: lastName } : {})
    },
    errorOn404: true
  });
};

export default login;