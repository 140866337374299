import {
  ADD_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION
} from './types';

export const addNotification = ({
  id,
  title,
  assets,
  success,
  type
}) => (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      id,
      title,
      assets,
      success,
      type
    }
  });
};

export const removeNotification = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: { id }
  });
};

export const clearAllNotifications = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_NOTIFICATIONS });
};