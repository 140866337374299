import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  LIGHT_GRADIENT_TEMPLATE,
  SMALL_GRADIENT_TEMPLATE,
  MEDIUM_GRADIENT_TEMPLATE,
  LARGE_GRADIENT_TEMPLATE
} from './weight-templates.js';
import { classes } from '@/helpers/styling';
import { hexToRGB } from '@/helpers/math';
import { BLACK } from '@/config/colors';

// POSITIONS
export const GRADIENT_POSITION_TOP = 'top';
export const GRADIENT_POSITION_RIGHT = 'right';
export const GRADIENT_POSITION_BOTTOM = 'bottom';
export const GRADIENT_POSITION_LEFT = 'left';

// WEIGHT KEYS
export const GRADIENT_WEIGHT_KEY_LIGHT = 'LIGHT';
export const GRADIENT_WEIGHT_KEY_SMALL = 'SMALL';
export const GRADIENT_WEIGHT_KEY_MEDIUM = 'MEDIUM';
export const GRADIENT_WEIGHT_KEY_LARGE = 'LARGE';

const EXTRA_BREADTH_ADJUSTMENT_PX = 5;

const GRADIENT_TEMPLATES = {
  [GRADIENT_WEIGHT_KEY_LIGHT]: LIGHT_GRADIENT_TEMPLATE,
  [GRADIENT_WEIGHT_KEY_SMALL]: SMALL_GRADIENT_TEMPLATE,
  [GRADIENT_WEIGHT_KEY_MEDIUM]: MEDIUM_GRADIENT_TEMPLATE,
  [GRADIENT_WEIGHT_KEY_LARGE]: LARGE_GRADIENT_TEMPLATE
};

const DEFAULT_GRADIENT_TEMPLATE = GRADIENT_TEMPLATES[GRADIENT_WEIGHT_KEY_LIGHT];

const computeStyles = ({
  extraBreadth = false,
  position = GRADIENT_POSITION_TOP,
  breadth: breadthValue,
  gradientBreadth: gradientBreadthValue,
  background,
  wrapperStyle = {},
  style = {}
}) => {
  const breadth = breadthValue || '100%';
  const gradient = gradientBreadthValue || '100%';

  const wrapperStyles = {
    top: 'unset',
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  };
  const styles = {};
  const wrapperPositionStyle = extraBreadth ? `-${ EXTRA_BREADTH_ADJUSTMENT_PX }px` : 0;
  const wrapperBreadthStyle = extraBreadth ? `calc(${ breadth } + ${ EXTRA_BREADTH_ADJUSTMENT_PX * 2 }px)` : breadth;
  const wrapperLateralBreadthStyle = extraBreadth ? `calc(100% + ${ EXTRA_BREADTH_ADJUSTMENT_PX * 2 }px)` : '100%';

  if ((position === GRADIENT_POSITION_LEFT) || (position === GRADIENT_POSITION_RIGHT)) {
    wrapperStyles.flexDirection = 'row';
    wrapperStyles.width = wrapperBreadthStyle;
    wrapperStyles.height = wrapperLateralBreadthStyle;
    styles.width = gradient;
  }
  if ((position === GRADIENT_POSITION_TOP) || (position === GRADIENT_POSITION_BOTTOM)) {
    wrapperStyles.height = wrapperBreadthStyle;
    wrapperStyles.width = wrapperLateralBreadthStyle;
    styles.height = gradient;
  }
  if ((position === GRADIENT_POSITION_TOP) || (position === GRADIENT_POSITION_LEFT)) {
    wrapperStyles.justifyContent = 'flex-start';
  }
  if (position === GRADIENT_POSITION_TOP) {
    wrapperStyles.top = wrapperPositionStyle;
    wrapperStyles.left = wrapperPositionStyle;
  }
  if (position === GRADIENT_POSITION_BOTTOM) {
    wrapperStyles.bottom = wrapperPositionStyle;
    wrapperStyles.left = wrapperPositionStyle;
  }
  if (position === GRADIENT_POSITION_LEFT) {
    wrapperStyles.left = wrapperPositionStyle;
    wrapperStyles.top = wrapperPositionStyle;
  }
  if (position === GRADIENT_POSITION_RIGHT) {
    wrapperStyles.right = wrapperPositionStyle;
    wrapperStyles.top = wrapperPositionStyle;
  }

  return {
    wrapperStyles: {
      ...wrapperStyles,
      ...wrapperStyle
    },
    styles: {
      ...styles,
      ...style,
      background
    }
  };
};

const convertGradientTemplateToBackground = ({
  key, direction, colour
}) => {
  const colourAsRGBA = hexToRGB(colour);
  const colourString = (colourAsRGBA.split(' / ')[0] || '').replace('rgb(', '').replaceAll(' ', ',');

  return `linear-gradient(${ direction }, ${ (GRADIENT_TEMPLATES[key] || DEFAULT_GRADIENT_TEMPLATE).replaceAll('COLOUR', colourString) })`;
};

const Gradient = ({
  style,
  wrapperStyle,
  colour,
  extraBreadth,
  directionReverse,
  position,
  breadth,
  gradientBreadth,
  disable,
  className,
  gradientClassName,
  weight
}) => {
  let direction = directionReverse ? 'to top' : 'to bottom';

  if ((position === GRADIENT_POSITION_LEFT) || (position === GRADIENT_POSITION_RIGHT)) {
    direction = directionReverse ? 'to left' : 'to right';
  }

  const background = convertGradientTemplateToBackground({
    key: weight,
    direction,
    colour: colour || BLACK
  });

  const { wrapperStyles, styles } = useMemo(() => computeStyles({
    wrapperStyle,
    style,
    background,
    extraBreadth,
    position,
    breadth,
    gradientBreadth
  }), [extraBreadth, position, breadth, gradientBreadth, wrapperStyle, style, background]);

  return disable
    ? null
    : (
      <div
        className={ classes(className, 'gradient-component-wrapper') }
        style={ wrapperStyles }
      >
        <div
          className={ classes(gradientClassName, 'gradient-component') }
          style={ styles }
        />
      </div>
    );
};

Gradient.propTypes = {
  directionReverse: PropTypes.bool,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  colour: PropTypes.string,
  position: PropTypes.string,
  extraBreadth: PropTypes.bool,
  breadth: PropTypes.string,
  gradientBreadth: PropTypes.string,
  disable: PropTypes.bool,
  className: PropTypes.string,
  gradientClassName: PropTypes.string,
  weight: PropTypes.oneOf([
    GRADIENT_WEIGHT_KEY_LIGHT,
    GRADIENT_WEIGHT_KEY_SMALL,
    GRADIENT_WEIGHT_KEY_MEDIUM,
    GRADIENT_WEIGHT_KEY_LARGE
  ])
};

Gradient.defaultProps = {
  directionReverse: false,
  style: {},
  wrapperStyle: {},
  colour: null,
  position: GRADIENT_POSITION_TOP,
  extraBreadth: true,
  breadth: '100%',
  gradientBreadth: '100%',
  disable: false,
  className: '',
  gradientClassName: '',
  weight: undefined
};

export default Gradient;