import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const BasicImage = React.forwardRef(({
  src,
  loading,
  className,
  imageClassName,
  onClick,
  alt
  // ...props
}, ref) => {
  const imageRef = useRef();

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  useEffect(() => {
    if (imageRef?.current?.complete) {
      handleImageLoad();
    }
  }, [handleImageLoad, imageRef]);
  
  return !!src && (
    <div
      // { ...props }
      className={ classes(
        className,
        styles.imageContainer,
        {
          [styles.loading]: !imageLoaded,
          [styles.isClickable]: !loading && !!onClick
        }
      ) }
      onClick={ onClick }
      ref={ ref }
    >
      <img
        alt={ alt || '' }
        className={ classes(styles.image, imageClassName, { [styles.isLoaded]: imageLoaded }) }
        onLoad={ handleImageLoad }
        ref={ imageRef }
        src={ src }
      />
    </div>
  );
});

BasicImage.propTypes = {
  src: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string
};

BasicImage.defaultProps = {
  src: '',
  loading: false,
  className: '',
  imageClassName: '',
  onClick: undefined,
  alt: ''
};

export default memo(BasicImage);