import forEach from 'lodash.foreach';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

// Filter library tracks by search text and filters
export const filterLibraryTracks = ({
  tracks,
  searchValue = '',
  formatValue = '',
  genreId = null
}) => {
  // There should be no duplicates but make sure to remove any as a mitigation
  let filteredTracks = uniqBy(tracks, 'id');

  // If there is a genre filter and it is not set to 'all', filter based on genre id
  if (genreId && !genreId.toLowerCase().includes('all')) {
    filteredTracks = filteredTracks.filter((t) => {
      const { genres } = t;

      return (genres || []).some((g) => g.id === genreId);
    });
  }

  // If there is a format filter and it is not set to 'all', filter based on format
  if (formatValue && !formatValue.toLowerCase().includes('all')) {
    filteredTracks = filteredTracks.filter((t) => {
      const { show } = t || {};
      const episodeFormat = t.format || '';
      const channelFormat = show.format || '';
      
      return (
        (episodeFormat.toLowerCase() === (formatValue || '').toLowerCase())
        || (channelFormat.toLowerCase() === (formatValue || '').toLowerCase())
      );
    });
  }

  // If there is a search value, filter tracks that include the search value in one of the following fields:
  // Episode title, channel title, guests, hosts
  if (searchValue) {
    filteredTracks = filteredTracks.filter((t) => {
      const {
        title,
        show,
        guest
      } = t;

      const {
        title: channelTitle,
        hosts
      } = show;
      
      return ''
        .concat(' ', (title || ''))
        .concat(' ', (channelTitle || ''))
        .concat(' ', ((guest || []).join(' ').trim() || ''))
        .concat(' ', ((hosts || []).join(' ').trim() || ''))
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim());
    });
  }

  return filteredTracks;
};

// Create a list of all genres that are included in an array of tracks
// This is used on the library page to display only genres that are in the library
export const getLibraryGenresList = ({ tracks, genres }) => {
  // Filter out any genres that are not associated with any track in the tracks array
  // Then sort the genres list in alphabetical order by title
  const filteredGenres = sortBy(
    genres.filter((genre) => {
      return tracks.some((track) => {
        const trackGenres = track.genres || [];

        return trackGenres.some((trackGenre) => {
          return (trackGenre.id === genre.id);
        });
      });
    }), (g => g.title)
  );

  return filteredGenres;
};

// Construct a query string for the current library filters
// This may be used to fetch filtered library tracks from the API
export const generateQueryParamsForLibrary = ({ optionFilters, textSearchFilter }) => {
  const queryParams = {};

  forEach(
    optionFilters,
    (filter, k) => {
      // Only add the filter to the params object if it is truthy and not set to 'all'
      if (filter.id && !filter.id.toLowerCase().includes('all')) {
        queryParams[k] = filter.id;
      }
    }
  );

  if (textSearchFilter) {
    // Add the text filter to the params object if it is truthy
    queryParams.query = textSearchFilter;
  }

  return queryParams;
};

// Extract the library tracks for a certain query key
// This is used if we are storing API responses by the unique query string used to make the call
export const getLibraryForKey = ({ library, queryKey }) => {
  const stateForQueryKey = (library.keys || {})[queryKey] || {};
  const status = stateForQueryKey.status || {};
  const tracks = stateForQueryKey.tracks || [];
  const page = stateForQueryKey.page || 0;

  return {
    status,
    tracks,
    page
  };
};

// Create a unique key to represent a list of tracks
// This is useful for understanding when a list of tracks has changed
export const generateKeyForTracks = ({ tracks }) => {
  return tracks.reduce((acc, t) => `${ acc }.${ t.id }`, '');
};