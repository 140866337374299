import { useCallback, useEffect } from 'react';
import { isBrowser } from '@/helpers/browser';

const useBrowserPopState = (onPopState) => {
  const isBrowserMode = isBrowser();

  const handlePopState = useCallback((e) => {
    onPopState(e);
  }, [onPopState]);

  useEffect(() => {
    if (isBrowserMode) {
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      if (isBrowserMode) {
        setTimeout(() => {
          window.removeEventListener('popstate', handlePopState);
        }, 0);
      }
    };
  }, [isBrowserMode, handlePopState]);

  return null;
};

export default useBrowserPopState;