import {
  SET_ROUTE_LOADING,
  SET_ROUTE_LOADING_COMPLETE,
  SET_SHOW_ROUTE_LOADER,
  UPDATE_ROUTER_PATH
} from './types';

export const updateRouterPath = (previousPath) => (dispatch) => {
  dispatch({
    type: UPDATE_ROUTER_PATH,
    payload: { previousPath }
  });
};

export const setRouteLoading = (value = true) => (dispatch) => {
  dispatch({
    type: SET_ROUTE_LOADING,
    payload: { value }
  });
};

export const setRouteLoadingComplete = () => (dispatch) => {
  dispatch({ type: SET_ROUTE_LOADING_COMPLETE });
};

export const showRouteLoader = (shouldShow = true) => (dispatch) => {
  dispatch({
    type: SET_SHOW_ROUTE_LOADER,
    payload: { value: shouldShow }
  });
};