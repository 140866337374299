/* eslint-disable import/no-mutable-exports */
import { getProcessEnv } from '@/helpers/env';
import { isBrowser } from '@/helpers/browser';
import { FB_EVT_PAGE_VIEW } from '@/config/events-fb-pixel/events';

const { FB_PIXEL_ID } = getProcessEnv();

const KEAKIE_PIXEL_ID = FB_PIXEL_ID;
const TRACK_TYPE_SINGLE = 'trackSingle';
const TRACK_TYPE_SINGLE_CUSTOM = 'trackSingleCustom';

export let Pixel;
let eventsSentForPage = {};

export const setFBPixel = (pixel) => { Pixel = pixel; };

const markEventAsSentForPixelId = ({ pixelId, eventName } = {}) => {
  if (eventName) {
    try {
      eventsSentForPage[pixelId].push(eventName);
    } catch {
      eventsSentForPage[pixelId] = [eventName];
    }
  }
};

const hasEventBeenSentForPixelId = ({ pixelId, eventName } = {}) => !!eventName && (eventsSentForPage[pixelId] || []).includes(eventName);

const trackFBEvent = ({
  eventName,
  metadata,
  pixelId,
  trackType
}) => {
  const pixelIdToUse = pixelId || KEAKIE_PIXEL_ID;

  if (
    Pixel
      && isBrowser()
      && eventName
      && trackType
      && !hasEventBeenSentForPixelId({
        eventName,
        pixelId: pixelIdToUse
      })
  ) {
    markEventAsSentForPixelId({
      eventName,
      pixelId: pixelIdToUse
    });

    Pixel.fbq(
      trackType,
      pixelIdToUse,
      eventName,
      metadata
    );
  }
};

export const sendCustomEventToFB = ({
  eventName,
  metadata,
  pixelId
}) => trackFBEvent({
  eventName,
  metadata,
  pixelId,
  trackType: TRACK_TYPE_SINGLE_CUSTOM
});

export const sendPageViewEventToFB = ({ pixelId } = {}) => trackFBEvent({
  eventName: FB_EVT_PAGE_VIEW,
  pixelId,
  trackType: TRACK_TYPE_SINGLE
});

export const resetSentEventsForPixelIds = () => {
  eventsSentForPage = {};
};