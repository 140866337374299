import { shallowEqual, useSelector } from 'react-redux';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { getProcessEnv } from '@/helpers/env';
import { shouldCall } from '@/helpers/api';
import ConnectivityContext from '@/contexts/connectivity';
import useUser from '@/hooks/api/useUser';
import { selectLoginType } from '@/state/selectors/user';
import { LOGIN_TYPE_SHADOW } from '@/config/login';

const { DISABLE_AUTHENTICATION } = getProcessEnv();

const defaultRetryDelay = 2;
const retryIncrement = 2;
const maxRetryFactor = 15;
const maxNoOfRetries = 5;

const selector = (state) => ({ loginType: selectLoginType(state) });

// This hook is used for handling API calls.
// It assumes that success/loading/error states are managed externally
// and will only be triggered by the shouldCall flag
const useCallApiSimple = ({
  call,
  shouldCall: shouldCallFlag = false,
  shouldCallTimeout = false,
  key,
  retryDelay = defaultRetryDelay,
  maxRetries = maxNoOfRetries,
  infiniteRetry = false,
  shouldRetry = false,
  hasError = false,
  waitForAuth = true,
  waitForCacheHydration = true
}) => {
  const { loginType } = useSelector(selector, shallowEqual);
  const {
    keakieCheckLoginStatus, userStatus, cacheHydrationComplete
  } = useUser();
  const { online } = useContext(ConnectivityContext);

  const authIsComplete = DISABLE_AUTHENTICATION
  || (
    !shouldCall({ status: keakieCheckLoginStatus }).should
      && !keakieCheckLoginStatus.loading
      && ((loginType === LOGIN_TYPE_SHADOW) ? userStatus.success : (!!loginType && userStatus.success))
  );

  const preconditionsPassed = authIsComplete && (waitForCacheHydration ? cacheHydrationComplete : true);

  const shouldFireCall = online && (
    waitForAuth
      ? (shouldCallFlag && preconditionsPassed)
      : shouldCallFlag
  );

  const shouldFireCallTimeout = online && (
    waitForAuth
      ? (shouldCallTimeout && preconditionsPassed)
      : shouldCallTimeout
  );

  const shouldRetryCall = online && (
    waitForAuth
      ? (shouldRetry && preconditionsPassed)
      : shouldRetry
  );

  const [retry, setRetry] = useState(0);

  const delay = retryDelay * ((retry >= maxRetryFactor) ? maxRetryFactor : retry);

  const timer = useRef(null);

  useEffect(() => {
    if (online) {
      setRetry(0);
      clearTimeout(timer.current);
    }
  }, [online]);

  useEffect(() => {
    if (key) {
      setRetry(0);
      clearTimeout(timer.current);
    }
  }, [key]);

  useEffect(() => {
    if (shouldFireCallTimeout) {
      setRetry(0);
      clearTimeout(timer.current);
    }
  }, [shouldFireCallTimeout]);

  // Pass this down to whatever is using the hook so it can retry the call
  const retryCall = useCallback(() => {
    clearTimeout(timer.current);
    setRetry(retry + retryIncrement);
    call();
  }, [retry, call]);

  const forceRetryCall = useCallback(() => {
    clearTimeout(timer.current);
    setRetry(0);
    call();
  }, [call]);

  useEffect(() => {
    if (shouldFireCall) {
      if (retry === 0) {
        if (key) {
          setRetry(retry + retryIncrement);
        }
        call();
      }
    } else if (hasError && shouldRetryCall && (infiniteRetry ? true : (retry <= maxRetries))) {
      timer.current = setTimeout(() => retryCall(), (delay * 1000));
    } else {
      clearTimeout(timer.current);
    }

    return () => {
      clearTimeout(timer.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, shouldFireCall, shouldRetryCall, hasError, key]);

  return { retry: forceRetryCall };
};

export default useCallApiSimple;