import { IntercomProvider, useIntercom } from 'react-use-intercom';
import {
  useCallback, useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { shallowEqual, useSelector } from 'react-redux';
import { Provider } from './context';
import CONFIG from '@/config/global';
import { getProcessEnv } from '@/helpers/env';
import { sendEventToIntercom, initializeIntercom } from '@/helpers/events-intercom';
import useInterval from '@/hooks/utility/useInterval';
import useStateWithRef from '@/hooks/utility/useStateWithRef';
import useUser from '@/hooks/api/useUser';
import { LOGIN_TYPE_SHADOW } from '@/config/login';
import useLanguage from '@/hooks/language/useLanguage';
import { selectPreviousPath } from '@/state/selectors/routes';
import { pathnameStartsWithLocale } from '@/helpers/routing';
import { size } from '@/helpers/lodash';
import useDeepLinks from '@/hooks/browser/useDeepLInks';

const appId = CONFIG.intercomAppId;

const INITIALISE_DELAY = 3000;

const { ENABLE_INTERCOM } = getProcessEnv();

const intercomIsBooted = () => !!(window.Intercom && window.Intercom.booted && window.intercomSettings);

const DEFAULT_BOOT_PARAMS = {};
const DEFAULT_CUSTOM_ATTRIBUTES = {};

const selector = (state) => ({ previousPath: selectPreviousPath(state) });

const IntercomProviderCustom = ({ children }) => {
  const { shouldAttemptDeepLink, deepLinkComplete } = useDeepLinks();
  const [readyForInitialisation, setReadyForInitialisation] = useState(!shouldAttemptDeepLink);
  const [isBooted, setIsBooted] = useState(false);
  const [intercomBootedCheckCount, setIntercomBootedCheckCount] = useState(0);
  const eventsSentBeforeInitialisationQueueRef = useRef([]);

  const {
    value: intercomUserId,
    set: setIntercomUserId,
    get: getIntercomUserId
  } = useStateWithRef();
  const { languageChangeInProgress } = useLanguage();
  const { asPath, locale } = useRouter();
  const { previousPath } = useSelector(selector, shallowEqual);

  const localeHasChanged = useMemo(() => !!previousPath && !!locale && !pathnameStartsWithLocale({
    asPath: previousPath,
    locale
  }), [locale, previousPath]);

  const {
    boot,
    update,
    hardShutdown,
    trackEvent,
    getVisitorId
  } = useIntercom();

  // User info
  const {
    userId,
    user,
    userInfo
  } = useUser();
  const {
    first_name: fn, last_name: ln, type
  } = user || {};
  const isShadowUser = (type === LOGIN_TYPE_SHADOW);
  const { email } = userInfo;
  const fullName = `${ fn || '' }${ fn && ln ? ' ' : '' }${ ln || '' }`;

  useEffect(() => {
    if (shouldAttemptDeepLink && deepLinkComplete) {
      setReadyForInitialisation(true);
    }
  }, [shouldAttemptDeepLink, deepLinkComplete]);

  const bootOrUpdate = useCallback((params = {}) => {
    const paramsWithDefaults = {
      ...DEFAULT_BOOT_PARAMS,
      ...params,
      customAttributes: { ...DEFAULT_CUSTOM_ATTRIBUTES }
    };

    if (ENABLE_INTERCOM) {
      if (intercomIsBooted()) {
        update(paramsWithDefaults);
      } else if (readyForInitialisation) {
        if (shouldAttemptDeepLink || localeHasChanged) {
          // NOTE: When changing the URL before initialisation, the intercom script can be interrupted.
          // Therefore in certain cases, we initialise ourselves:
          // (1): We changed window.location as we attempted deep linking using custom schemes on mobile
          // (2): We change locale due to a switch in language after an immediate language change prompt on app startup
          
          initializeIntercom({
            appId,
            delay: INITIALISE_DELAY
          });
        }

        boot(paramsWithDefaults);
      }
    }
  }, [boot, update, readyForInitialisation, localeHasChanged, shouldAttemptDeepLink]);

  const checkIfBooted = useCallback(() => {
    setIntercomBootedCheckCount((prev) => prev + 1);

    if (!isBooted && intercomIsBooted()) {
      setIsBooted(true);
    }
  }, [isBooted]);

  // Periodically check if intercom has been booted
  useInterval({
    callback: checkIfBooted,
    delay: 500 + (500 * intercomBootedCheckCount),
    disable: !ENABLE_INTERCOM || languageChangeInProgress || isBooted || !userId || (intercomBootedCheckCount > 20)
  });

  // Shutdown on unmount
  useEffect(() => {
    return () => {
      if (ENABLE_INTERCOM) {
        hardShutdown();
      }
    };
  }, [hardShutdown]);

  // Update current user details
  useEffect(() => {
    if (ENABLE_INTERCOM && userId && !languageChangeInProgress) {
      if (isShadowUser) {
        // We do not record information for shadow users, as there is no way in
        // Intercom to reconcile the shadow user after the Keakie user logs in
        bootOrUpdate({
          userId: null,
          name: null,
          email: null
        });

        setIntercomUserId(null);
      } else {
        bootOrUpdate({
          userId,
          ...(fullName ? { name: fullName } : {}),
          ...(email ? { email } : {})
        });

        setIntercomUserId(userId);
      }
    }
  }, [
    bootOrUpdate,
    setIntercomUserId,
    getVisitorId,
    isShadowUser,
    userId,
    email,
    fullName,
    languageChangeInProgress
  ]);

  // Send page view events
  useEffect(() => {
    if (isBooted && asPath) {
      bootOrUpdate();
    }
  }, [asPath, isBooted, bootOrUpdate]);

  const sendIntercomEvent = useCallback(({
    eventName,
    metadata,
    isClosingBrowser = false
  }) => {
    if (!intercomIsBooted() && eventName) {
      eventsSentBeforeInitialisationQueueRef.current.push({
        eventName,
        metadata: metadata || undefined
      });
    } else if (eventName) {
      const currentUserId = getIntercomUserId();
      
      if (isClosingBrowser && currentUserId) {
        sendEventToIntercom({
          eventName,
          metadata,
          userId: currentUserId,
          isClosingBrowser: true
        });
      } else {
        trackEvent(eventName, metadata || undefined);
      }
    }
  }, [
    trackEvent,
    getIntercomUserId
  ]);

  useEffect(() => {
    if (isBooted && !!size(eventsSentBeforeInitialisationQueueRef.current)) {
      eventsSentBeforeInitialisationQueueRef.current.forEach(({ eventName, metadata }) => {
        trackEvent(eventName, metadata);
      });

      eventsSentBeforeInitialisationQueueRef.current = [];
    }
  }, [isBooted, trackEvent, locale, asPath]);

  const value = useMemo(() => ({
    isBooted,
    userIdReady: !!(isBooted && intercomUserId),
    sendIntercomEvent,
    shouldSendEvents: !languageChangeInProgress
  }), [isBooted, intercomUserId, sendIntercomEvent, languageChangeInProgress]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

IntercomProviderCustom.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const IntercomProviderWrapped = ({ children }) => {
  const { shouldAttemptDeepLink } = useDeepLinks();

  return (
    <IntercomProvider
      appId={ appId }
      initializeDelay={ shouldAttemptDeepLink ? undefined : INITIALISE_DELAY }
      shouldInitialize={ !shouldAttemptDeepLink }
    >
      <IntercomProviderCustom>
        { children }
      </IntercomProviderCustom>
    </IntercomProvider>
  );
};

IntercomProviderWrapped.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default IntercomProviderWrapped;