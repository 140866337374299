import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { CHECK_LOGIN_STATUS, HOST } } = CONFIG;

// Check whether the user has an existing login session with Keakie.
// If they do then a user object is returned (this user object omits the statistics information)
const checkLogin = () => {
  return callApi({
    url: HOST(CHECK_LOGIN_STATUS) + CHECK_LOGIN_STATUS,
    method: 'GET',
    errorOn404: true
  });
};

export default checkLogin;