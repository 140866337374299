import { useEffect } from 'react';
import { getWindowSizes } from '@/helpers/browser';
import useDebounce from '@/hooks/utility/useDebounce';

const useWindowSize = ({ debounceTime = 250 } = {}) => {
  const { debouncedValue: debouncedWindowSize, set: setWindowSize } = useDebounce(getWindowSizes(), debounceTime);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSizes());
    };

    window.addEventListener('resize', handleWindowResize);
    
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [debounceTime, setWindowSize]);

  return debouncedWindowSize;
};

export default useWindowSize;