import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const TileGrain = ({ isLoaded }) => {
  return (
    <div className={ classes(styles.tileGrain, { [styles.isLoaded]: !isLoaded }) } />
  );
};

TileGrain.propTypes = { isLoaded: PropTypes.bool };

TileGrain.defaultProps = { isLoaded: false };

export default TileGrain;