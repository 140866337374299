import * as fontSizes from '@/sass/variables/font-sizes.module.scss';

// KEYS
export const FONT_SIZE_KEY_40 = 'var-fs-key-40';
export const FONT_SIZE_KEY_50 = 'var-fs-key-50';
export const FONT_SIZE_KEY_60 = 'var-fs-key-60';
export const FONT_SIZE_KEY_70 = 'var-fs-key-70';
export const FONT_SIZE_KEY_80 = 'var-fs-key-80';
export const FONT_SIZE_KEY_90 = 'var-fs-key-90';
export const FONT_SIZE_KEY_100 = 'var-fs-key-100';
export const FONT_SIZE_KEY_110 = 'var-fs-key-110';
export const FONT_SIZE_KEY_120 = 'var-fs-key-120';
export const FONT_SIZE_KEY_140 = 'var-fs-key-140';
export const FONT_SIZE_KEY_160 = 'var-fs-key-160';

// VALUES
export const FONT_SIZE_040 = fontSizes[FONT_SIZE_KEY_40];
export const FONT_SIZE_050 = fontSizes[FONT_SIZE_KEY_50];
export const FONT_SIZE_060 = fontSizes[FONT_SIZE_KEY_60];
export const FONT_SIZE_070 = fontSizes[FONT_SIZE_KEY_70];
export const FONT_SIZE_080 = fontSizes[FONT_SIZE_KEY_80];
export const FONT_SIZE_090 = fontSizes[FONT_SIZE_KEY_90];
export const FONT_SIZE_100 = fontSizes[FONT_SIZE_KEY_100];
export const FONT_SIZE_110 = fontSizes[FONT_SIZE_KEY_110];
export const FONT_SIZE_120 = fontSizes[FONT_SIZE_KEY_120];
export const FONT_SIZE_140 = fontSizes[FONT_SIZE_KEY_140];
export const FONT_SIZE_160 = fontSizes[FONT_SIZE_KEY_160];

const FONT_SIZES = {
  [FONT_SIZE_040]: FONT_SIZE_040,
  [FONT_SIZE_050]: FONT_SIZE_050,
  [FONT_SIZE_060]: FONT_SIZE_060,
  [FONT_SIZE_070]: FONT_SIZE_070,
  [FONT_SIZE_080]: FONT_SIZE_080,
  [FONT_SIZE_090]: FONT_SIZE_090,
  [FONT_SIZE_100]: FONT_SIZE_100,
  [FONT_SIZE_110]: FONT_SIZE_110,
  [FONT_SIZE_120]: FONT_SIZE_120,
  [FONT_SIZE_140]: FONT_SIZE_140,
  [FONT_SIZE_160]: FONT_SIZE_160
};

export default FONT_SIZES;