import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { getRouteObject } from '@/helpers/routing';
import ButtonCTA from '@/components/component-library/Button/CTA';
import KeakieBrokenIcon from '@/components/component-library/Icon/types/KeakieBroken';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import Text, { FONT_SIZE_090, FONT_SIZE_160 } from '@/components/component-library/Text';
import { DISCOVER_KEY } from '@/config/routeKeys';
import pageKeys from '@/helpers/routing/constants';

const defaultPrimaryButtonConfig = {
  text: 'Home',
  hide: false,
  disableDefaultFunc: false,
  onClick: undefined
};

const defaultSecondaryButtonConfig = {
  text: null,
  hide: true,
  onClick: undefined
};

// Full page error component. Text and click handlers can be modified to suit any generic error
const ErrorFullPage = ({
  primaryText, // Primary text to display
  secondaryText, // Secondary text to display
  tertiaryText, // Tertiary text to display
  primaryButtonConfig, // Config of format: { text: String, onClick: Func, hide: Bool, disableDefaultFunc: Bool } for the primary button
  secondaryButtonConfig // Config of format: { text: String, onClick: Func, hide: Bool } for the secondary button
}) => {
  const router = useRouter();
  const routeObject = getRouteObject(router.asPath) || {};
  const isHomePage = (routeObject.key === DISCOVER_KEY);

  const {
    text: primaryButtonText,
    onClick: primaryButtonOnClick,
    hide: primaryButtonHide,
    disableDefaultFunc: primaryButtonDisableDefault
  } = {
    ...defaultPrimaryButtonConfig,
    ...(isHomePage ? { text: 'Retry' } : {}),
    ...primaryButtonConfig
  };

  const {
    text: secondaryButtonText,
    onClick: secondaryButtonOnClick,
    hide: secondaryButtonHide
  } = {
    ...defaultSecondaryButtonConfig,
    ...secondaryButtonConfig
  };

  const handleClickPrimaryButton = () => {
    if (!primaryButtonDisableDefault) {
      router.replace(pageKeys.discover);
    }

    if (primaryButtonOnClick) {
      primaryButtonOnClick();
    }
  };

  return (
    <div className='error-page-full'>
      <LayoutWrapperHorizontal fillHeight>
        <div className='error-page'>
          <div className='error-information'>
            <Text
              bold
              className='title'
              size={ FONT_SIZE_160 }
              useHeaderFont
            >
              {primaryText}
            </Text>
            <Text
              bold
              className='info'
              size={ FONT_SIZE_090 }
              useHeaderFont
            >
              {secondaryText}
            </Text>
            <Text className='description'>
              {tertiaryText}
            </Text>
            <div className='buttons'>
              {!primaryButtonHide && handleClickPrimaryButton && (
                <ButtonCTA
                  className='error-button'
                  onClick={handleClickPrimaryButton}
                >
                  {primaryButtonText}
                </ButtonCTA>
              )}
              {!secondaryButtonHide && secondaryButtonOnClick && (
                <ButtonCTA
                  className='go-back-button'
                  onClick={secondaryButtonOnClick}
                >
                  {secondaryButtonText}
                </ButtonCTA>
              )}
            </div>
          </div>
          <div className='error-logo'>
            <KeakieBrokenIcon />
          </div>
        </div>
      </LayoutWrapperHorizontal>
    </div>
  );
};

ErrorFullPage.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  tertiaryText: PropTypes.string,
  primaryButtonConfig: PropTypes.object,
  secondaryButtonConfig: PropTypes.object
};

ErrorFullPage.defaultProps = {
  primaryText: 'Error',
  secondaryText: 'An error occurred',
  tertiaryText: 'Sorry, something went wrong.',
  primaryButtonConfig: {},
  secondaryButtonConfig: {}
};

export default ErrorFullPage;
