const initialState = {
  // Audio playback
  keakiePlaybackMode: null, // To be removed from this part of state as the audio player should not care about this
  currentlyPlayingTrack: {
    key: null,
    id: null,
    season: null,
    episodeNumber: null,
    showId: null,
    show: null,
    playbackType: null
  },
  tracklistPosition: {
    index: null,
    id: null
  },
  // Audio tag
  seek: 0,
  volume: 1,
  paused: true,
  muted: false,
  metaLoaded: false,
  error: null,
  stalled: false,
  canPlayFired: false,
  audioInitialised: false,
  readyState: 0,
  resumeFromSeek: 0,
  seekOverrideKey: 0,
  // Episodes
  episodes: {},
  // Queue
  tracklist: [],
  customQueue: []
};

export default initialState;