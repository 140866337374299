import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { Hydrate } from '@tanstack/react-query';
import { Provider as StoreProvider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { hydrateStoreFromCache } from '@/state/caching';
import { store } from '@/state/store';
import { AppFilterProvider } from '@/contexts/app-filter';
import { AppLayoutProvider } from '@/contexts/app-layout';
import { AudioElementProvider } from '@/contexts/audio-playback/audio-element';
import { ConnectivityProvider } from '@/contexts/connectivity';
import { GAProvider } from '@/contexts/google-analytics';
import { FBPixelProvider } from '@/contexts/facebook-pixel';
import { InitialRenderProvider } from '@/contexts/initial-render';
import { IntercomProvider } from '@/contexts/intercom';
import { AirplayProvider } from '@/contexts/airplay';
import { LoginProvider } from '@/contexts/login';
import { MainScrollElementProvider } from '@/contexts/main-scroll-element';
import { RightClickMenuProvider } from '@/contexts/right-click-menu';
import { ShakaPlayerProvider } from '@/contexts/audio-playback/shaka-player';
import { createQueryClient } from '@/react-query';
import {
  activateQuantcastTag, isBrowser, isSafari
} from '@/helpers/browser';
import { attachCachingSubscription, getCache } from '@/helpers/caching';
import { FeatureTogglesProvider } from '@/contexts/feature-toggles-local-storage';
import { SimpleAppStateProvider } from '@/contexts/simple-app-state';
import { ModalProvider } from '@/contexts/modal-provider';
import { LanguageProvider } from '@/contexts/language';
import { DeepLinksProvider } from '@/contexts/deep-links';

const loadPolyfills = async () => {
  // Force polyfill in safari as latest version breaks smooth scrolling
  if (isSafari()) {
    // eslint-disable-next-line no-underscore-dangle
    window.__forceSmoothScrollPolyfill__ = true;
  }
  const smoothscroll = await import('smoothscroll-polyfill');
  smoothscroll.polyfill();
};

let hasAttachedCachingSubscription = false;

const AppProviders = ({ pageProps, children }) => {
  const [{ client, ...clientOptions }] = useState(() => createQueryClient());

  useEffect(() => {
    if (isBrowser()) {
      const hydratedState = getCache({ initialState: store.getState() }).state;

      store.dispatch(hydrateStoreFromCache({ hydratedState }));

      if (!hasAttachedCachingSubscription) {
        attachCachingSubscription(store);
        hasAttachedCachingSubscription = true;
      }

      loadPolyfills();
      activateQuantcastTag();
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <DeepLinksProvider>
        <ConnectivityProvider>
          <PersistQueryClientProvider client={client} persistOptions={clientOptions}>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />
              <InitialRenderProvider>
                <SimpleAppStateProvider>
                  <LanguageProvider>
                    <IntercomProvider>
                      <GAProvider>
                        <FBPixelProvider>
                          <AudioElementProvider>
                            <ShakaPlayerProvider>
                              <AirplayProvider>
                                <LoginProvider>
                                  <MainScrollElementProvider>
                                    <AppFilterProvider>
                                      <ModalProvider>
                                        <AppLayoutProvider>
                                          <RightClickMenuProvider>
                                            <FeatureTogglesProvider>
                                              {children }
                                            </FeatureTogglesProvider>
                                          </RightClickMenuProvider>
                                        </AppLayoutProvider>
                                      </ModalProvider>
                                    </AppFilterProvider>
                                  </MainScrollElementProvider>
                                </LoginProvider>
                              </AirplayProvider>
                            </ShakaPlayerProvider>
                          </AudioElementProvider>
                        </FBPixelProvider>
                      </GAProvider>
                    </IntercomProvider>
                  </LanguageProvider>
                </SimpleAppStateProvider>
              </InitialRenderProvider>
            </Hydrate>
          </PersistQueryClientProvider>
        </ConnectivityProvider>
      </DeepLinksProvider>
    </StoreProvider>
  );
};

AppProviders.propTypes = {
  pageProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AppProviders;
