import { connect } from 'react-redux';
import without from 'lodash.without';
import {
  useCallback, useMemo, useRef
} from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Provider } from './context';
import useAppleLogin from './useAppleLogin';
import useFacebookLogin from './useFacebookLogin';
import { getProcessEnv } from '@/helpers/env';
import { getRouteObject } from '@/helpers/routing';
import { shouldCall } from '@/helpers/api';
import useCallApiSimple from '@/hooks/api/useCallApiSimple';
import {
  selectExternalLoginStatus,
  selectKeakieCheckLoginStatus,
  selectLogin,
  selectKeakieCreateLoginStatus
} from '@/state/selectors/user';
import {
  LOGIN_TYPE_SHADOW, LOGIN_TYPE_FACEBOOK, LOGIN_TYPE_APPLE
} from '@/config/login';
import {
  loginWithExternalProviderFailure,
  loginWithExternalProviderLoading,
  loginWithExternalProviderSuccess,
  checkKeakieLogin,
  createKeakieLogin,
  logout
} from '@/state/account/actions';
import {
  APPLE_REDIRECT_CLIENT_KEY, APP_DOWNLOAD_KEY, APP_DOWNLOAD_PARTNER_PROGRAM_KEY
} from '@/config/routeKeys';
import CONFIG from '@/config/global';
import { getAppleLoginDetails } from '@/helpers/login';

const { allowAppUsageWithoutLogin } = CONFIG;
const { DISABLE_AUTHENTICATION } = getProcessEnv();

const THROTTLE_CHECK_LOGIN_SECONDS = 3;

const LoginProvider = ({
  children,
  externalLoginStatus,
  login,
  keakieCheckLoginStatus,
  keakieCreateLoginStatus,
  router,
  dispatch
}) => {
  const {
    loggedIn = false,
    type = null,
    info = {},
    logoutLoading
  } = login || {};
  const { accessToken } = info;

  const lastCheckedLoginTimestampRef = useRef(0);

  const handleAuthLoading = useCallback(() => {
    dispatch(loginWithExternalProviderLoading({ type }));
  }, [dispatch, type]);

  const handleAuthFailure = useCallback(() => {
    dispatch(loginWithExternalProviderFailure());
  }, [dispatch]);

  const handleFacebookLoginSuccess = useCallback((result) => dispatch(
    loginWithExternalProviderSuccess({
      type: LOGIN_TYPE_FACEBOOK,
      info: {
        ...without(result, ['authResponse']),
        ...result.authResponse
      }
    })
  ), [dispatch]);

  const { onLogin: onFacebookLogin } = useFacebookLogin({
    onLoginLoading: handleAuthLoading,
    onLoginSuccess: handleFacebookLoginSuccess,
    onLoginFailure: handleAuthFailure,
    loggedIn
  });

  const {
    onLogin: onAppleLogin,
    initialiseAppleSDK
  } = useAppleLogin({ loggedIn });

  const checkLoginComplete = (keakieCheckLoginStatus.error || keakieCheckLoginStatus.success);

  const currentRouteObject = useMemo(() => (getRouteObject(router.asPath) || {}), [router.asPath]);

  const isAppleRedirectPage = (currentRouteObject.key === APPLE_REDIRECT_CLIENT_KEY);
  const isAppDownloadPage = (currentRouteObject.key === APP_DOWNLOAD_KEY)
    || (currentRouteObject.key === APP_DOWNLOAD_PARTNER_PROGRAM_KEY);

  const handleCheckLogin = useCallback(() => {
    const hasCheckedLoginRecently = ((Date.now() - lastCheckedLoginTimestampRef.current) / 1000) < THROTTLE_CHECK_LOGIN_SECONDS;
    lastCheckedLoginTimestampRef.current = Date.now();

    // Make sure check call is throttled
    setTimeout(
      () => dispatch(checkKeakieLogin()),
      hasCheckedLoginRecently ? (THROTTLE_CHECK_LOGIN_SECONDS * 1.1 * 1000) : 0
    );
  }, [dispatch]);

  // KEAKIE: Check login status
  useCallApiSimple({
    shouldCall: !DISABLE_AUTHENTICATION
      && !isAppDownloadPage
      && !isAppleRedirectPage
      && shouldCall({ status: keakieCheckLoginStatus }).should
      && !accessToken,
    call: handleCheckLogin,
    waitForAuth: false
  });

  // APPLE: Login
  useCallApiSimple({
    shouldCall: !DISABLE_AUTHENTICATION
      && !isAppDownloadPage
      && !isAppleRedirectPage
      && checkLoginComplete
      && !keakieCreateLoginStatus.success
      && shouldCall({ status: externalLoginStatus }).should
      && (type === LOGIN_TYPE_APPLE),
    call: onAppleLogin,
    waitForAuth: false
  });

  // FACEBOOK: Login
  useCallApiSimple({
    shouldCall: !DISABLE_AUTHENTICATION
      && !isAppDownloadPage
      && !isAppleRedirectPage
      && checkLoginComplete
      && !keakieCreateLoginStatus.success
      && shouldCall({ status: externalLoginStatus }).should
      && (type === LOGIN_TYPE_FACEBOOK),
    call: onFacebookLogin,
    waitForAuth: false
  });

  const handleLogin = useCallback(() => {
    let loginDetails = {};
    
    // Retrieve first and last names from local storage if signing in with Apple
    if (type === LOGIN_TYPE_APPLE) {
      loginDetails = getAppleLoginDetails();
    }

    dispatch(createKeakieLogin({
      type: type || LOGIN_TYPE_SHADOW,
      accessToken,
      firstName: loginDetails.firstName,
      lastName: loginDetails.lastName
    }));
  }, [dispatch, type, accessToken]);

  // KEAKIE: Login
  useCallApiSimple({
    shouldCall: !DISABLE_AUTHENTICATION
      && !isAppDownloadPage
      && !isAppleRedirectPage
      && (checkLoginComplete || accessToken)
      && (externalLoginStatus.success || !type)
      && shouldCall({ status: keakieCreateLoginStatus }).should,
    call: handleLogin,
    waitForAuth: false
  });

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const isLoggingIn = shouldCall({ status: keakieCheckLoginStatus }).should || keakieCheckLoginStatus.loading || keakieCreateLoginStatus.loading;

  const value = useMemo(() => ({
    keakieCreateLoginStatus: !allowAppUsageWithoutLogin ? keakieCreateLoginStatus : { success: true },
    externalLoginStatus: !allowAppUsageWithoutLogin ? externalLoginStatus : { success: true },
    hasLoggedIn: !allowAppUsageWithoutLogin ? loggedIn : true,
    isLoggingOut: !allowAppUsageWithoutLogin ? logoutLoading : false,
    isLoggingIn: !allowAppUsageWithoutLogin ? isLoggingIn : false,
    loginType: !allowAppUsageWithoutLogin ? type : LOGIN_TYPE_SHADOW,
    onFacebookLogin,
    onAppleLogin,
    logout: handleLogout,
    initialiseAppleSDK
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    keakieCreateLoginStatus,
    externalLoginStatus,
    loggedIn,
    logoutLoading,
    isLoggingIn,
    type
  ]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  externalLoginStatus: PropTypes.object,
  login: PropTypes.object.isRequired,
  keakieCheckLoginStatus: PropTypes.object,
  keakieCreateLoginStatus: PropTypes.object,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

LoginProvider.defaultProps = {
  externalLoginStatus: null,
  keakieCheckLoginStatus: null,
  keakieCreateLoginStatus: null
};

// ------ Connect the page with the store and inject required state as props ------
const mapStateToProps = (state) => ({
  externalLoginStatus: selectExternalLoginStatus(state),
  login: selectLogin(state),
  keakieCreateLoginStatus: selectKeakieCreateLoginStatus(state),
  keakieCheckLoginStatus: selectKeakieCheckLoginStatus(state)
});

export default connect(mapStateToProps)(withRouter(LoginProvider));