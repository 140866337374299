import initialState from './initialState';
import {
  updateFeatureEnabledStatus,
  updateUser,
  updateUserEpisodeEdge
} from './utility';
import {
  LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE,
  LOGIN_WITH_EXTERNAL_PROVIDER_LOADING,
  LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS,
  CHECK_KEAKIE_LOGIN_FAILURE,
  CHECK_KEAKIE_LOGIN_LOADING,
  CHECK_KEAKIE_LOGIN_SUCCESS,
  GET_EPISODE_USER_EDGE_FAILURE,
  GET_EPISODE_USER_EDGE_LOADING,
  GET_EPISODE_USER_EDGE_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  CREATE_KEAKIE_LOGIN_FAILURE,
  CREATE_KEAKIE_LOGIN_LOADING,
  CREATE_KEAKIE_LOGIN_SUCCESS,
  UPDATE_FB_USER_INFO,
  LOGOUT_FAILURE,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  RESET_STATE,
  UPDATE_ACCOUNT_FEATURE_FAILURE,
  UPDATE_ACCOUNT_FEATURE_LOADING,
  UPDATE_ACCOUNT_FEATURE_SUCCESS
} from './types';
import { IS_HYDRATED_KEY } from '@/state/caching';
import { setStatus } from '@/helpers/state';
import {
  ADD_EPISODE_TO_LIBRARY_FAILURE,
  ADD_EPISODE_TO_LIBRARY_LOADING,
  ADD_EPISODE_TO_LIBRARY_SUCCESS,
  GET_LIBRARY_SUCCESS,
  REMOVE_EPISODE_FROM_LIBRARY_FAILURE,
  REMOVE_EPISODE_FROM_LIBRARY_LOADING,
  REMOVE_EPISODE_FROM_LIBRARY_SUCCESS
} from '@/state/library/types';

const resetState = (state) => {
  return {
    ...initialState,
    [IS_HYDRATED_KEY]: state[IS_HYDRATED_KEY]
  };
};

const reducer = (state = { ...initialState }, action) => {
  if (!state || !action) { return state; }

  const { type, payload } = action;

  switch (type) {
    case RESET_STATE: {
      return resetState(state);
    }
    case UPDATE_ACCOUNT_FEATURE_LOADING: {
      const { key, enabled } = payload;

      // 1. Toggle the feature loading status (it is loading whilst we wait for the API response)
      // 2. Toggle the value of the feature (toggle the switch) optimistically whilst waiting for API response
      return updateFeatureEnabledStatus({
        state,
        key,
        enabled,
        status: 'loading'
      });
    }
    case UPDATE_ACCOUNT_FEATURE_SUCCESS: {
      const { key } = payload;

      // Toggle the feature loading status. There is no need to toggle the value as we
      // did so optimistically
      return updateFeatureEnabledStatus({
        state,
        key,
        onlyUpdateLoading: true,
        status: 'success'
      });
    }
    case UPDATE_ACCOUNT_FEATURE_FAILURE: {
      const { key, enabled } = payload;

      // 1. Toggle the feature loading status
      // 2. Toggle the value of the feature back to what it was as the update failed
      return updateFeatureEnabledStatus({
        state,
        key,
        enabled,
        status: 'failure'
      });
    }
    case GET_EPISODE_USER_EDGE_LOADING: {
      const { id } = payload;

      // Mark the user episode edge as loading
      return updateUserEpisodeEdge({
        state,
        status: 'loading',
        episodeId: id
      });
    }
    case GET_EPISODE_USER_EDGE_SUCCESS: {
      const { id, edge } = payload;

      // 1. Mark the user episode edge as successful
      // 2. Store the edge in state
      return updateUserEpisodeEdge({
        state,
        status: 'success',
        episodeId: id,
        episodeEdge: edge
      });
    }
    case GET_LIBRARY_SUCCESS: {
      return {
        ...state,
        episodeEdges: {}
      };
    }
    case GET_EPISODE_USER_EDGE_FAILURE: {
      const { id } = payload;

      // Mark the user episode edge as failed
      return updateUserEpisodeEdge({
        state,
        status: 'failure',
        episodeId: id
      });
    }
    case ADD_EPISODE_TO_LIBRARY_LOADING:
    case REMOVE_EPISODE_FROM_LIBRARY_LOADING: {
      const { id } = payload;

      const currentEdge = state.episodeEdges[id] || {};

      return {
        ...state,
        episodeEdges: {
          ...state.episodeEdges,
          [id]: {
            ...currentEdge,
            edge: {
              ...currentEdge.edge,
              in_library: !(currentEdge.edge || {}).in_library
            },
            ...setStatus('status', 'loading')
          }
        }
      };
    }
    case ADD_EPISODE_TO_LIBRARY_SUCCESS:
    case REMOVE_EPISODE_FROM_LIBRARY_SUCCESS: {
      const { id } = payload;

      const currentEdge = state.episodeEdges[id] || {};

      return {
        ...state,
        episodeEdges: {
          ...state.episodeEdges,
          [id]: {
            ...currentEdge,
            ...setStatus('status', 'success')
          }
        }
      };
    }
    case ADD_EPISODE_TO_LIBRARY_FAILURE:
    case REMOVE_EPISODE_FROM_LIBRARY_FAILURE: {
      const { id } = payload;

      const currentEdge = state.episodeEdges[id] || {};

      return {
        ...state,
        episodeEdges: {
          ...state.episodeEdges,
          [id]: {
            ...currentEdge,
            edge: {
              ...currentEdge.edge,
              in_library: !(currentEdge.edge || {}).in_library
            },
            ...setStatus('status', 'success')
          }
        }
      };
    }
    case CHECK_KEAKIE_LOGIN_LOADING: {
      return {
        ...state,
        ...setStatus('keakieCheckLoginStatus', 'loading')
      };
    }
    case CHECK_KEAKIE_LOGIN_SUCCESS: {
      const {
        user, type: loginType, hasLoggedIn
      } = payload;

      return {
        ...state,
        ...setStatus('keakieCheckLoginStatus', 'success'),
        ...setStatus('keakieCreateLoginStatus', 'success'),
        user,
        login: {
          ...state.login,
          type: loginType,
          loggedIn: hasLoggedIn
        }
      };
    }
    case CHECK_KEAKIE_LOGIN_FAILURE: {
      return {
        ...state,
        ...setStatus('keakieCheckLoginStatus', 'failure'),
        ...setStatus('keakieCreateLoginStatus', 'default')
      };
    }
    case LOGIN_WITH_EXTERNAL_PROVIDER_LOADING: {
      const { type: loginType } = payload;

      return {
        ...state,
        ...setStatus('externalLoginStatus', 'loading'),
        ...setStatus('keakieCreateLoginStatus', 'default'),
        login: {
          ...initialState.login,
          type: loginType
        }
      };
    }
    case LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS: {
      const { type: loginType, info } = payload;

      return {
        ...state,
        ...setStatus('externalLoginStatus', 'success'),
        login: {
          ...state.login,
          info,
          type: loginType
        }
      };
    }
    case LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE: {
      return {
        ...state,
        ...setStatus('externalLoginStatus', 'failure'),
        login: initialState.login
      };
    }
    case CREATE_KEAKIE_LOGIN_LOADING: {
      const { type: loginType } = payload;

      return {
        ...state,
        ...setStatus('keakieCreateLoginStatus', 'loading'),
        login: {
          ...state.login,
          type: loginType,
          loggedIn: false
        }
      };
    }
    case CREATE_KEAKIE_LOGIN_SUCCESS: {
      const { user, hasLoggedIn } = payload;

      return {
        ...state,
        ...setStatus('keakieCheckLoginStatus', 'success'),
        ...setStatus('keakieCreateLoginStatus', 'success'),
        user,
        login: {
          ...state.login,
          loggedIn: hasLoggedIn
        }
      };
    }
    case CREATE_KEAKIE_LOGIN_FAILURE: {
      return {
        ...state,
        ...setStatus('keakieCreateLoginStatus', 'failure'),
        login: {
          ...state.login,
          type: null,
          info: {},
          loggedIn: false
        }
      };
    }
    case UPDATE_FB_USER_INFO: {
      const { email, profileImgSrc } = payload;

      return {
        ...state,
        login: {
          ...state.login,
          info: {
            ...state.login.info,
            email,
            profileImgSrc,
            fetched: true
          }
        }
      };
    }
    case LOGOUT_LOADING: {
      return {
        ...state,
        login: {
          ...state.login,
          logoutLoading: true
        }
      };
    }
    case LOGOUT_SUCCESS: {
      return resetState(state);
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        login: {
          ...state.login,
          logoutLoading: false
        }
      };
    }
    case GET_USER_LOADING: {
      return updateUser({
        state,
        status: 'loading'
      });
    }
    case GET_USER_SUCCESS: {
      const { user } = payload;
      
      return updateUser({
        state,
        user,
        status: 'success'
      });
    }
    case GET_USER_FAILURE: {
      return updateUser({
        state,
        status: 'failure'
      });
    }
    default:
      return state;
  }
};

export default reducer;