import {
  LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE,
  LOGIN_WITH_EXTERNAL_PROVIDER_LOADING,
  LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS,
  CHECK_KEAKIE_LOGIN_FAILURE,
  CHECK_KEAKIE_LOGIN_LOADING,
  CHECK_KEAKIE_LOGIN_SUCCESS,
  GET_EPISODE_USER_EDGE_FAILURE,
  GET_EPISODE_USER_EDGE_LOADING,
  GET_EPISODE_USER_EDGE_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  CREATE_KEAKIE_LOGIN_FAILURE,
  CREATE_KEAKIE_LOGIN_LOADING,
  CREATE_KEAKIE_LOGIN_SUCCESS,
  UPDATE_FB_USER_INFO,
  LOGOUT_FAILURE,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  RESET_STATE,
  UPDATE_ACCOUNT_FEATURE_FAILURE,
  UPDATE_ACCOUNT_FEATURE_LOADING,
  UPDATE_ACCOUNT_FEATURE_SUCCESS
} from './types';
import { LOGIN_TYPE_APPLE, LOGIN_TYPE_SHADOW } from '@/config/login';
import checkLoginCall from '@/helpers/api/account/checkLoginStatus';
import getEpisodeUserEdgeCall from '@/helpers/api/episodes/getEpisodeUserEdge';
import getUserCall from '@/helpers/api/account/getUser';
import loginCall from '@/helpers/api/account/login';
import logoutCall from '@/helpers/api/account/logout';
import updateAccountFeatureCall from '@/helpers/api/account/toggleAccountFeature';
import { GAloggedIn, GAloggedOut } from '@/helpers/events-ga/login';
import { FBloggedIn, FBloggedOut } from '@/helpers/events-fb-pixel/login';
import { formatUser } from '@/helpers/common';
import { clearAppleLoginDetails } from '@/helpers/login';
import { sendEventToGA } from '@/helpers/events-ga';
import { sendCustomEventToFB } from '@/helpers/events-fb-pixel';
import { selectUserId, selectLoginType } from '@/state/selectors/user';

export const getUser = () => (dispatch) => {
  dispatch({ type: GET_USER_LOADING });

  return getUserCall()
    .then(({ json: user }) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: { user }
      });
    })
    .catch(() => {
      dispatch({ type: GET_USER_FAILURE });
    });
};

export const resetAccountState = () => (dispatch) => {
  dispatch({ type: RESET_STATE });
};

export const toggleAccountFeature = ({
  key, enabled, apiKey
}) => (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNT_FEATURE_LOADING,
    payload: {
      key,
      enabled
    }
  });
  
  return updateAccountFeatureCall({
    key: apiKey,
    enabled
  })
    .then(() => {
      dispatch({
        type: UPDATE_ACCOUNT_FEATURE_SUCCESS,
        payload: { key }
      });
    })
    .catch(() => {
      dispatch({
        type: UPDATE_ACCOUNT_FEATURE_FAILURE,
        payload: {
          key,
          enabled: !enabled
        }
      });
    });
};

export const getEpisodeUserEdge = ({ episodeId }) => (dispatch) => {
  dispatch({
    type: GET_EPISODE_USER_EDGE_LOADING,
    payload: { id: episodeId }
  });

  return getEpisodeUserEdgeCall({ episodeId })
    .then(({ json: edge }) => {
      dispatch({
        type: GET_EPISODE_USER_EDGE_SUCCESS,
        payload: {
          id: episodeId,
          edge
        }
      });
    })
    .catch(() => {
      dispatch({
        type: GET_EPISODE_USER_EDGE_FAILURE,
        payload: { id: episodeId }
      });
    });
};

export const loginWithExternalProviderLoading = ({ type }) => (dispatch) => {
  dispatch({
    type: LOGIN_WITH_EXTERNAL_PROVIDER_LOADING,
    payload: { type: type || LOGIN_TYPE_SHADOW }
  });
};

export const loginWithExternalProviderSuccess = ({ type, info }) => (dispatch) => {
  dispatch({
    type: LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS,
    payload: {
      type,
      info
    }
  });
};

export const loginWithExternalProviderFailure = () => (dispatch) => {
  dispatch({ type: LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE });
};

export const createKeakieLogin = ({
  type,
  accessToken,
  firstName,
  lastName
}) => (dispatch) => {
  dispatch({
    type: CREATE_KEAKIE_LOGIN_LOADING,
    payload: { type }
  });

  return loginCall({
    type,
    accessToken,
    firstName,
    lastName
  })
    .then(({ json: user }) => {
      const formattedUser = formatUser(user);
      const { type: userType } = formattedUser;

      dispatch({
        type: CREATE_KEAKIE_LOGIN_SUCCESS,
        payload: {
          user: formattedUser,
          type: type === LOGIN_TYPE_SHADOW ? LOGIN_TYPE_SHADOW : userType,
          hasLoggedIn: (userType !== LOGIN_TYPE_SHADOW)
        }
      });

      if (type !== LOGIN_TYPE_SHADOW) {
        sendEventToGA({
          ...GAloggedIn({ type }),
          userId: user.id
        });

        sendCustomEventToFB({ ...FBloggedIn() });
      }
    })
    .catch(() => {
      dispatch({ type: CREATE_KEAKIE_LOGIN_FAILURE });
    });
};

export const checkKeakieLogin = () => (dispatch) => {
  dispatch({ type: CHECK_KEAKIE_LOGIN_LOADING });

  return checkLoginCall()
    .then(({ json: user = {} }) => {
      const formattedUser = formatUser(user);
      const { type: userType } = formattedUser;

      dispatch({
        type: CHECK_KEAKIE_LOGIN_SUCCESS,
        payload: {
          user: formattedUser,
          type: userType,
          hasLoggedIn: (userType !== LOGIN_TYPE_SHADOW)
        }
      });
    })
    .catch(() => {
      dispatch({ type: CHECK_KEAKIE_LOGIN_FAILURE });
    });
};

export const updateFacebookUserInfo = ({ email, profileImgSrc }) => (dispatch) => dispatch({
  type: UPDATE_FB_USER_INFO,
  payload: {
    email,
    profileImgSrc
  }
});

export const logout = ({ shouldCallApi = true } = {}) => (dispatch, getState) => {
  const loginType = selectLoginType(getState());

  if (loginType === LOGIN_TYPE_APPLE) {
    clearAppleLoginDetails();
  }

  if (!shouldCallApi) {
    return dispatch({ type: LOGOUT_SUCCESS });
  }

  const userId = selectUserId(getState());

  dispatch({ type: LOGOUT_LOADING });

  return logoutCall()
    .then(() => {
      dispatch({ type: LOGOUT_SUCCESS });

      sendEventToGA({
        ...GAloggedOut(),
        userId
      });

      sendCustomEventToFB({ ...FBloggedOut() });
    })
    .catch(() => {
      dispatch({ type: LOGOUT_FAILURE });
    });
};