import ReactGA from 'react-ga4';
import { getProcessEnv } from '@/helpers/env';
import { isBrowser } from '@/helpers/browser';
import { GA_DIM_KEAKIE_APP } from '@/config/events-ga/dimensions';

const { ENABLE_GA, ENABLE_GA_DEBUG } = getProcessEnv();

export const sendEventToGA = ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  userId,
  nonInteraction,
  transport = 'xhr',
  dimensions = {},
  metrics = {}
}) => {
  if (isBrowser() && ENABLE_GA) {
    ReactGA.send({
      hitType: 'event',
      // Built-in params
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      userId,
      debug_mode: ENABLE_GA_DEBUG,
      nonInteraction,
      transport,
      // Custom params
      ...dimensions,
      ...metrics,
      // Common custom params
      [GA_DIM_KEAKIE_APP]: 'web'
    });
  }
};

export const setPersistentPropertiesInGA = (keyValuePairs) => {
  if (isBrowser() && ENABLE_GA) {
    ReactGA.gtag('set', 'user_properties', keyValuePairs);
  }
};