import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, GET_EPISODE } } = CONFIG;

// Get the full episode. We need channel slug, episode number, season number and episode number
// as we request the episode based on the url in the user's browser
const getEpisode = ({
  channelSlug, season, episodeNumber
}) => {
  return callApi({
    url: HOST(GET_EPISODE) + GET_EPISODE
      .replace(':channel_slug', channelSlug)
      .replace(':episode_number', episodeNumber)
      .replace(':season', season),
    errorOn404: true
  });
};

export default getEpisode;