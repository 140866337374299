import languageParser from 'accept-language-parser';
import some from 'lodash.some';
import sortBy from 'lodash.sortby';
import { size } from '@/helpers/lodash';
import T_LOCALES, {
  T_LOCALES_EQUIVALENCY_SETS,
  T_LOCALE_BASE,
  T_LOCALE_NEXT_DEFAULT
} from '@/config/translations/languages.mjs';

export const getBaseVersionOfLocale = (locale) => {
  if (!locale) {
    return undefined;
  }

  return locale;
};

export const getUserPreferredLocale = ({ ctx, req }) => {
  const request = (ctx ? ctx.req : req);

  const acceptLanguageHeader = request?.headers?.['accept-language']
    || request?.headers?.get?.('accept-language');

  const userLocales = (acceptLanguageHeader || '')
    .split(',')
    .map((language) => {
      const [code, region] = language.trim().split('-');
      return {
        code,
        region
      };
    });

  const preferredLocale = userLocales[0]?.code || undefined;

  return {
    acceptLanguageHeader,
    preferredLocale: getBaseVersionOfLocale(preferredLocale) || 'en'
  };
};

export const getCurrentLanguage = () => {
  return 'en';
};

export const checkIfLanguagesDiffer = ({ lang, otherLang }) => {
  if (!lang || !otherLang) {
    return false;
  }

  return lang !== otherLang;
};
