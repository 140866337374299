import debounce from 'lodash.debounce';
import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, LIBRARY } } = CONFIG;

// Get library tracks for the current user.
// This is debounced as it can be called from multiple areas of the app at the same time
// eg queue, library page
const getLibrary = debounce(
  (queryParams) => {
    return callApi({
      url: HOST(LIBRARY) + LIBRARY,
      queryParams
    });
  },
  500,
  {
    leading: true,
    trailing: false
  }
);

export default getLibrary;