export const UPDATE_ACCOUNT_FEATURE_LOADING = 'account/UPDATE_ACCOUNT_FEATURE_LOADING';
export const UPDATE_ACCOUNT_FEATURE_SUCCESS = 'account/UPDATE_ACCOUNT_FEATURE_SUCCESS';
export const UPDATE_ACCOUNT_FEATURE_FAILURE = 'account/UPDATE_ACCOUNT_FEATURE_FAILURE';

export const GET_EPISODE_USER_EDGE_LOADING = 'account/GET_EPISODE_USER_EDGE_LOADING';
export const GET_EPISODE_USER_EDGE_SUCCESS = 'account/GET_EPISODE_USER_EDGE_SUCCESS';
export const GET_EPISODE_USER_EDGE_FAILURE = 'account/GET_EPISODE_USER_EDGE_FAILURE';

export const LOGIN_WITH_EXTERNAL_PROVIDER_LOADING = 'account/LOGIN_WITH_EXTERNAL_PROVIDER_LOADING';
export const LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS = 'account/LOGIN_WITH_EXTERNAL_PROVIDER_SUCCESS';
export const LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE = 'account/LOGIN_WITH_EXTERNAL_PROVIDER_FAILURE';

export const CHECK_KEAKIE_LOGIN_LOADING = 'account/CHECK_KEAKIE_LOGIN_LOADING';
export const CHECK_KEAKIE_LOGIN_SUCCESS = 'account/CHECK_KEAKIE_LOGIN_SUCCESS';
export const CHECK_KEAKIE_LOGIN_FAILURE = 'account/CHECK_KEAKIE_LOGIN_FAILURE';

export const CREATE_KEAKIE_LOGIN_LOADING = 'account/CREATE_KEAKIE_LOGIN_LOADING';
export const CREATE_KEAKIE_LOGIN_SUCCESS = 'account/CREATE_KEAKIE_LOGIN_SUCCESS';
export const CREATE_KEAKIE_LOGIN_FAILURE = 'account/CREATE_KEAKIE_LOGIN_FAILURE';

export const UPDATE_FB_USER_INFO = 'account/UPDATE_FB_USER_INFO';

export const LOGOUT_LOADING = 'account/LOGOUT_LOADING';
export const LOGOUT_SUCCESS = 'account/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'account/LOGOUT_FAILURE';

export const GET_USER_LOADING = 'account/GET_USER_LOADING';
export const GET_USER_SUCCESS = 'account/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'account/GET_USER_FAILURE';

export const RESET_STATE = 'account/RESET_STATE';