import React from 'react';
import PropTypes from 'prop-types';
import { getProcessEnv, getProcessEnvPublic } from '../helpers/env';
import { isBrowser, intersectionObserverSupported } from '@/helpers/browser';
import { errors } from '@/config/events-keakie';
import App from '@/components/app/App';
import ErrorBoundary from '@/components/app/Error/ErrorBoundary';
import '@/sass/styles.scss';
import { getUserPreferredLocale } from '@/helpers/language';
import AppProviders from '@/components/app/App/AppProviders';

if (!intersectionObserverSupported()) {
  await import('intersection-observer');
}

const {
  USE_WHY_DID_YOU_RENDER,
  NODE_ENV,
  USE_MOCK_API
} = getProcessEnv();

const isCypress = (isBrowser() ? window.Cypress : process.env.CYPRESS);

if (USE_MOCK_API && !isCypress) {
  const { workerStart } = await import('@/__mocks__/index.js');
  await workerStart();
}

if (USE_WHY_DID_YOU_RENDER) {
  await import('@/scripts-js/wdyr');
}

// NOT IN PRODUCTION: Log information about environment variables
if (NODE_ENV !== 'production') {
  // eslint-disable-next-line
  console.log('Environment Details', getProcessEnvPublic());
}

const MyApp = ({
  Component,
  router,
  pageProps,
  userPreferredLocale
}) => {
  return (
    <ErrorBoundary
      errorDescription="An error occurred in the app."
      errorKey="app_error_boundary"
    >
      {({ hasError } = {}) => (
        <AppProviders
          pageProps={pageProps}
          userPreferredLocale={userPreferredLocale}
        >
          <App
            Component={Component}
            hasError={hasError}
            pageProps={pageProps}
            router={router}
          />
        </AppProviders>
      )}
    </ErrorBoundary>
  );
};

MyApp.getInitialProps = ({ ctx }) => {
  const {
    acceptLanguageHeader,
    preferredLocale
  } = getUserPreferredLocale({ req: ctx.req });

  return {
    userPreferredLocale: preferredLocale,
    acceptLanguageHeader
  };
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  userPreferredLocale: PropTypes.string
};

MyApp.defaultProps = {
  userPreferredLocale: undefined
};

export default MyApp;
