import { IS_HYDRATED_KEY } from '@/state/caching';

const EMPTY_OBJECT = {};

// USER
export const selectUserAccount = (state) => state.account || EMPTY_OBJECT;
export const selectProfileImageAssets = (state) => selectUserAccount(state).assets;
export const selectUserEpisodeEdges = (state) => selectUserAccount(state).episodeEdges;
export const selectUser = (state) => selectUserAccount(state).user;
export const selectUserId = (state) => (selectUser(state) || EMPTY_OBJECT).id;
export const selectUserStatus = (state) => selectUserAccount(state).userStatus;
export const selectAccountFeaturesStatus = (state) => selectUserAccount(state).featuresStatus || EMPTY_OBJECT;
export const selectLogin = (state) => selectUserAccount(state).login || EMPTY_OBJECT;
export const selectLoginInfo = (state) => selectLogin(state).info || EMPTY_OBJECT;
export const selectLoginType = (state) => selectLogin(state).type || null;
export const selectKeakieCheckLoginStatus = (state) => selectUserAccount(state).keakieCheckLoginStatus || EMPTY_OBJECT;
export const selectKeakieCreateLoginStatus = (state) => selectUserAccount(state).keakieCreateLoginStatus || EMPTY_OBJECT;
export const selectExternalLoginStatus = (state) => selectUserAccount(state).externalLoginStatus || EMPTY_OBJECT;
export const selectExternalLoginIsLoggedIn = (state) => selectLogin(state).loggedIn || false;
export const selectCacheHydrationComplete = (state) => selectUserAccount(state)[IS_HYDRATED_KEY] || false;