// https://shaka-player-demo.appspot.com/docs/api/tutorial-config.html
// https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.PlayerConfiguration
// https://github.com/google/shaka-player/blob/master/docs/tutorials/config.md
// https://github.com/google/shaka-player/blob/master/docs/tutorials/network-and-buffering-config.md
// https://github.com/google/shaka-player/blob/master/docs/tutorials/drm-config.md

const retryParameters = {
  // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.RetryParameters
  maxAttempts: 3, // Max number of times to retry a call
  baseDelay: 2000, // Delay between calls
  backoffFactor: 1, // Factor to increase base delay for subsequent retries
  fuzzFactor: 0.5, // Factor of variance when increasing the base delay
  timeout: 0 // Timeout for each call (0 means never)
};

const restrictions = {
  // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Restrictions
  minWidth: 0,
  minHeight: 0,
  minPixels: 0,
  minBandwidth: 0
};

export const devStreamingConfig = {
  short: {
    bufferingGoal: 10, // Seconds we will buffer in advance
    rebufferingGoal: 2, // Seconds of content needed before playback begins. Must be less than bufferingGoal
    bufferBehind: 10 // Seconds of content to be kept prior to current playback time
  },
  long: {
    bufferingGoal: 20,
    rebufferingGoal: 2,
    bufferBehind: 20
  }
};

const shakaPlayerConfig = {
  drm: {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.DrmConfiguration
    retryParameters: { ...retryParameters },
    servers: {},
    clearKeys: {},
    advanced: {},
    delayLicenseRequestUntilPlayed: false
  },
  manifest: {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.ManifestConfiguration
    retryParameters: { ...retryParameters },
    defaultPresentationDelay: 10,
    dash: {
      clockSyncUri: '',
      ignoreDrmInfo: false,
      xlinkFailGracefully: false,
      ignoreMinBufferTime: false,
      autoCorrectDrift: true
    },
    hls: { ignoreTextStreamFailures: false }
  },
  streaming: {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.StreamingConfiguration
    retryParameters: { ...retryParameters },
    ignoreTextStreamFailures: false,
    alwaysStreamText: false,
    startAtSegmentBoundary: false,
    durationBackoff: 1,
    forceTransmuxTS: false,
    safeSeekOffset: 5,
    stallEnabled: true,
    stallThreshold: 1,
    stallSkip: 0.1,
    useNativeHlsOnSafari: false,
    ...devStreamingConfig.short
  },
  offline: {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.OfflineConfiguration
    usePersistentLicense: true
  },
  abr: {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.AbrConfiguration
    enabled: true,
    defaultBandwidthEstimate: 10000000,
    switchInterval: 8,
    bandwidthUpgradeTarget: 0.85,
    bandwidthDowngradeTarget: 0.95,
    restrictions: { ...restrictions }
  },
  preferredAudioLanguage: undefined,
  preferredTextLanguage: undefined,
  preferredVariantRole: undefined,
  preferredTextRole: undefined,
  preferredAudioChannelCount: 2,
  restrictions: { ...restrictions },
  playRangeStart: 0
};

export default shakaPlayerConfig;