import { createSelector } from 'reselect';
import {
  audioSupported,
  computeTracklistHistoryEpisodes,
  computeTracklistQueueEpisodes,
  extractManifestUrlsFromEpisode,
  seekHasPassedThresholdForPreviousTrack,
  shouldUseHlsUrl
} from '@/helpers/audio';
import { truthyOrZero } from '@/helpers/math';
import { formatEpisode } from '@/helpers/common';

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

// AUDIO
// --------------
export const selectAudio = (state) => state.audio;

export const selectEpisodesState = (state) => selectAudio(state).episodes;

export const selectEpisodeById = createSelector(
  [selectEpisodesState, (_, episodeId) => episodeId],
  (episodesState, episodeId) => {
    const { episode, fullEpisode } = episodesState[episodeId] || DEFAULT_OBJECT;

    return formatEpisode(fullEpisode || episode || DEFAULT_OBJECT);
  }
);

export const selectCurrentEpisodeId = (state) => selectAudio(state).currentlyPlayingTrack.id;

export const selectCurrentEpisodeState = createSelector(
  [
    selectEpisodesState,
    selectCurrentEpisodeId
  ],
  (episodesState, currentEpisodeId) => (
    currentEpisodeId
      ? episodesState[currentEpisodeId]
      : null
  )
);

export const selectCurrentEpisode = createSelector(
  [selectCurrentEpisodeState],
  (currentEpisodeState) => {
    const { episode, fullEpisode } = currentEpisodeState || DEFAULT_OBJECT;

    return formatEpisode(fullEpisode || episode);
  }
);

export const selectCurrentEpisodeKey = (state) => selectAudio(state).currentlyPlayingTrack.key
  || selectCurrentEpisodeId(state)
  || null;

export const selectCurrentEpisodeSeason = (state) => selectAudio(state).currentlyPlayingTrack.season || null;
export const selectCurrentEpisodeNumber = (state) => selectAudio(state).currentlyPlayingTrack.episodeNumber || null;

export const selectCurrentEpisodePlaybackType = (state) => selectAudio(state).currentlyPlayingTrack.playbackType;

export const selectCurrentEpisodeManifestUrls = createSelector(
  [
    selectCurrentEpisode
  ],
  (currentEpisode) => extractManifestUrlsFromEpisode(currentEpisode)
);

export const selectCurrentManifestUrl = createSelector(
  [
    selectCurrentEpisodeManifestUrls
  ],
  (manifestUrls) => (
    (
      (audioSupported() && shouldUseHlsUrl())
        ? manifestUrls.hls
        : manifestUrls.dash
    ) || null
  )
);

export const selectCurrentEpisodeStatus = createSelector(
  [
    selectCurrentEpisodeState
  ],
  (currentEpisodeState) => {
    const { fullEpisodeStatus } = currentEpisodeState || DEFAULT_OBJECT;

    return fullEpisodeStatus || DEFAULT_OBJECT;
  }
);

export const selectCurrentShowId = (state) => selectAudio(state).currentlyPlayingTrack.showId;

export const selectCurrentShow = (state) => selectAudio(state).currentlyPlayingTrack.show || DEFAULT_OBJECT;

export const selectCurrentShowIsPodcast = (state) => selectCurrentShow(state).format === 'podcast';

export const selectCurrentShowSlug = createSelector(
  [selectCurrentShow],
  (currentShow) => (currentShow ? currentShow.slug : null)
);

export const selectAudioStreamingContext = (state) => selectAudio(state).streamingContext;

// TRACKLIST
// --------------
export const selectTracklist = (state) => selectAudio(state).tracklist;

export const selectTracklistCount = (state) => selectAudio(state).tracklist.length;

export const selectTracklistEpisodes = createSelector(
  [selectTracklist, selectEpisodesState],
  (tracklist, episodesState) => {
    return tracklist.map(({ id }) => {
      const { episode, fullEpisode } = episodesState[id] || DEFAULT_OBJECT;

      return fullEpisode || episode;
    }).filter(_ => _);
  }
);

export const selectCurrentTracklistIndex = (state) => selectAudio(state).tracklistPosition.index;

export const selectTracklistQueue = createSelector(
  [selectTracklistEpisodes, selectCurrentTracklistIndex],
  (tracklistEpisodes, currentTracklistIndex) => (
    (truthyOrZero(currentTracklistIndex) && currentTracklistIndex >= 0)
      ? computeTracklistQueueEpisodes({
        episodePreviews: tracklistEpisodes,
        currentIndex: currentTracklistIndex
      })
      : DEFAULT_ARRAY
  )
);

export const selectTracklistQueueCount = createSelector(
  [selectTracklistQueue],
  (tracklistQueue) => tracklistQueue.length
);

export const selectTracklistHistory = createSelector(
  [selectTracklistEpisodes, selectCurrentTracklistIndex, selectCurrentEpisodePlaybackType],
  (tracklistEpisodes, currentTracklistIndex, currentEpisodeQueueType) => (
    (truthyOrZero(currentTracklistIndex) && currentTracklistIndex >= 0)
      ? computeTracklistHistoryEpisodes({
        episodePreviews: tracklistEpisodes,
        currentIndex: currentEpisodeQueueType === 'custom' ? currentTracklistIndex + 1 : currentTracklistIndex
      })
      : DEFAULT_ARRAY
  )
);

export const selectTracklistHistoryCount = createSelector(
  [selectTracklistHistory],
  (tracklistHistory) => tracklistHistory.length
);

// CUSTOM QUEUE
// --------------
export const selectCustomQueue = (state) => selectAudio(state).customQueue;

export const selectCustomQueueCount = (state) => selectCustomQueue(state).length;

export const selectCustomQueueEpisodes = createSelector(
  [selectCustomQueue, selectEpisodesState],
  (customQueue, episodesState) => {
    return customQueue.map(({ id }) => {
      const { episode, fullEpisode } = episodesState[id] || DEFAULT_OBJECT;

      return fullEpisode || episode;
    }).filter(_ => _);
  }
);

export const selectCustomQueueEpisodeIds = createSelector(
  [selectCustomQueue],
  (customQueue) => customQueue.map(({ id }) => id)
);

export const selectCustomQueueKeys = createSelector(
  [selectCustomQueue],
  (customQueue) => customQueue.map(({ key }) => key)
);

// COMBINED QUEUE
// --------------
export const selectQueueTotalCount = createSelector(
  [
    selectTracklistQueueCount,
    selectCustomQueueCount
  ],
  (tracklistQueueCount, customQueueCount) => (tracklistQueueCount + customQueueCount)
);

// AUDIO TAG
// --------------
export const selectAudioSeek = (state) => selectAudio(state).seek;
export const selectAudioVolume = (state) => selectAudio(state).volume;
export const selectAudioPaused = (state) => selectAudio(state).paused;
export const selectAudioMuted = (state) => selectAudio(state).muted;
export const selectAudioMetaLoaded = (state) => selectAudio(state).metaLoaded;
export const selectAudioError = (state) => selectAudio(state).error;
export const selectAudioStalled = (state) => selectAudio(state).stalled;
export const selectAudioCanPlayFired = (state) => selectAudio(state).canPlayFired;
export const selectAudioInitialised = (state) => selectAudio(state).audioInitialised;
export const selectAudioReadyState = (state) => selectAudio(state).readyState;
export const selectAudioReadyForSeek = createSelector(
  [
    selectAudioCanPlayFired,
    selectAudioMetaLoaded,
    selectAudioReadyState
  ],
  (canPlay, metaLoaded, readyState) => (
    metaLoaded
      && (shouldUseHlsUrl() ? true : canPlay) // IMPORTANT: Where we use the HLS url, the canPlay event may not fire
      && (readyState >= 2)
  )
);
export const selectAudioReadyForPlayback = createSelector(
  [
    selectAudioCanPlayFired,
    selectAudioMetaLoaded,
    selectAudioReadyState,
    selectAudioError
  ],
  (canPlay, metaLoaded, readyState) => (
    metaLoaded
      && (shouldUseHlsUrl() ? true : canPlay) // IMPORTANT: Where we use the HLS url, the canPlay event may not fire
      && (shouldUseHlsUrl() ? true : (readyState >= 2))
  )
);
export const selectAudioSeekOverrideKey = (state) => selectAudio(state).seekOverrideKey;
export const selectResumeFromSeek = (state) => selectAudio(state).resumeFromSeek;

export const selectNextTrackExists = createSelector(
  [selectQueueTotalCount],
  (queueCount) => queueCount > 0
);

export const selectNextTrackIsAvailable = createSelector(
  [selectNextTrackExists, selectAudioInitialised],
  (nextTrackExists) => nextTrackExists
);

export const selectPreviousTrackExists = createSelector(
  [selectTracklistHistoryCount],
  (tracklistHistoryCount) => tracklistHistoryCount > 0
);

export const selectPreviousTrackIsAvailable = createSelector(
  [selectPreviousTrackExists, selectAudioSeek],
  (previousTrackExists, audioSeek) => (previousTrackExists || seekHasPassedThresholdForPreviousTrack(audioSeek))
);

export const selectPlayPauseIsAvailable = createSelector(
  [selectAudioReadyForPlayback, selectAudioInitialised, selectCurrentEpisodeId, selectCustomQueueCount, selectAudioPaused],
  (audioReady, audioInitialised, currentEpisodeId, customQueueCount, isPaused) => (
    (audioReady || audioInitialised)
      || !isPaused
      || (!currentEpisodeId && !!customQueueCount) // This is a special case where the user has not started playback but has queued a custom episode
  )
);

// To split out
export const selectKeakiePlaybackMode = (state) => selectAudio(state).keakiePlaybackMode;