import {
  DISCOVER_KEY,
  NOT_FOUND_KEY,
  CHANNEL_KEY,
  EPISODE_KEY,
  SEARCH_KEY,
  GENRE_KEY,
  LIBRARY_KEY,
  PROFILE_KEY,
  PRIVACY_POLICY_KEY,
  APPLE_REDIRECT_CLIENT_KEY,
  SIGN_IN_KEY,
  UPDATE_STORIES_KEY,
  APP_DOWNLOAD_KEY,
  APP_DOWNLOAD_PARTNER_PROGRAM_KEY
} from '../routeKeys';
import { defaultsDeep } from '@/helpers/lodash';

const { NODE_ENV } = process.env;

const ROUTES_CONFIG = {
  base: {
    [APPLE_REDIRECT_CLIENT_KEY]: { key: APPLE_REDIRECT_CLIENT_KEY },
    [NOT_FOUND_KEY]: { key: NOT_FOUND_KEY },
    [DISCOVER_KEY]: { key: DISCOVER_KEY },
    [CHANNEL_KEY]: { key: CHANNEL_KEY },
    [EPISODE_KEY]: { key: EPISODE_KEY },
    [SEARCH_KEY]: { key: SEARCH_KEY },
    [GENRE_KEY]: { key: GENRE_KEY },
    [LIBRARY_KEY]: { key: LIBRARY_KEY },
    [PROFILE_KEY]: { key: PROFILE_KEY },
    [PRIVACY_POLICY_KEY]: { key: PRIVACY_POLICY_KEY },
    [SIGN_IN_KEY]: { key: SIGN_IN_KEY },
    [UPDATE_STORIES_KEY]: { key: UPDATE_STORIES_KEY },
    [APP_DOWNLOAD_KEY]: { key: APP_DOWNLOAD_KEY },
    [APP_DOWNLOAD_PARTNER_PROGRAM_KEY]: { key: APP_DOWNLOAD_PARTNER_PROGRAM_KEY }
  },
  development: {},
  production: {}
};

// Merge the base config with that of the current NODE_ENV value
const ENV_ROUTES_CONFIG = defaultsDeep(
  ROUTES_CONFIG[NODE_ENV],
  ROUTES_CONFIG.base
);

export default ENV_ROUTES_CONFIG;