import { useContext } from 'react';
import AppFilterContext from '@/contexts/app-filter';

const useAppFilter = () => {
  const {
    appIsFiltered,
    toggleAppFilter,
    disableAppFilter,
    enableAppFilter
  } = useContext(AppFilterContext);

  return {
    appIsFiltered,
    toggleAppFilter,
    disableAppFilter,
    enableAppFilter
  };
};

export default useAppFilter;