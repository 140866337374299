import {
  useCallback,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash.foreach';
import find from 'lodash.find';
import { Provider } from './context';
import { isBrowser } from '@/helpers/browser';
import { initialiseABFeature, updateABFeature } from '@/helpers/utility';
import featureToSearchCommandMap, { featureToSearchCustomPropertiesFunctions } from '@/config/feature-toggles/search-commands';
import useGA from '@/hooks/ga/useGA';

const FeatureTogglesProvider = ({ children }) => {
  const [features, setFeatures] = useState({});

  const { isInitialised: GAIsInitialised, setGAProperties } = useGA();

  const setFeaturesState = useCallback((updates = {}) => {
    setFeatures((oldFeatures) => {
      const newFeatures = { ...oldFeatures };

      forEach(updates, (value, key) => {
        newFeatures[key] = {
          ...oldFeatures[key],
          ...value
        };
      });

      return newFeatures;
    });
  }, []);

  const initialiseFeature = useCallback(({ featureKey } = {}) => {
    if (isBrowser()) {
      const { enabled, customProperties } = initialiseABFeature({ featureKey });

      setFeaturesState({
        [featureKey]: {
          initialised: true,
          enabled,
          ...customProperties
        }
      });
    }
  }, [setFeaturesState]);

  const updateFeatureEnabledStatus = useCallback(({
    featureKey, shouldEnable = null, customProperties = {}
  } = {}) => {
    initialiseFeature({ featureKey });

    updateABFeature({
      featureKey,
      shouldEnable,
      customProperties
    });

    setFeaturesState({
      [featureKey]: {
        initialised: true,
        enabled: shouldEnable,
        ...customProperties
      }
    });
  }, [setFeaturesState, initialiseFeature]);

  const trackFeatureStatusInGA = useCallback(({ featureKey, isEnabled }) => {
    const featureSettings = features[featureKey] || {};
    const { gaPropertySet } = featureSettings;

    // Track whether the user has been shown the feature
    if (GAIsInitialised && !gaPropertySet) {
      setFeaturesState({ [featureKey]: { gaPropertySet: true } });
      setGAProperties({ [featureKey]: isEnabled });
    }
  }, [GAIsInitialised, features, setGAProperties, setFeaturesState]);

  const updateFeatureBySearchCommand = useCallback(({ searchValue }) => {
    let featureKey;
    let customProperties = {};
    const textFormatted = (searchValue || '').trim().toLowerCase();

    find(featureToSearchCommandMap, (value, key) => {
      if (textFormatted.includes(value)) {
        featureKey = key;
        const customPropertiesFunction = featureToSearchCustomPropertiesFunctions[key];

        if (customPropertiesFunction) {
          customProperties = customPropertiesFunction(searchValue);
        }
      }
    });

    if (featureKey) {
      updateFeatureEnabledStatus({
        featureKey,
        shouldEnable: !textFormatted.includes('disable'),
        customProperties
      });
    }
  }, [updateFeatureEnabledStatus]);

  const value = useMemo(() => ({
    features,
    initialiseFeature,
    updateFeatureEnabledStatus,
    trackFeatureStatusInGA,
    updateFeatureBySearchCommand
  }), [features, initialiseFeature, updateFeatureEnabledStatus, updateFeatureBySearchCommand, trackFeatureStatusInGA]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

FeatureTogglesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default FeatureTogglesProvider;