import * as clipboard from 'clipboard-polyfill';
import { isDesktopSafari } from '@/helpers/browser';
import { createHandleTooltip } from '@/helpers/tooltip';

// Copy a text value to the clipboard
export const copyToClipboard = (text) => clipboard.writeText(text);

// Copy the href in the url bar to the clipboard
export const copyCurrentUrlToClipboard = () => clipboard.writeText(window.location.href);

// Shares a link based on the parameters passed
// Attempts to use the share sheet api and if unavailable, copy to clipboard instead
export const share = async ({
  title = '',
  text = title,
  url = window.location.href,
  e = null, // Event - if this is passed it can be used to retrieve a clicked element and display the tooltip over it
  tooltipElement = null, // Alternatively pass the element that the tooltip should hover over
  useShareApi = true, // If set to true, use the share sheet api. If false only copy to clipboard
  tooltipText
} = {}) => {
  // Create a handler which will display a tooltip over the given element
  const handleTooltip = createHandleTooltip({
    e,
    tooltipElement,
    text: tooltipText
  });

  // Create a handler which will attempt to copy to clipboard
  const handleCopyToClipboard = () => copyToClipboard(url)
    .then(() => {
      // If successfully copied to clipboard, display the tooltip
      if (tooltipText) {
        handleTooltip();
      }
    })
    .catch(() => {

    });
  
  if (useShareApi && !isDesktopSafari()) {
    try {
      const shareData = {
        title,
        text,
        url
      };
      
      await navigator.share(shareData);
    } catch (err) {
      handleCopyToClipboard();
    }
  } else {
    handleCopyToClipboard();
  }
};