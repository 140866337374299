import React from 'react';
import PropTypes from 'prop-types';
import { classes } from '@/helpers/styling';

const LayoutWrapperHorizontal = React.forwardRef(({
  children,
  forceMobile,
  disable,
  isRow,
  fillHeight,
  className,
  disableSmallMobileMode,
  ...props
}, ref) => {
  const classNameToApply = disable
    ? classes('layout-wrapper-horizontal-disabled', className)
    : classes(
      forceMobile
        ? 'layout-wrapper-horizontal-mobile'
        : 'layout-wrapper-horizontal',
      {
        '--is-row': isRow,
        '--fill-height': fillHeight,
        '--disable-small-mobile-mode': disableSmallMobileMode
      },
      className
    );

  return (
    <div
      { ...props }
      className={ classNameToApply }
      ref={ ref }
    >
      { children ?? null }
    </div>
  );
});

LayoutWrapperHorizontal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  forceMobile: PropTypes.bool,
  disable: PropTypes.bool,
  isRow: PropTypes.bool,
  fillHeight: PropTypes.bool,
  className: PropTypes.string,
  ref: PropTypes.object,
  disableSmallMobileMode: PropTypes.bool
};

LayoutWrapperHorizontal.defaultProps = {
  forceMobile: false,
  disable: false,
  isRow: false,
  fillHeight: false,
  className: '',
  ref: null,
  disableSmallMobileMode: false
};

export default LayoutWrapperHorizontal;