import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, GET_USER } } = CONFIG;

// Get the user object associated with the current user's account
// This returns a user object including the statistics information
const getUser = () => {
  return callApi({
    url: HOST(GET_USER) + GET_USER,
    errorOn404: true
  });
};

export default getUser;