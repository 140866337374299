import without from 'lodash.without';
import BREAKPOINTS, {
  BP_WIDTH_KEY_MOBILE,
  BP_WIDTH_KEY_SMALL_MOBILE,
  BP_WIDTH_KEY_SMALL_MOBILE_OR_MOBILE
} from '@/config/breakpoints';
import { getWindowSizes, isBrowser } from '@/helpers/browser';

const mobileBreakpointKeys = [BP_WIDTH_KEY_MOBILE, BP_WIDTH_KEY_SMALL_MOBILE];
const sortedBreakpoints = Object.entries(BREAKPOINTS).sort(([, bpWidthA], [, bpWidthB]) => bpWidthA - bpWidthB);
const breakpointKeysArray = Object.keys(BREAKPOINTS);

export const getBrowserSize = (width) => {
  if (!width) {
    return sortedBreakpoints[0][0];
  }

  const widthIsLargestBreakpoint = sortedBreakpoints.filter(([, bpWidth]) => width <= bpWidth).length === 0;

  if (widthIsLargestBreakpoint) {
    return sortedBreakpoints[sortedBreakpoints.length - 1][0];
  }

  const sortedBreakpointsFiltered = sortedBreakpoints.filter(([, bpWidth]) => width <= bpWidth);

  return sortedBreakpointsFiltered[0][0];
};

export const getLayoutSizesExcluding = (browserSizesArray) => without(breakpointKeysArray, ...(browserSizesArray || []));

export const shouldRenderForLayoutSizes = ({ browserSizesArray, isFirstRender = false } = {}) => {
  const isServerMode = isFirstRender || !isBrowser();

  const { width } = getWindowSizes();

  return isServerMode || (browserSizesArray || []).includes(getBrowserSize(width));
};

export const shouldNotRenderForLayoutSizes = ({ browserSizesArray, isFirstRender = false } = {}) => {
  return shouldRenderForLayoutSizes({
    browserSizesArray: getLayoutSizesExcluding(browserSizesArray),
    isFirstRender
  });
};

export const shouldRenderForMobile = ({ isFirstRender = false } = {}) => {
  return shouldRenderForLayoutSizes({
    browserSizesArray: mobileBreakpointKeys,
    isFirstRender
  });
};

export const shouldRenderForDesktop = ({ isFirstRender = false } = {}) => shouldNotRenderForLayoutSizes({
  browserSizesArray: mobileBreakpointKeys,
  isFirstRender
});

export const getScreenSizeFlags = () => {
  const allSizes = {};

  Object.entries(BREAKPOINTS).forEach(([key]) => {
    allSizes[key] = shouldRenderForLayoutSizes({ browserSizesArray: [key] });
  });

  allSizes[BP_WIDTH_KEY_SMALL_MOBILE_OR_MOBILE] = allSizes[BP_WIDTH_KEY_SMALL_MOBILE] || allSizes[BP_WIDTH_KEY_MOBILE];

  return allSizes;
};