import PropTypes from 'prop-types';
import React, { memo } from 'react';
import NextImage from '../NextImage';
import BasicImage from '../BasicImage';
import { extractHighAndLowResImages } from '../helpers';
import styles from './styles.module.scss';
import Gradient, { GRADIENT_POSITION_BOTTOM, GRADIENT_POSITION_TOP } from '@/components/app/Gradient';
import {
  LARGE,
  MEDIUM,
  SQUARE,
  PROFILE,
  SMALL,
  TILESMALL,
  XLARGE,
  XSMALL
} from '@/config/assets';
import { classes } from '@/helpers/styling';

const DEFAULT_OBJECT = {};
const GRADIENT_STYLE = { height: '66%' };

const SquareImage = React.forwardRef(({
  assetsObj,
  assetSize,
  className,
  imageClassName,
  loading,
  onClick,
  fallbackImageSrc,
  // Gradients
  enableGradient,
  gradientBottom,
  gradientTop,
  // Options
  hasBorderRadius,
  isProfile,
  forceHighDensity,
  disableNextImage,
  src,
  ...props
}, ref) => {
  const { srcLowRes, srcHighRes } = src ? { srcHighRes: src } : extractHighAndLowResImages({
    assetSizeKey: assetSize,
    assetsObj,
    imageType: isProfile ? PROFILE : SQUARE,
    forceAssetSizeKey: assetSize,
    forceHighDensity
  });

  const enableTopGradient = enableGradient && gradientTop;
  const enableBottomGradient = enableGradient && gradientBottom;

  const combinedClassName = classes(
    styles.squareImageComponent,
    className,
    { [styles.hasBorderRadius]: hasBorderRadius }
  );

  return (
    <>
      {
        enableTopGradient ? (
          <Gradient
            className={ classes(styles.topGradient, { [styles.hasBorderRadius]: hasBorderRadius }) }
            extraBreadth
            gradientBreadth='35%'
            position={ GRADIENT_POSITION_TOP }
            style={ GRADIENT_STYLE }
          />
        ) : null
      }
      { disableNextImage
        ? (
          <BasicImage
            className={ combinedClassName }
            imageClassName={ imageClassName }
            loading={ loading }
            onClick={ onClick }
            ref={ ref }
            src={ srcHighRes || srcLowRes || fallbackImageSrc }
            { ...props }
          />
        )
        : (
          <NextImage
            className={ combinedClassName }
            imageClassName={ imageClassName }
            loading={ loading }
            onClick={ onClick }
            ref={ ref }
            srcHighRes={ srcHighRes || fallbackImageSrc }
            srcLowRes={ srcLowRes || fallbackImageSrc }
            { ...props }
          />
        ) }
      {
        enableBottomGradient ? (
          <Gradient
            className={ styles.bottomGradient }
            directionReverse
            extraBreadth
            gradientBreadth='66%'
            position={ GRADIENT_POSITION_BOTTOM }
            style={ GRADIENT_STYLE }
          />
        ) : null
      }
    </>
  );
});

SquareImage.propTypes = {
  assetsObj: PropTypes.object,
  assetSize: PropTypes.oneOf([XLARGE, LARGE, MEDIUM, SMALL, XSMALL, TILESMALL]),
  loading: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  hasBorderRadius: PropTypes.bool,
  isProfile: PropTypes.bool,
  enableGradient: PropTypes.bool,
  gradientBottom: PropTypes.bool,
  gradientTop: PropTypes.bool,
  onClick: PropTypes.func,
  forceHighDensity: PropTypes.bool,
  disableNextImage: PropTypes.bool,
  fallbackImageSrc: PropTypes.string,
  src: PropTypes.string
};

SquareImage.defaultProps = {
  assetSize: undefined,
  loading: false,
  assetsObj: DEFAULT_OBJECT,
  className: '',
  imageClassName: '',
  enableGradient: false,
  hasBorderRadius: true,
  gradientBottom: false,
  gradientTop: false,
  isProfile: false,
  onClick: undefined,
  forceHighDensity: false,
  disableNextImage: false,
  fallbackImageSrc: '',
  src: ''
};

export default memo(SquareImage);