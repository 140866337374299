import {
  useCallback, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import EpisodeMenuOverlay from '@/components/app/EpisodeMenuOverlay';
import useAppFilter from '@/hooks/layout/useAppFilter';
import useBrowserPopState from '@/hooks/browser/useBrowserPopState';
import useShouldRenderForMobile from '@/hooks/layout/useShouldRenderForMobile';

const DEFAULT_OBJECT = {};

const RightClickMenuProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [selectedEpisodeQueueKey, setSelectedEpisodeQueueKey] = useState(null);
  const [elementId, setElementId] = useState('');
  const [overlayProps, setOverlayProps] = useState({});

  const { enableAppFilter, disableAppFilter } = useAppFilter();
  const shouldRenderForMobile = useShouldRenderForMobile();

  const closeMenu = useCallback(() => {
    if (overlayProps.onClose) {
      overlayProps.onClose();
    }
    setSelectedEpisode(null);
    setSelectedEpisodeQueueKey(null);
    setElementId('');
    setOverlayProps({});
    setMenuOpen(false);
    disableAppFilter();
  }, [disableAppFilter, overlayProps]);

  const onClickNavigationOption = useCallback(() => {
    if (overlayProps.onClickNavigationOption) {
      overlayProps.onClickNavigationOption();
    }
  }, [overlayProps]);

  const openMenu = useCallback(({
    episode,
    episodeKey,
    elemId,
    props
  }) => {
    setSelectedEpisode(episode);
    setSelectedEpisodeQueueKey(episodeKey);
    setElementId(elemId);
    setOverlayProps(props);
    setMenuOpen(true);
    if (!shouldRenderForMobile) {
      enableAppFilter();
    }
  }, [enableAppFilter, shouldRenderForMobile]);

  const toggleMenu = useCallback(({
    e,
    episode,
    episodeKey,
    elemId,
    props,
    onClose = _ => _,
    onOpen = _ => _
  }) => {
    e.stopPropagation();
    e.preventDefault();

    if (menuOpen) {
      closeMenu();
      onClose();
    } else {
      openMenu({
        episode,
        episodeKey,
        elemId,
        props
      });
      onOpen();
    }
  }, [closeMenu, openMenu, menuOpen]);

  const selectedTrackElement = useMemo(() => (elementId ? document.getElementById(elementId) : null), [elementId]);
  const boundingClientRect = useMemo(() => (selectedTrackElement ? selectedTrackElement.getBoundingClientRect() : DEFAULT_OBJECT), [selectedTrackElement]);

  const handlePopState = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  useBrowserPopState(handlePopState);

  const value = useMemo(() => ({
    openRightClickMenu: openMenu,
    toggleRightClickMenu: toggleMenu,
    closeRightClickMenu: closeMenu,
    selectedEpisode,
    selectedEpisodeQueueKey,
    elementId,
    boundingClientRect
  }), [
    openMenu,
    toggleMenu,
    closeMenu,
    selectedEpisode,
    selectedEpisodeQueueKey,
    elementId,
    boundingClientRect
  ]);

  return (
    <Provider value={ value }>
      { children }
      <EpisodeMenuOverlay
        { ...overlayProps }
        boundingClientRect={ boundingClientRect }
        episode={ selectedEpisode }
        episodeQueueKey={ selectedEpisodeQueueKey }
        onClickNavigationOption={ onClickNavigationOption }
        onClose={ closeMenu }
        open={ menuOpen }
      />
    </Provider>
  );
};

RightClickMenuProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default RightClickMenuProvider;