import React, {
  memo,
  useState,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import NextImg from 'next/image';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const imageLoader = ({
  src, width, quality
}) => {
  return `${ src }?w=${ width }&q=${ quality || 100 }`;
};

const NextImage = React.forwardRef(({
  srcLowRes,
  srcHighRes,
  loading,
  className,
  imageClassName,
  priority,
  onClick,
  disableBorderRadius,
  alt,
  quality,
  aspectRatio,
  height,
  width,
  layout,
  ...props
}, ref) => {
  const [lowResLoaded, setLowResLoaded] = useState(false);
  const [showBackground, setShowBackground] = useState(true);
  const [highResLoaded, setHighResLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLowResLoaded(true);
  }, []);

  const handleLoadingComplete = useCallback(() => {
    if (!highResLoaded) {
      setHighResLoaded(true);
      setShowBackground(false);
    }
  }, [highResLoaded]);

  const src = srcHighRes || srcLowRes;
  const usePlaceholder = !!(srcHighRes && srcLowRes) && (srcHighRes !== srcLowRes);
  const imageHeight = height || aspectRatio;

  return (
    <div
      { ...props }
      className={ classes(
        className,
        styles.imageContainer,
        {
          [styles.showBackground]: loading || showBackground,
          [styles.highResLoaded]: !loading && highResLoaded,
          [styles.isClickable]: !loading && !!onClick,
          [styles.disableBorderRadius]: disableBorderRadius
        }
      ) }
      onClick={ onClick }
      ref={ ref }
    >
      { !!src && (
        <NextImg
          alt={ alt || '' }
          blurDataURL={ usePlaceholder ? srcLowRes : undefined }
          className={ classes(
            imageClassName,
            styles.image,
            {
              [styles.lowResLoaded]: !loading && lowResLoaded,
              [styles.highResLoaded]: !loading && srcHighRes && highResLoaded
            }
          ) }
          height={ imageHeight }
          layout={ layout || 'fill' }
          loader={ imageLoader }
          onLoad={ handleLoad }
          onLoadingComplete={ handleLoadingComplete }
          placeholder={ usePlaceholder ? 'blur' : undefined }
          priority={ priority }
          src={ src }
          width={ width }
        />
      ) }
    </div>
  );
});

NextImage.propTypes = {
  srcLowRes: PropTypes.string,
  srcHighRes: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  onClick: PropTypes.func,
  disableBorderRadius: PropTypes.bool,
  priority: PropTypes.bool,
  alt: PropTypes.string,
  quality: PropTypes.number,
  aspectRatio: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  layout: PropTypes.oneOf([
    'intrinsic',
    'responsive',
    'fill',
    'fixed',
    'raw'
  ])
};

NextImage.defaultProps = {
  srcLowRes: '',
  srcHighRes: '',
  loading: false,
  className: '',
  imageClassName: '',
  onClick: undefined,
  disableBorderRadius: false,
  priority: false,
  alt: '',
  quality: 100,
  aspectRatio: undefined,
  height: undefined,
  width: undefined,
  layout: 'fill'
};

export default memo(NextImage);