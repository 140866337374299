import { defaultsDeep } from '@/helpers/lodash';

const { NODE_ENV } = process.env;

const LOCAL_STORAGE_CONFIG = {
  base: {
    cacheVersion: '5.1.0', // Major bump will clear user's redux cache entirely, minor bump will clear everything except for audio
    enableCacheKeys: [
      'audio',
      'genres.parents',
      'genres.parentsStatus',
      'genres.all',
      'genres.allStatus',
      'genres.allGenresFlattened'
    ],
    localStorageTimeouts: {
      parentGenres: {
        expiryKey: 'parentGenres',
        ttl: 24 * 60 * 60 // 24 hours
      },
      allGenres: {
        expiryKey: 'allGenres',
        ttl: 24 * 60 * 60 // 24 hours
      },
      audio: {
        expiryKey: 'audio',
        ttl: 2 * 60 * 60 // 2 hours
      }
    }
  },
  development: {},
  production: {}
};

// Merge the base config with that of the current NODE_ENV value
const ENV_API_CONFIG = defaultsDeep(
  LOCAL_STORAGE_CONFIG[NODE_ENV],
  LOCAL_STORAGE_CONFIG.base
);

export default ENV_API_CONFIG;