import initialState from './initialState';
import {
  updateLibrary
  // resetTracksState
} from './utility';
import {
  ADD_EPISODE_TO_LIBRARY_FAILURE,
  ADD_EPISODE_TO_LIBRARY_SUCCESS,
  CLEAR_FILTERS,
  GET_LIBRARY_FAILURE,
  GET_LIBRARY_LOADING,
  GET_LIBRARY_SUCCESS,
  REMOVE_EPISODE_FROM_LIBRARY_FAILURE,
  REMOVE_EPISODE_FROM_LIBRARY_SUCCESS,
  RESET_STATE,
  SET_FILTER,
  SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY,
  SET_TEXT_FILTER
} from './types';

const reducer = (state = { ...initialState }, action) => {
  if (!state || !action) { return state; }
  
  const { type, payload } = action;

  switch (type) {
    case RESET_STATE: {
      return initialState;
    }
    case GET_LIBRARY_LOADING: {
      const { key } = payload;

      // Mark the library call as loading for the relevant search key
      return updateLibrary({
        state,
        searchKey: key,
        status: 'loading'
      });
    }
    case GET_LIBRARY_SUCCESS: {
      const {
        key, tracks, notFound, timestamp
      } = payload;

      // 1. Mark the library call as successful for the relevant search key
      // 2. Save the tracks in state for the relevant search key
      return updateLibrary({
        state,
        searchKey: key,
        status: 'success',
        tracks,
        notFound,
        timestamp
      });
    }
    case GET_LIBRARY_FAILURE: {
      const { key } = payload;
      
      // Mark the library call as failed for the relevant search key
      return updateLibrary({
        state,
        searchKey: key,
        status: 'failure'
      });
    }
    case SET_FILTER: {
      const { key, filter } = payload;

      // Update the filter key in the searchFilters object
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          [key]: filter
        }
      };
    }
    case SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY: {
      const { searchKey } = payload;

      return {
        ...state,
        audioQueueSearchKey: searchKey
      };
    }
    case CLEAR_FILTERS: {
      // Replace the searchFilters with its initial object
      return {
        ...state,
        searchFilters: { ...initialState.searchFilters }
      };
    }
    case SET_TEXT_FILTER: {
      const { textSearchFilter } = payload;

      return {
        ...state,
        textSearchFilter
      };
    }
    // TO DO: WE ARE CURRENTLY RESETTING LIBRARY STATE WHEN A LIBRARY UPDATE IS MADE
    // WE COULD BE MORE CLEVER ABOUT THIS AND UPDATE ALL OF OUR CACHED SEARCHES
    case ADD_EPISODE_TO_LIBRARY_SUCCESS: {
      const { episode } = payload;

      return updateLibrary({
        state,
        newEpisode: episode
      });
    }
    case ADD_EPISODE_TO_LIBRARY_FAILURE: {
      return state;
    }
    case REMOVE_EPISODE_FROM_LIBRARY_SUCCESS: {
      const { id } = payload;

      return updateLibrary({
        state,
        episodeIdToRemove: id
      });
    }
    case REMOVE_EPISODE_FROM_LIBRARY_FAILURE: {
      return state;
    }
    default:
      return state;
  }
};

export default reducer;