import {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import { audioSupported } from '@/helpers/audio';

const AudioElementProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [audioRefReady, setAudioRefReady] = useState(false);

  const audioIsSupported = audioSupported();

  // Setup the audio ref
  useEffect(() => {
    if (audioIsSupported) {
      audioRef.current = new Audio();
      setAudioRefReady(true);
    }

    return () => {
      audioRef.current = null;
    };
  }, [audioIsSupported]);

  const value = useMemo(() => ({
    audioRef,
    audioRefReady
  }), [audioRef, audioRefReady]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

AudioElementProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default AudioElementProvider;