import debounce from 'lodash.debounce';
import {
  ADD_EPISODE_TO_LIBRARY_FAILURE,
  ADD_EPISODE_TO_LIBRARY_LOADING,
  ADD_EPISODE_TO_LIBRARY_SUCCESS,
  CLEAR_FILTERS,
  GET_LIBRARY_FAILURE,
  GET_LIBRARY_LOADING,
  GET_LIBRARY_SUCCESS,
  REMOVE_EPISODE_FROM_LIBRARY_FAILURE,
  REMOVE_EPISODE_FROM_LIBRARY_LOADING,
  REMOVE_EPISODE_FROM_LIBRARY_SUCCESS,
  RESET_STATE,
  SET_FILTER,
  SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY,
  SET_TEXT_FILTER
} from './types';
import {
  selectLibraryFormatFilterValue,
  selectLibraryGenreFilterId,
  selectLibraryTextFilter
} from '@/state/selectors/library';
import { filterLibraryTracks } from '@/helpers/pages/library';
import { formatEpisode } from '@/helpers/common';
import addToLibraryCall from '@/helpers/api/library/addToLibrary';
import getLibraryCall from '@/helpers/api/library/getLibrary';
import removeFromLibraryCall from '@/helpers/api/library/removeFromLibrary';
import { addNotification } from '@/state/notifications/actions';
import { changeTracklist } from '@/state/audio/actions';

export const resetLibraryState = () => (dispatch) => {
  dispatch({ type: RESET_STATE });
};

export const getLibrary = debounce(({ key, params = {} }) => (dispatch) => {
  dispatch({
    type: GET_LIBRARY_LOADING,
    payload: { key }
  });

  return getLibraryCall(params)
    .then(({ json: library, notFound }) => {
      if (notFound) {
        return dispatch({
          type: GET_LIBRARY_SUCCESS,
          payload: {
            key,
            notFound
          }
        });
      }

      return dispatch({
        type: GET_LIBRARY_SUCCESS,
        payload: {
          tracks: library.nodes.map((episodePreview) => formatEpisode(episodePreview)),
          key,
          notFound,
          timestamp: Date.now()
        }
      });
    })
    .catch(() => {
      dispatch({
        type: GET_LIBRARY_FAILURE,
        payload: { key }
      });
    });
},
500,
{
  leading: true,
  trailing: false
});

export const setFilter = ({ key, filter }) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    payload: {
      key,
      filter
    }
  });
};

export const clearFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTERS });
};

export const setTextFilter = (textSearchFilter) => (dispatch) => {
  dispatch({
    type: SET_TEXT_FILTER,
    payload: { textSearchFilter }
  });
};

export const setLibraryAudioQueueSearchKey = (searchKey) => (dispatch) => {
  dispatch({
    type: SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY,
    payload: { searchKey }
  });
};

export const changeLibraryTracklist = ({ libraryTracks }) => (dispatch, getState) => {
  const state = getState();

  const searchValue = selectLibraryTextFilter(state);
  const formatValue = selectLibraryFormatFilterValue(state);
  const genreId = selectLibraryGenreFilterId(state);

  const filteredTracks = filterLibraryTracks({
    tracks: libraryTracks,
    searchValue,
    formatValue,
    genreId
  });

  dispatch(changeTracklist({
    tracklist: filteredTracks.map(({ id }, index) => ({
      index,
      key: id,
      id
    })),
    episodes: filteredTracks
  }));
};

export const addToLibrary = ({
  id, title, assets, episode
}) => (dispatch) => {
  dispatch({
    type: ADD_EPISODE_TO_LIBRARY_LOADING,
    payload: { id }
  });

  addToLibraryCall(id)
    .then(() => {
      dispatch({
        type: ADD_EPISODE_TO_LIBRARY_SUCCESS,
        payload: {
          id,
          episode
        }
      });

      dispatch(addNotification({
        id: `add-library ${ id }`,
        title,
        assets,
        type: 'add-library',
        success: true
      }));
    })
    .catch(() => {
      dispatch({
        type: ADD_EPISODE_TO_LIBRARY_FAILURE,
        payload: { id }
      });

      dispatch(addNotification({
        id: `add-library ${ id }`,
        title,
        assets,
        type: 'add-library',
        success: false
      }));
    });
};

export const removeFromLibrary = ({
  id, title, assets, searchKey = null
}) => (dispatch) => {
  dispatch({
    type: REMOVE_EPISODE_FROM_LIBRARY_LOADING,
    payload: { id }
  });

  removeFromLibraryCall(id)
    .then(() => {
      dispatch({
        type: REMOVE_EPISODE_FROM_LIBRARY_SUCCESS,
        payload: {
          id,
          searchKey
        }
      });

      dispatch(addNotification({
        id: `remove-library ${ id }`,
        title,
        assets,
        type: 'remove-library',
        success: true
      }));
    })
    .catch(() => {
      dispatch({
        type: REMOVE_EPISODE_FROM_LIBRARY_FAILURE,
        payload: { id }
      });

      dispatch(addNotification({
        id: `remove-library ${ id }`,
        title,
        assets,
        type: 'remove-library',
        success: false
      }));
    });
};