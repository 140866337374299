export const addNotification = ({
  state,
  id,
  title,
  assets,
  type,
  success
}) => {
  const currentNotifications = state.notifications;
  // Check whether the same id is already in the notifications queue
  const currentNotificationIndex = currentNotifications.findIndex((n) => n.id === id);
  const currentNotification = currentNotifications[currentNotificationIndex] || null;
  // If the id is not in the queue, it should be added
  const shouldAddNotification = !currentNotification;

  // Success notifications should take priority over failure notifications if the key is the same
  const shouldReplaceNotification = (
    (!currentNotification || (currentNotification && !currentNotification.success))
    && success
  );

  // Destructure the existing notifications to avoid state mutation
  let newNotifications = [...currentNotifications];

  const newNotification = {
    id,
    title,
    assets,
    success,
    type
  };

  // If adding, simply push the notification into the array
  if (shouldAddNotification) {
    newNotifications = [
      ...currentNotifications,
      newNotification
    ];
  } else if (shouldReplaceNotification) {
    newNotifications[currentNotificationIndex] = newNotification;
  }

  return {
    ...state,
    notifications: newNotifications
  };
};