import appEnv from '@beam-australia/react-env';
import { DEFAULT_PORT } from '../config/global/urls';

// Converts 'true' and 'false' strings to the correct boolean value
export const convertEnvStringToBool = (env) => env === 'true' || env === true;

export const convertUseMockEnvToBoolOrAllString = (env) => {
  if (env === 'true') { return true; }

  return ((!env || (env === 'false')) ? false : env);
};

const getEnvVar = (key) => appEnv(key)
  || process.env[key]
  || process.env[`NEXT_APP_${ key }`]; // This makes sure that client variables are also available on the server

const { NODE_ENV } = process.env;
const KEAKIE_ENV = getEnvVar('KEAKIE_ENV');
const isKeakieStaging = (KEAKIE_ENV === 'staging');
const isKeakieProduction = (KEAKIE_ENV === 'production');

const envPublic = {
  NODE_ENV,
  KEAKIE_ENV,
  FB_PIXEL_ID: getEnvVar('FB_PIXEL_ID'),
  IP: getEnvVar('IP') || 'localhost',
  PORT: getEnvVar('PORT') || DEFAULT_PORT,
  API_URL: getEnvVar('API_URL'),
  HTTPS: isKeakieProduction || convertEnvStringToBool(getEnvVar('HTTPS')),
  USE_MOCK_API: !isKeakieProduction && convertUseMockEnvToBoolOrAllString(getEnvVar('USE_MOCK_API')),
  USE_WHY_DID_YOU_RENDER: !isKeakieProduction && convertUseMockEnvToBoolOrAllString(getEnvVar('USE_WHY_DID_YOU_RENDER')),
  DISABLE_AUTHENTICATION: !isKeakieProduction && convertEnvStringToBool(getEnvVar('DISABLE_AUTHENTICATION')),
  ENABLE_INTERCOM: isKeakieProduction || isKeakieStaging || convertEnvStringToBool(getEnvVar('ENABLE_INTERCOM')),
  ENABLE_GA: isKeakieProduction || isKeakieStaging || convertEnvStringToBool(getEnvVar('ENABLE_GA')),
  ENABLE_GA_DEBUG: !isKeakieProduction && convertEnvStringToBool(getEnvVar('ENABLE_GA_DEBUG')),
  GA_TRACKING_IDS: (getEnvVar('GA_TRACKING_IDS') || '').split(',').filter(_ => _),
  KEAKIE_DEV_MODE: !isKeakieStaging && !isKeakieProduction,
  KEAKIE_STAGING_MODE: isKeakieStaging,
  KEAKIE_PRODUCTION_MODE: isKeakieProduction,
  CACHE_SERVER_SIDE_PROPS_RESPONSES: convertEnvStringToBool(getEnvVar('CACHE_SERVER_SIDE_PROPS_RESPONSES')),
  CACHE_REACT_QUERY_RESPONSES: convertEnvStringToBool(getEnvVar('CACHE_REACT_QUERY_RESPONSES')),
  CACHE_FIREBASE_SHORT_LINK_RESPONSES: convertEnvStringToBool(getEnvVar('CACHE_FIREBASE_SHORT_LINK_RESPONSES')),
  CACHE_ITEM_LIMIT: getEnvVar('CACHE_ITEM_LIMIT'),
  CACHE_TTL_MINUTES: getEnvVar('CACHE_TTL_MINUTES'),
  ENABLE_TRACK_SEEK: convertEnvStringToBool(getEnvVar('ENABLE_TRACK_SEEK')),
  FB_APP_ID: getEnvVar('FB_APP_ID'),
  FB_PAGE_ID: getEnvVar('FB_PAGE_ID'),
  APPLE_CLIENT_ID: getEnvVar('APPLE_CLIENT_ID'),
  ADMIN_STORIES_PASSWORD: getEnvVar('ADMIN_STORIES_PASSWORD'),
  KEAKIE_STORIES_TOKEN: getEnvVar('KEAKIE_STORIES_TOKEN')
};

const envSecret = {
  SSR_TOKEN: process.env.SSR_TOKEN,
  INTERCOM_ACCESS_TOKEN: process.env.INTERCOM_ACCESS_TOKEN,
  FIREBASE_WEB_API_KEY: process.env.FIREBASE_WEB_API_KEY,
  APPLE_CAMPAIGNS_PROVIDER_TOKEN: process.env.APPLE_CAMPAIGNS_PROVIDER_TOKEN
};

const env = {
  ...envPublic,
  ...envSecret
};

Object.keys(env).forEach(key => {
  if (env[key] === undefined) {
    delete env[key];
  }
});

export const getProcessEnvPublic = () => envPublic;

// Get the environment variables
export const getProcessEnv = () => env;