import { Component } from 'react';
import PropTypes from 'prop-types';
import { logError } from '@/helpers/logging';
import ErrorFullPage from '@/components/app/Error/ErrorFullPage';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    err: null
  };

  componentDidUpdate(prevProps, prevState) {
    const { hasError, err } = this.state;
    const { errorKey, errorDescription } = this.props;

    if (!prevState.hasError && hasError && err) {
      logError({
        key: errorKey,
        info: errorDescription,
        err
      });
    }
  }

  // When an error is caught, log it and update the error status in state
  componentDidCatch(err) {
    const { hasError } = this.state;

    if (!hasError) {
      this.setState({
        hasError: true,
        err
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? (
      <ErrorFullPage
        primaryButtonConfig={ {
          onClick: () => setTimeout(() => this.setState({
            hasError: false,
            err: null
          }), 250)
        } }
      />
    ) : children({ hasError });
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.func.isRequired,
  errorKey: PropTypes.any.isRequired,
  errorDescription: PropTypes.any.isRequired
};

export default ErrorBoundary;