import {
  ADD_EPISODE_TO_CUSTOM_QUEUE,
  CHANGE_TRACKLIST,
  CLEAR_CUSTOM_QUEUE,
  EPISODE_MANIFEST_NOT_FOUND,
  GET_FULL_EPISODE_FAILURE,
  GET_FULL_EPISODE_LOADING,
  GET_FULL_EPISODE_SUCCESS,
  MOVE_CUSTOM_QUEUE_EPISODE_POSITION,
  NEXT_TRACK,
  PAUSE_AUDIO,
  PLAY_AUDIO,
  PLAY_EPISODE,
  PLAY_EPISODE_FROM_CUSTOM_QUEUE,
  PLAY_EPISODE_FROM_TRACKLIST,
  PREVIOUS_TRACK,
  READY_STATE_CHANGED,
  REMOVE_EPISODE_FROM_CUSTOM_QUEUE,
  SEEK_AUDIO,
  SET_OVERRIDE_SEEK_KEY,
  SET_QUEUE_MODE,
  SET_STREAMING_CONTEXT,
  SET_VOLUME,
  TIME_UPDATE,
  TOGGLE_CAN_PLAY,
  TOGGLE_ERROR,
  TOGGLE_META_LOADED,
  TOGGLE_MUTED,
  TOGGLE_STALLED
} from './types';
import getEpisodeCall from '@/helpers/api/episodes/getEpisode';
import { truthyOrZero } from '@/helpers/math';
import { addNotification } from '@/state/notifications/actions';
import {
  selectAudioCanPlayFired,
  selectAudioMetaLoaded,
  selectAudioPaused,
  selectAudioReadyState,
  selectAudioSeek,
  selectCustomQueueEpisodeIds,
  selectKeakiePlaybackMode
} from '@/state/selectors/audio';
import { formatEpisode } from '@/helpers/common';

export const getEpisode = ({
  episodeNumber,
  channelSlug,
  season,
  episodeId: id
}) => (dispatch) => {
  dispatch({
    type: GET_FULL_EPISODE_LOADING,
    payload: { id }
  });

  return getEpisodeCall({
    episodeNumber,
    channelSlug,
    season
  })
    .then(({ json: episode, notFound }) => {
      dispatch({
        type: GET_FULL_EPISODE_SUCCESS,
        payload: {
          id,
          episode: formatEpisode({
            ...episode,
            id
          }),
          notFound
        }
      });
    })
    .catch(() => {
      dispatch({
        type: GET_FULL_EPISODE_FAILURE,
        payload: { id }
      });
    });
};

export const playAudio = () => (dispatch, getState) => {
  if (selectAudioPaused(getState())) {
    dispatch({ type: PLAY_AUDIO });
  }
};

export const pauseAudio = () => (dispatch, getState) => {
  if (!selectAudioPaused(getState())) {
    dispatch({ type: PAUSE_AUDIO });
  }
};

export const nextTrack = () => (dispatch) => {
  dispatch({ type: NEXT_TRACK });
};

export const previousTrack = () => dispatch => {
  dispatch({ type: PREVIOUS_TRACK });
};

export const playEpisode = ({
  episode,
  seek,
  streamingContext
}) => (dispatch) => {
  return dispatch({
    type: PLAY_EPISODE,
    payload: {
      episode: formatEpisode(episode),
      key: episode.id,
      seek,
      streamingContext
    }
  });
};

export const playEpisodeFromTracklist = ({ id, streamingContext }) => (dispatch) => {
  return dispatch({
    type: PLAY_EPISODE_FROM_TRACKLIST,
    payload: {
      id,
      key: id,
      streamingContext
    }
  });
};

export const playEpisodeFromCustomQueue = ({ key, id }) => (dispatch) => {
  return dispatch({
    type: PLAY_EPISODE_FROM_CUSTOM_QUEUE,
    payload: {
      key,
      id
    }
  });
};

export const changeTracklist = ({ tracklist, episodes }) => (dispatch) => {
  dispatch({
    type: CHANGE_TRACKLIST,
    payload: {
      tracklist,
      episodes: episodes.map((ep) => formatEpisode(ep))
    }
  });
};

export const addEpisodeToCustomQueue = ({
  episode,
  id,
  title
}) => (dispatch) => {
  dispatch({
    type: ADD_EPISODE_TO_CUSTOM_QUEUE,
    payload: {
      episode: formatEpisode(episode),
      key: `${ episode.id }-${ Date.now() }`
    }
  });

  const showForEpisode = episode.show || episode.channel || {};

  dispatch(addNotification({
    id: `add-queue ${ id }`,
    title,
    assets: showForEpisode.assets,
    type: 'add-queue',
    success: true
  }));
};

export const removeEpisodeFromCustomQueue = ({
  key,
  id,
  title,
  assets,
  withNotification = true
}) => (dispatch) => {
  dispatch({
    type: REMOVE_EPISODE_FROM_CUSTOM_QUEUE,
    payload: { key }
  });
  if (withNotification) {
    dispatch(addNotification({
      id: `remove-queue ${ id }`,
      title,
      assets,
      type: 'remove-queue',
      success: true
    }));
  }
};

export const moveCustomQueueEpisodePosition = ({ from, to }) => (dispatch) => {
  dispatch({
    type: MOVE_CUSTOM_QUEUE_EPISODE_POSITION,
    payload: {
      from,
      to
    }
  });
};

export const shiftEpisodeInQueue = ({
  from,
  to,
  targetEpisodeKey,
  movedEpisodeKey
}) => (dispatch, getState) => {
  if (
    truthyOrZero(from)
    && truthyOrZero(to)
    && (from !== -1)
    && (to !== -1)
  ) {
    dispatch(moveCustomQueueEpisodePosition({
      from,
      to
    }));
  } else if (movedEpisodeKey && targetEpisodeKey) {
    const customQueueEpisodeIds = selectCustomQueueEpisodeIds(getState());

    const fromIndex = customQueueEpisodeIds.findIndex(({ key }) => (key === movedEpisodeKey));
    const toIndex = customQueueEpisodeIds.findIndex(({ key }) => (key === targetEpisodeKey));
  
    dispatch(moveCustomQueueEpisodePosition({
      from: fromIndex,
      to: toIndex
    }));
  }
};

export const clearCustomQueue = () => (dispatch) => {
  dispatch({ type: CLEAR_CUSTOM_QUEUE });
};

export const seekAudio = ({ seek }) => (dispatch, getState) => {
  if (seek !== selectAudioSeek(getState())) {
    dispatch({
      type: SEEK_AUDIO,
      payload: { seek }
    });
  }
};

export const audioTimeUpdate = ({ seek }) => (dispatch, getState) => {
  if (seek.toFixed(2) !== selectAudioSeek(getState()).toFixed(2)) {
    dispatch({
      type: TIME_UPDATE,
      payload: { seek }
    });
  }
};

export const setVolume = (volume) => dispatch => {
  return dispatch({
    type: SET_VOLUME,
    payload: { volume }
  });
};

export const toggleMuted = (isMuted) => (dispatch) => {
  dispatch({
    type: TOGGLE_MUTED,
    payload: { muted: isMuted }
  });
};

export const setStreamingContext = (streamingContext) => (dispatch) => {
  dispatch({
    type: SET_STREAMING_CONTEXT,
    payload: { streamingContext }
  });
};

export const toggleMetaLoaded = (metaLoaded) => (dispatch, getState) => {
  if (metaLoaded !== selectAudioMetaLoaded(getState())) {
    dispatch({
      type: TOGGLE_META_LOADED,
      payload: { metaLoaded }
    });
  }
};

export const toggleError = (error) => (dispatch) => {
  dispatch({
    type: TOGGLE_ERROR,
    payload: { error }
  });
};

export const toggleStalled = (stalled) => (dispatch) => {
  dispatch({
    type: TOGGLE_STALLED,
    payload: { stalled }
  });
};

export const toggleCanPlayFired = (hasFired) => (dispatch, getState) => {
  if (hasFired !== selectAudioCanPlayFired(getState())) {
    dispatch({
      type: TOGGLE_CAN_PLAY,
      payload: { hasFired }
    });
  }
};

export const readyStateChanged = (readyState) => (dispatch, getState) => {
  if (readyState !== selectAudioReadyState(getState())) {
    dispatch({
      type: READY_STATE_CHANGED,
      payload: { readyState }
    });
  }
};

export const setOverrideSeekKey = (seekOverrideKey) => (dispatch) => {
  dispatch({
    type: SET_OVERRIDE_SEEK_KEY,
    payload: { seekOverrideKey }
  });
};

export const setQueueMode = ({ mode = 'show' }) => (dispatch, getState) => {
  if (mode !== selectKeakiePlaybackMode(getState())) {
    dispatch({
      type: SET_QUEUE_MODE,
      payload: { mode }
    });
  }
};

export const episodeManifestNotFound = (episodeId) => (dispatch) => {
  dispatch({
    type: EPISODE_MANIFEST_NOT_FOUND,
    payload: { episodeId }
  });
};