import initialState from './initialState';
import {
  ADD_EPISODE_TO_CUSTOM_QUEUE,
  ADD_EPISODE_TO_HISTORY,
  CHANGE_TRACKLIST,
  CLEAR_CUSTOM_QUEUE,
  EPISODE_MANIFEST_NOT_FOUND,
  GET_FULL_EPISODE_FAILURE,
  GET_FULL_EPISODE_LOADING,
  GET_FULL_EPISODE_SUCCESS,
  MOVE_CUSTOM_QUEUE_EPISODE_POSITION,
  NEXT_TRACK,
  PAUSE_AUDIO,
  PLAY_AUDIO,
  PLAY_EPISODE,
  PLAY_EPISODE_FROM_CUSTOM_QUEUE,
  PLAY_EPISODE_FROM_TRACKLIST,
  PREVIOUS_TRACK,
  READY_STATE_CHANGED,
  REMOVE_EPISODE_FROM_CUSTOM_QUEUE,
  REMOVE_EPISODE_FROM_HISTORY,
  RESET_AUDIO,
  SEEK_AUDIO,
  SET_OVERRIDE_SEEK_KEY,
  SET_QUEUE_MODE,
  SET_STREAMING_CONTEXT,
  SET_VOLUME,
  TIME_UPDATE,
  TOGGLE_CAN_PLAY,
  TOGGLE_ERROR,
  TOGGLE_META_LOADED,
  TOGGLE_MUTED,
  TOGGLE_STALLED
} from './types';
import {
  getEpisodeById,
  getTracklistHistory,
  getTracklistIndex,
  getTracklistQueue,
  overrideAudioSeek,
  updateEpisodes
} from './utility';
import { filter } from '@/helpers/lodash';
import { seekHasPassedThresholdForPreviousTrack } from '@/helpers/audio';

const DEFAULT_AUDIO_STATE = {
  seek: 0,
  resumeFromSeek: 0,
  metaLoaded: false,
  error: null,
  stalled: false,
  canPlayFired: false,
  readyState: 0,
  streamingContext: null
};

const reducer = (state = { ...initialState }, action) => {
  if (!state || !action) { return state; }

  const { type, payload } = action;

  switch (type) {
    case GET_FULL_EPISODE_LOADING: {
      const { id } = payload;

      // Mark the episode as loading
      return updateEpisodes({
        state,
        episodes: [{ id }],
        status: 'loading',
        isFullEpisode: true
      });
    }
    case GET_FULL_EPISODE_SUCCESS: {
      const { episode, notFound } = payload;

      // 1. Mark the episode as successfully fetched
      // 2. Save the episode in the episodes object
      return updateEpisodes({
        state,
        episodes: [episode],
        status: 'success',
        isFullEpisode: true,
        notFound
      });
    }
    case GET_FULL_EPISODE_FAILURE: {
      const { id } = payload;

      // Mark the episode as failed to fetch
      return updateEpisodes({
        state,
        episodes: [{ id }],
        status: 'failure',
        isFullEpisode: true
      });
    }
    case PLAY_AUDIO: {
      return {
        ...state,
        paused: false
      };
    }
    case PAUSE_AUDIO: {
      return {
        ...state,
        paused: true
      };
    }
    case PLAY_EPISODE: {
      const {
        episode,
        key,
        seek,
        streamingContext
      } = payload;

      const { id, show } = episode;
      const { id: showId } = show;

      const updatedState = {
        ...overrideAudioSeek({
          state: {
            ...state,
            ...DEFAULT_AUDIO_STATE
          },
          seek
        }),
        paused: false,
        currentlyPlayingTrack: {
          id,
          season: episode.season,
          episodeNumber: episode.episode_number,
          showId,
          show,
          key,
          playbackType: 'tracklist'
        },
        tracklistPosition: {
          index: getTracklistIndex({
            tracklist: state.tracklist,
            episodeId: episode.id
          }),
          id
        },
        streamingContext
      };

      return {
        ...updateEpisodes({
          state: updatedState,
          episodes: [episode],
          status: 'success'
        })
      };
    }
    case PLAY_EPISODE_FROM_TRACKLIST: {
      const {
        id, key, streamingContext
      } = payload;
      const episode = getEpisodeById({
        state,
        id
      });

      const updatedState = {
        ...overrideAudioSeek({
          state: {
            ...state,
            ...DEFAULT_AUDIO_STATE
          }
        }),
        paused: false,
        currentlyPlayingTrack: {
          id: episode.id,
          showId: episode.show.id,
          season: episode.season,
          episodeNumber: episode.episode_number,
          show: episode.show,
          key,
          playbackType: 'tracklist'
        },
        tracklistPosition: {
          index: getTracklistIndex({
            tracklist: state.tracklist,
            episodeId: id
          }),
          id
        },
        streamingContext
      };

      return {
        ...updateEpisodes({
          state: updatedState,
          episodes: [episode],
          status: 'success'
        })
      };
    }
    case PLAY_EPISODE_FROM_CUSTOM_QUEUE: {
      const { key, id } = payload;

      const { episodes, customQueue } = state;
      const { episode } = episodes[id] || {};
      const toIndex = customQueue.findIndex(({ key: episodeKey }) => (key === episodeKey));
      const queueWillBeEmpty = !customQueue[toIndex + 1];
      const slicedQueue = queueWillBeEmpty
        ? []
        : customQueue.slice(toIndex + 1);

      const updatedState = {
        ...overrideAudioSeek({
          state: {
            ...state,
            ...DEFAULT_AUDIO_STATE
          }
        }),
        paused: false,
        customQueue: slicedQueue,
        currentlyPlayingTrack: {
          id: episode.id,
          showId: episode.show.id,
          season: episode.season,
          episodeNumber: episode.episode_number,
          show: episode.show,
          key,
          playbackType: 'custom'
        }
      };

      return {
        ...updateEpisodes({
          state: updatedState,
          episodes: [episode],
          status: 'success'
        })
      };
    }
    case NEXT_TRACK: {
      const { customQueue } = state;
      const nextTrackIsInCustomQueue = !!customQueue.length;
      
      const trackListQueue = getTracklistQueue({ state });
      const nextEpisodeQueueObject = customQueue[0] || trackListQueue[0];
      const nextEpisode = nextEpisodeQueueObject
        ? getEpisodeById({
          state,
          id: nextEpisodeQueueObject.id
        })
        : null;
      
      if (!nextEpisode) {
        return {
          ...overrideAudioSeek({ state }),
          paused: true
        };
      }

      const nextEpisodeKey = (nextEpisodeQueueObject || {}).key;
      const nextEpisodeId = nextEpisode.id;
      let customQueueUpdates = {};
      let tracklistQueueUpdates = {};
      
      if (nextTrackIsInCustomQueue) {
        const newCustomQueue = filter(state.customQueue, ({ key: k }) => k !== nextEpisodeKey);

        customQueueUpdates = { customQueue: newCustomQueue };
      } else {
        tracklistQueueUpdates = {
          tracklistPosition: {
            index: getTracklistIndex({
              tracklist: state.tracklist,
              episodeId: nextEpisodeId
            }),
            id: nextEpisodeId
          }
        };
      }

      return {
        ...overrideAudioSeek({
          state: {
            ...state,
            ...DEFAULT_AUDIO_STATE
          }
        }),
        ...tracklistQueueUpdates,
        ...customQueueUpdates,
        paused: false,
        currentlyPlayingTrack: {
          id: nextEpisode.id,
          showId: nextEpisode.show.id,
          season: nextEpisode.season,
          episodeNumber: nextEpisode.episode_number,
          show: nextEpisode.show,
          key: nextEpisodeKey,
          playbackType: nextTrackIsInCustomQueue ? 'custom' : 'tracklist'
        }
      };
    }
    case PREVIOUS_TRACK: {
      if (seekHasPassedThresholdForPreviousTrack(state.seek)) {
        return {
          ...overrideAudioSeek({ state }),
          paused: false
        };
      }

      const tracklistHistory = getTracklistHistory({ state });
      const previousEpisode = tracklistHistory[tracklistHistory.length - 1];

      if (!previousEpisode) {
        return {
          ...overrideAudioSeek({ state }),
          paused: true
        };
      }

      const previousEpisodeId = previousEpisode.id;

      return {
        ...overrideAudioSeek({
          state: {
            ...state,
            ...DEFAULT_AUDIO_STATE
          }
        }),
        tracklistPosition: {
          index: getTracklistIndex({
            tracklist: state.tracklist,
            episodeId: previousEpisodeId
          }),
          id: previousEpisodeId
        },
        paused: false,
        currentlyPlayingTrack: {
          id: previousEpisodeId,
          showId: previousEpisode.show.id,
          show: previousEpisode.show,
          season: previousEpisode.season,
          episodeNumber: previousEpisode.episode_number,
          key: previousEpisode.key,
          playbackType: 'tracklist'
        }
      };
    }
    case CHANGE_TRACKLIST: {
      const { tracklist, episodes } = payload;

      return {
        ...state,
        ...updateEpisodes({
          state,
          episodes,
          status: 'success'
        }),
        tracklistPosition: {
          ...state.tracklistPosition,
          index: getTracklistIndex({
            tracklist,
            episodeId: state.tracklistPosition.id
          })
        },
        tracklist
      };
    }
    case CLEAR_CUSTOM_QUEUE: {
      return {
        ...state,
        customQueue: []
      };
    }
    case ADD_EPISODE_TO_CUSTOM_QUEUE: {
      const { episode, key } = payload;

      const newQueue = [
        ...state.customQueue,
        {
          key,
          id: episode.id
        }
      ];

      // Add the episode to the customQueue array
      return {
        ...updateEpisodes({
          state,
          episodes: [episode],
          status: 'success'
        }),
        customQueue: newQueue
      };
    }
    case REMOVE_EPISODE_FROM_CUSTOM_QUEUE: {
      const { key } = payload;

      // Remove the episode from the customQueue array
      const newQueue = filter(state.customQueue, ({ key: k }) => k !== key);
      
      return {
        ...state,
        customQueue: newQueue
      };
    }
    case MOVE_CUSTOM_QUEUE_EPISODE_POSITION: {
      // From index position and to index
      const { from, to } = payload;

      // Create a version of the queue with the from index removed
      const episodesWithoutFrom = filter(state.customQueue, (e, ind) => from !== ind);
      
      // Place the episode at the to index
      return {
        ...state,
        customQueue: [
          ...episodesWithoutFrom.slice(0, to),
          state.customQueue[from],
          ...episodesWithoutFrom.slice(to)
        ]
      };
    }
    case RESET_AUDIO: {
      return initialState;
    }
    case REMOVE_EPISODE_FROM_HISTORY: {
      const history = state.episodeHistory || [];

      return {
        ...state,
        episodeHistory: history.slice(1, history.length)
      };
    }
    case ADD_EPISODE_TO_HISTORY: {
      const { id } = payload;

      return {
        ...state,
        episodeHistory: [
          id,
          ...(state.episodeHistory || [])
        ]
      };
    }
    case SEEK_AUDIO: {
      const { seek } = payload;

      return {
        ...overrideAudioSeek({
          state,
          seek
        })
      };
    }
    case TIME_UPDATE: {
      const { seek } = payload;

      return {
        ...state,
        seek
      };
    }
    case SET_VOLUME: {
      const { volume } = payload;

      return {
        ...state,
        volume
      };
    }
    case TOGGLE_MUTED: {
      const { muted } = payload;

      return {
        ...state,
        muted
      };
    }
    case SET_STREAMING_CONTEXT: {
      const { streamingContext } = payload;

      return {
        ...state,
        streamingContext
      };
    }
    case TOGGLE_META_LOADED: {
      const { metaLoaded } = payload;

      return {
        ...state,
        metaLoaded
      };
    }
    case TOGGLE_ERROR: {
      const { error } = payload;

      return {
        ...state,
        error
      };
    }
    case TOGGLE_STALLED: {
      const { stalled } = payload;

      return {
        ...state,
        stalled
      };
    }
    case TOGGLE_CAN_PLAY: {
      const { hasFired } = payload;

      return {
        ...state,
        canPlayFired: hasFired
      };
    }
    case READY_STATE_CHANGED: {
      const { readyState } = payload;

      return {
        ...state,
        readyState,
        ...((readyState >= 2) ? { audioInitialised: true } : {})
      };
    }
    case SET_OVERRIDE_SEEK_KEY: {
      const { seekOverrideKey } = payload;

      return {
        ...state,
        seekOverrideKey
      };
    }
    case SET_QUEUE_MODE: {
      const { mode: keakiePlaybackMode } = payload;
      
      return {
        ...state,
        keakiePlaybackMode
      };
    }
    case EPISODE_MANIFEST_NOT_FOUND: {
      const { episodeId } = payload;

      const stateForEpisode = state.episodes[episodeId] || {};
    
      return {
        ...state,
        episodes: {
          ...state.episodes,
          [episodeId]: {
            ...stateForEpisode,
            manifestNotFound: true
          }
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;