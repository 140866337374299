import {
  useCallback, useMemo, useRef, useState
} from 'react';
  
// Use this if we need a choice of whether to
// A) Consume a value via state updates
// B) Consume the value at any point in time, without state udpates
const useStateWithRef = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  
  const valueRef = useRef(initialValue);
  
  const get = useCallback(() => valueRef.current, []);
  
  const set = useCallback((newValue) => {
    valueRef.current = newValue;
    setValue(newValue);
  }, []);
  
  return useMemo(() => ({
    value,
    get,
    set
  }), [value, get, set]);
};
  
export default useStateWithRef;