import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, GET_EPISODE_USER_EDGE } } = CONFIG;

// Get the edge between user and an episode
const getEpisodeUserEdge = ({ episodeId }) => {
  return callApi({
    url: HOST(GET_EPISODE_USER_EDGE) + GET_EPISODE_USER_EDGE
      .replace(':episode_id', episodeId)
  });
};

export default getEpisodeUserEdge;