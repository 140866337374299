export const GET_LIBRARY_LOADING = 'library/GET_LIBRARY_LOADING';
export const GET_LIBRARY_SUCCESS = 'library/GET_LIBRARY_SUCCESS';
export const GET_LIBRARY_FAILURE = 'library/GET_LIBRARY_FAILURE';

export const SET_FILTER = 'library/SET_FILTER';
export const SET_TEXT_FILTER = 'library/SET_TEXT_FILTER';
export const CLEAR_FILTERS = 'library/CLEAR_FILTERS';

export const SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY = 'library/SET_LIBRARY_AUDIO_QUEUE_SEARCH_KEY';

export const ADD_EPISODE_TO_LIBRARY_LOADING = 'library/ADD_EPISODE_TO_LIBRARY_LOADING';
export const ADD_EPISODE_TO_LIBRARY_SUCCESS = 'library/ADD_EPISODE_TO_LIBRARY_SUCCESS';
export const ADD_EPISODE_TO_LIBRARY_FAILURE = 'library/ADD_EPISODE_TO_LIBRARY_FAILURE';

export const REMOVE_EPISODE_FROM_LIBRARY_LOADING = 'library/REMOVE_EPISODE_FROM_LIBRARY_LOADING';
export const REMOVE_EPISODE_FROM_LIBRARY_SUCCESS = 'library/REMOVE_EPISODE_FROM_LIBRARY_SUCCESS';
export const REMOVE_EPISODE_FROM_LIBRARY_FAILURE = 'library/REMOVE_EPISODE_FROM_LIBRARY_FAILURE';

export const RESET_STATE = 'library/RESET_STATE';