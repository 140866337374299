export const DEFAULT_GENRES = 'dg'; // Genre
export const CHILD_GENRES = 'cg'; // Genre
export const NETWORK = 'n';
export const SEED = 'se';
export const NEW_EPISODES = 'ne';
export const NEW_RELEASES = 'nr';
export const SET = 'set';
export const PREFERRED_GENRES = 'pg'; // Genre
export const TIMED_GENRES = 'tg'; // Genre
export const USER_ACTIVITY = 'ua';
export const EPISODE = 'ep';
export const SEARCH = 'sea';
export const DIRECT = 'd';
export const HERO = 'hr';
export const AUTOPLAY = 'a';
export const CHANNEL = 'c';
export const DISCOVER = 'dis';
export const LIBRARY = 'l';

export const channelRecommendationTypes = {
  CHILD_GENRES,
  NEW_EPISODES,
  NEW_RELEASES,
  SEED,
  SET,
  PREFERRED_GENRES,
  TIMED_GENRES,
  USER_ACTIVITY,
  EPISODE,
  SEARCH,
  DIRECT,
  HERO
};

export const streamingContextTypes = {
  // Context specific
  DIRECT_EPISODE_URL: 'direct',
  QUEUED_EPISODE: null,
  DISCOVER_HERO_PLAY_BUTTON: null,
  DISCOVER_ROW_SHOW_SLATE: null,
  SHOW_HERO_PLAY_BUTTON: 'show',
  SHOW_EPISODE: 'show',
  SHOW_RELATED_SHOW_SLATE: null,
  SEARCH_SHOW_SLATE: null,
  SEARCH_EPISODE: null,
  GENRE_SHOW_SLATE: null,
  // Generic
  SHOW: 'show',
  LIBRARY: null,
  NULL: null
};

const generateErrorInfo = (description, info = {}) => JSON.stringify({
  description,
  ...info
});

// A list of errors for the app to send to the API.
// Each error has a key and an info function which constructs the info object.
export const errors = {
  // This is used whenever the app throws an error from a component which is caught by the error boundary
  ERROR_BOUNDARY: {
    key: 'EDNA_FE_001',
    info: (key) => generateErrorInfo(
      'An error was caught by an error boundary',
      { key }
    )
  },
  // Used whenever there is an API related error
  API_CALL: {
    key: 'EDNA_FE_002',
    info: ({
      url, code, body
    } = {}) => generateErrorInfo(
      'There was an error recieved in an API response',
      {
        url,
        code,
        body
      }
    )
  },
  // Used whenever there is an error on the server side
  SERVER: {
    key: 'EDNA_FE_003',
    info: ({ statusCode } = {}) => generateErrorInfo(
      'There was an error on the server',
      { statusCode }
    )
  },
  // Used whenever there is an audio related error
  AUDIO: {
    key: 'EDNA_FE_004',
    info: (detailsObject) => generateErrorInfo(
      'There was an audio error',
      detailsObject
    )
  },
  // Used when an audio manifest is not found
  AUDIO_MANIFEST_NOT_FOUND: {
    key: 'EDNA_FE_005',
    info: (detailsObject) => generateErrorInfo(
      'The audio manifest file was not found',
      detailsObject
    )
  }
};