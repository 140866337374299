import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import { Provider } from './context';
import { getProcessEnv } from '@/helpers/env';
import { sendEventToGA, setPersistentPropertiesInGA } from '@/helpers/events-ga';
import { GA_DIM_KEAKIE_APP } from '@/config/events-ga/dimensions';
import useStateWithRef from '@/hooks/utility/useStateWithRef';
import useUser from '@/hooks/api/useUser';
import useLanguage from '@/hooks/language/useLanguage';

const { NODE_ENV } = process.env;
const {
  ENABLE_GA, KEAKIE_STAGING_MODE, GA_TRACKING_IDS
} = getProcessEnv();

const COMMON_OPTIONS = {
  gaOptions: { testMode: NODE_ENV === 'development' || KEAKIE_STAGING_MODE },
  gtagOptions: {},
  legacyDimensionMetric: false
};

const GAProvider = ({ children }) => {
  const [isInitialised, setIsInitialised] = useState(false);

  const {
    value: GAUserId,
    set: setGAUserId,
    get: getGAUserId
  } = useStateWithRef();

  const { asPath } = useRouter();
  const { userId } = useUser();
  const { languageChangeInProgress } = useLanguage();

  // Initialise
  useEffect(() => {
    if (userId && !isInitialised && ENABLE_GA && !languageChangeInProgress && (GA_TRACKING_IDS || []).length) {
      ReactGA.initialize(
        GA_TRACKING_IDS.map((trackingId) => ({ trackingId })),
        { ...COMMON_OPTIONS }
      );
  
      setGAUserId(userId);
      setIsInitialised(true);
    }
  }, [userId, isInitialised, setGAUserId, languageChangeInProgress]);

  // Update current user id
  useEffect(() => {
    if ((userId !== getGAUserId())) {
      setGAUserId(userId);
    }
  }, [userId, getGAUserId, setGAUserId]);
  
  // Send page view events
  useEffect(() => {
    const uid = getGAUserId();

    if (isInitialised && uid) {
      ReactGA.send({
        hitType: 'pageview',
        userId: uid,
        [GA_DIM_KEAKIE_APP]: 'web'
      });
    }
  }, [asPath, isInitialised, getGAUserId]);

  const sendGAEvent = useCallback(({ isClosingBrowser = false, ...params }) => {
    const uid = getGAUserId();

    sendEventToGA({
      ...params,
      transport: isClosingBrowser ? 'beacon' : 'xhr',
      ...(uid ? { userId: uid } : {})
    });
  }, [getGAUserId]);

  const setGAProperties = useCallback((parameters) => {
    if (isInitialised) {
      setPersistentPropertiesInGA(parameters);
    }
  }, [isInitialised]);

  const value = useMemo(() => ({
    userIdReady: !!(isInitialised && GAUserId),
    shouldSendEvents: !languageChangeInProgress,
    sendGAEvent,
    setGAProperties,
    isInitialised
  }), [sendGAEvent, isInitialised, GAUserId, setGAProperties, languageChangeInProgress]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

GAProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default GAProvider;