import { createSelector } from 'reselect';
import { FILTER_KEY_FORMAT, FILTER_KEY_GENRE } from '@/config/libraryFilters';

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

// LIBRARY
export const selectLibraryState = (state) => state.library;

// GENRE FILTER
export const selectLibrarySearchFilters = (state) => selectLibraryState(state).searchFilters;
export const selectLibraryGenreFilterId = (state) => selectLibrarySearchFilters(state)[FILTER_KEY_GENRE].id;
export const selectLibraryGenreFilterValue = (state) => selectLibrarySearchFilters(state)[FILTER_KEY_GENRE].value;

// FORMAT FILTER
export const selectLibraryFormatFilterId = (state) => selectLibrarySearchFilters(state)[FILTER_KEY_FORMAT].id;
export const selectLibraryFormatFilterValue = (state) => selectLibrarySearchFilters(state)[FILTER_KEY_FORMAT].value;

// TEXT FILTER
export const selectLibraryTextFilter = (state) => selectLibraryState(state).textSearchFilter;

// LIBRARY TRACKS FOR FILTER KEY
export const selectLibraryTracksForFilterKey = (state, filterKey) => (selectLibraryState(state).keys[filterKey] || DEFAULT_OBJECT).tracks || DEFAULT_ARRAY;
export const selectLibraryStatusForFilterKey = (state, filterKey) => (selectLibraryState(state).keys[filterKey] || DEFAULT_OBJECT).status || DEFAULT_OBJECT;
export const selectLibraryTracksCountForFilterKey = createSelector(
  [selectLibraryTracksForFilterKey],
  (tracks) => tracks.length
);

// LIBRARY TRACKS
export const selectLibraryTracks = (state) => selectLibraryTracksForFilterKey(state, '');
export const selectLibraryStatus = (state) => selectLibraryStatusForFilterKey(state, '');
export const selectLibraryTracksCount = createSelector(
  [selectLibraryTracks],
  (tracks) => tracks.length
);
export const selectLibraryIsEmpty = createSelector(
  [selectLibraryStatus, selectLibraryTracks],
  (libraryStatus, tracks) => libraryStatus.notFound || libraryStatus.error || (!!libraryStatus.success && !tracks.length)
);

// AUDIO QUEUE SEARCH KEY
export const selectLibraryAudioQueueSearchKey = (state) => selectLibraryState(state).audioQueueSearchKey;