import without from 'lodash.without';
import { setStatus } from '@/helpers/state';

// Helper reducer function for toggling an account feature
export const updateFeatureEnabledStatus = ({
  state,
  key,
  enabled,
  status,
  onlyUpdateLoading = false
}) => {
  const { user: { features }, featuresStatus } = state;

  const newState = { ...state };

  // Add or remove the key from the featuresLoading array based on its current state
  newState.featuresStatus = {
    ...featuresStatus,
    ...setStatus(key, status)
  };

  // Add or remove the key from the enabled features array based on its current state
  if (!onlyUpdateLoading) {
    newState.user = {
      ...state.user,
      features: !enabled
        ? without(features, key)
        : [
          ...(features || []),
          key
        ]
    };
  }

  return newState;
};

// Helper reducer function for fetching a user episode edge
export const updateUserEpisodeEdge = ({
  state,
  status,
  episodeId,
  episodeEdge
}) => {
  const currentEpisodeEdges = state.episodeEdges;
  const currentEpisodeEdge = currentEpisodeEdges[episodeId] || {};

  return {
    ...state,
    episodeEdges: {
      ...currentEpisodeEdges,
      [episodeId]: {
        ...setStatus('status', status),
        edge: episodeEdge || currentEpisodeEdge.edge
      }
    }
  };
};

export const updateUser = ({
  state,
  status,
  user
}) => {
  return {
    ...state,
    user: {
      ...state.user,
      ...(user ? { statistics: user.statistics } : {})
    },
    ...setStatus('userStatus', status)
  };
};