import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, UPDATE_ACCOUNT } } = CONFIG;

// Turn an account feature on or off
const toggleAccountFeature = ({ key, enabled }) => {
  return callApi({
    url: HOST(UPDATE_ACCOUNT) + UPDATE_ACCOUNT,
    method: 'PATCH',
    body: [{ [key]: enabled }],
    errorOn404: true
  });
};

export default toggleAccountFeature;