import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Gradient, { GRADIENT_POSITION_BOTTOM, GRADIENT_POSITION_TOP } from '../Gradient';
import TileGrain from '@/components/app/TileGrain';
import EpisodeMenu from '@/components/app/EpisodeMenu';
import SquareImage from '@/components/component-library/Image/KeakieImageSquare';
import CloseIcon from '@/components/component-library/Icon/types/Close';
import Overlay from '@/components/app/Overlay';
import SlateImage from '@/components/component-library/Image/KeakieImageSlate';
import Text, { FONT_SIZE_070, FONT_SIZE_080 } from '@/components/component-library/Text';
import { classes } from '@/helpers/styling';
import { goToChannel, goToEpisode } from '@/helpers/pages/channel';
import { satisfiesBrowser } from '@/helpers/browser';
import { SMALL, XLARGE } from '@/config/assets';
import { channelRecommendationTypes } from '@/config/events-keakie';
import { createImageAltTextForShow } from '@/helpers/seo/shows';

const OVERLAY_BACKGROUND_STYLE = { background: 'black' };

const OVERLAY_STYLE = {
  justifyContent: 'space-between',
  overflowY: 'auto'
};

const EpisodeMenuOverlayMobile = ({
  excludeMenuOptionKeys,
  episode,
  episodeQueueKey,
  onClose,
  onGoToChannel,
  forceLibraryRemove,
  forceQueueRemove,
  forceQueueAdd,
  open,
  onClickNavigationOption
}) => {
  const delayDisplayingImage = satisfiesBrowser({ safari: '>0' });

  const [showImage, setShowImage] = useState(!delayDisplayingImage);
  const timeout = useRef(null);

  useEffect(() => {
    if (delayDisplayingImage) {
      clearTimeout(timeout.current);

      if (open) {
        timeout.current = setTimeout(() => {
          setShowImage(true);
        }, 100);
      } else {
        timeout.current = setTimeout(() => {
          setShowImage(false);
        }, 100);
      }
    }
  }, [open, delayDisplayingImage]);

  const show = (episode || {}).show || {};
  const recommendationId = (episode || {}).id;
  const recommendationType = channelRecommendationTypes.EPISODE;

  const handleGoToChannel = () => {
    onClose();

    if (onClickNavigationOption) {
      onClickNavigationOption();
    }

    goToChannel({
      channelId: show.id,
      channelSlug: show.slug,
      recommendationId,
      recommendationType
    });
  };

  const handleGoToEpisode = () => {
    onClose();

    if (onClickNavigationOption) {
      onClickNavigationOption();
    }

    goToEpisode({
      channelId: show.id,
      channelSlug: show.slug,
      episodeNumber: (episode || {}).episode_number,
      seasonNumber: (episode || {}).season,
      recommendationId,
      recommendationType
    });
  };

  return (
    <Overlay
      backgroundStyle={OVERLAY_BACKGROUND_STYLE}
      className='episode-menu-overlay-mobile'
      commonClassName={classes('transition-slide-from-bottom', { '--is-open': open })}
      style={OVERLAY_STYLE}
      zIndex={115}
    >
      <div className='overlay-background'>
        <SlateImage
          alt={createImageAltTextForShow({ show })}
          assetSize={SMALL}
          assetsObj={show?.assets}
          className='background-slate-image'
          disableBorderRadius
        />
        <TileGrain />
      </div>
      <div className='content'>
        <Gradient breadth='25%' gradientBreadth='100%'position={GRADIENT_POSITION_TOP} />
        <div className='show-info'>
          <div className='top-section'>
            {showImage && (
              <div className='show-square-image'>
                <SquareImage
                  alt={createImageAltTextForShow({ show })}
                  assetSize={XLARGE}
                  assetsObj={show?.assets}
                  forceHighDensity
                  hasBorderRadius={false}
                  onClick={handleGoToChannel}
                />
              </div>
            )}
            <Text
              bold
              className='episode-title'
              onClick={handleGoToEpisode}
              size={FONT_SIZE_080}
              useHeaderFont
            >
              {(episode || {}).title}
            </Text>
            <Text
              bold
              className='channel-title'
              onClick={handleGoToChannel}
              size={FONT_SIZE_080}
              useHeaderFont
            >
              {show.title}
            </Text>
            <Gradient
              breadth='75%'
              directionReverse
              gradientBreadth='100%'
              position={GRADIENT_POSITION_BOTTOM}
            />
          </div>
          <div className='bottom-section'>
            <EpisodeMenu
              episode={(episode || {})}
              episodeQueueKey={episodeQueueKey}
              excludeMenuOptionKeys={excludeMenuOptionKeys}
              forceLibraryRemove={forceLibraryRemove}
              forceQueueAdd={forceQueueAdd}
              forceQueueRemove={forceQueueRemove}
              onClickNavigationOption={onClickNavigationOption}
              onClose={onClose}
              onGoToChannel={onGoToChannel}
              recommendationId={recommendationId}
              recommendationType={recommendationType}
              style={{ marginTop: '20px' }}
            />
            <div className='cancel-button' onClick={onClose}>
              <div className='clear-icon'>
                <CloseIcon />
              </div>
              <Text bold size={FONT_SIZE_070} useHeaderFont>
                Close
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

EpisodeMenuOverlayMobile.propTypes = {
  episodeQueueKey: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onGoToChannel: PropTypes.func,
  excludeMenuOptionKeys: PropTypes.array,
  forceLibraryRemove: PropTypes.bool,
  forceQueueAdd: PropTypes.bool,
  forceQueueRemove: PropTypes.bool,
  episode: PropTypes.object,
  open: PropTypes.bool,
  onClickNavigationOption: PropTypes.func
};

EpisodeMenuOverlayMobile.defaultProps = {
  episodeQueueKey: null,
  onGoToChannel: undefined,
  excludeMenuOptionKeys: [],
  forceLibraryRemove: false,
  forceQueueAdd: false,
  forceQueueRemove: false,
  episode: null,
  open: false,
  onClickNavigationOption: undefined
};

export default EpisodeMenuOverlayMobile;
