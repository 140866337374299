import * as colors from '@/sass/variables/colors.module.scss';

// KEYS
const WHITE_KEY = 'var-white';
const GREY_X_LIGHT_KEY = 'var-grey-x-light';
const GREY_LIGHT_KEY = 'var-grey-light';
const GREY_MEDIUM_KEY = 'var-medium-grey';
const GREY_KEY = 'var-grey';
const BLACK_LIGHT_KEY = 'var-light-black';
const BLACK_MATTE_KEY = 'var-matte-black';
const BLACK_KEY = 'var-black';
const BLUE_KEY = 'var-blue';

// VALUES
export const WHITE = colors[WHITE_KEY];
export const GREY_X_LIGHT = colors[GREY_X_LIGHT_KEY];
export const GREY_LIGHT = colors[GREY_LIGHT_KEY];
export const GREY_MEDIUM = colors[GREY_MEDIUM_KEY];
export const GREY = colors[GREY_KEY];
export const BLACK_LIGHT = colors[BLACK_LIGHT_KEY];
export const BLACK_MATTE = colors[BLACK_MATTE_KEY];
export const BLACK = colors[BLACK_KEY];
export const BLUE = colors[BLUE_KEY];