export const LIGHT_GRADIENT_TEMPLATE = `
  rgba(COLOUR, 1) 0%,
  rgba(COLOUR, 0.987259) 0.75%,
  rgba(COLOUR, 0.951407) 2.92%,
  rgba(COLOUR, 0.896) 6.37%,
  rgba(COLOUR, 0.824593) 10.97%,
  rgba(COLOUR, 0.740741) 16.59%,
  rgba(COLOUR, 0.648) 23.1%,
  rgba(COLOUR, 0.549926) 30.37%,
  rgba(COLOUR, 0.450074) 38.27%,
  rgba(COLOUR, 0.352) 46.66%,
  rgba(COLOUR, 0.259259) 55.41%,
  rgba(COLOUR, 0.175407) 64.39%,
  rgba(COLOUR, 0.104) 73.47%,
  rgba(COLOUR, 0.0485926) 82.52%,
  rgba(COLOUR, 0.0127407) 91.41%,
  rgba(COLOUR, 0) 100%
`;

export const SMALL_GRADIENT_TEMPLATE = `
  rgba(COLOUR, 1) 0%,
  rgba(COLOUR, 0.916) 4.8%,
  rgba(COLOUR, 0.83) 8.4%,
  rgba(COLOUR, 0.742) 11.4%,
  rgba(COLOUR, 0.654) 13.8%,
  rgba(COLOUR, 0.567) 16.1%,
  rgba(COLOUR, 0.482) 18.6%,
  rgba(COLOUR, 0.401) 21.6%,
  rgba(COLOUR, 0.323) 25.4%,
  rgba(COLOUR, 0.251) 30.2%,
  rgba(COLOUR, 0.186) 36.5%,
  rgba(COLOUR, 0.128) 44.5%,
  rgba(COLOUR, 0.08) 54.6%,
  rgba(COLOUR, 0.042) 66.9%,
  rgba(COLOUR, 0.015) 82%,
  rgba(COLOUR, 0) 100%
`;

export const MEDIUM_GRADIENT_TEMPLATE = `
  rgba(COLOUR, 1) 0%,
  rgba(COLOUR, 0.919) 5.1%,
  rgba(COLOUR, 0.839) 9.6%,
  rgba(COLOUR, 0.762) 13.8%,
  rgba(COLOUR, 0.686) 17.7%,
  rgba(COLOUR, 0.612) 21.7%,
  rgba(COLOUR, 0.54) 25.8%,
  rgba(COLOUR, 0.47) 30.3%,
  rgba(COLOUR, 0.403) 35.3%,
  rgba(COLOUR, 0.338) 41%,
  rgba(COLOUR, 0.275) 47.6%,
  rgba(COLOUR, 0.215) 55.3%,
  rgba(COLOUR, 0.157) 64.2%,
  rgba(COLOUR, 0.102) 74.5%,
  rgba(COLOUR, 0.049) 86.3%,
  rgba(COLOUR, 0) 100%
`;

export const LARGE_GRADIENT_TEMPLATE = `
  rgba(COLOUR, 1) 0%,
  rgba(COLOUR, 0.987) 9.2%,
  rgba(COLOUR, 0.951) 17.2%,
  rgba(COLOUR, 0.896) 24.3%,
  rgba(COLOUR, 0.825) 30.6%,
  rgba(COLOUR, 0.741) 36.4%,
  rgba(COLOUR, 0.648) 41.7%,
  rgba(COLOUR, 0.55) 46.7%,
  rgba(COLOUR, 0.45) 51.8%,
  rgba(COLOUR, 0.352) 56.9%,
  rgba(COLOUR, 0.259) 62.3%,
  rgba(COLOUR, 0.175) 68.2%,
  rgba(COLOUR, 0.104) 74.7%,
  rgba(COLOUR, 0.049) 82.1%,
  rgba(COLOUR, 0.013) 90.5%,
  rgba(COLOUR, 0) 100%
`;