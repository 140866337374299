import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, LIBRARY } } = CONFIG;

// Add a track [by id] to the current user's library
const addToLibrary = (id) => {
  return callApi({
    url: HOST(LIBRARY) + LIBRARY,
    method: 'POST',
    body: { episode_id: id },
    errorOn404: true
  });
};

export default addToLibrary;