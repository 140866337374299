import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { IS_HYDRATED_KEY, attachCacheHydraterToReducers } from './caching';
import account from '@/state/account';
import audio from '@/state/audio';
import library from '@/state/library';
import notifications from '@/state/notifications';
import router from '@/state/router';
import { LOGIN_TYPE_SHADOW } from '@/config/login';
import { CREATE_KEAKIE_LOGIN_SUCCESS, LOGOUT_SUCCESS } from '@/state/account/types';

const reducers = {
  library,
  account,
  audio,
  router,
  notifications
};

const initialState = combineReducers(reducers)(undefined, {});

const computeStoreStateAfterLoginChange = ({ state, userHasLoggedIn = false }) => ({
  ...initialState,
  audio: state.audio,
  account: {
    ...(userHasLoggedIn ? state.account : initialState.account),
    [IS_HYDRATED_KEY]: state.account[IS_HYDRATED_KEY]
  },
  router: {
    ...initialState.router,
    ...state.router,
    showRouteLoader: false,
    routeLoading: false
  }
});

const reducer = combineReducers(
  attachCacheHydraterToReducers(
    {
      reducersObject: reducers,
      initialState
    }
  )
);

const rootReducer = (state, action) => {
  // If (1) the user has logged in as a non shadow user
  // Or (2) the user has logged out
  // We should reset state that needs to be refreshed for a new user
  if (((action.type === CREATE_KEAKIE_LOGIN_SUCCESS) && (action.payload.type !== LOGIN_TYPE_SHADOW)) || (action.type === LOGOUT_SUCCESS)) {
    return reducer(
      computeStoreStateAfterLoginChange({
        state,
        userHasLoggedIn: (action.type === CREATE_KEAKIE_LOGIN_SUCCESS)
      }),
      action
    );
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});