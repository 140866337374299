// A FILE WITH LODASH REPLACEMENT FUNCTIONS
// Lodash is not supported by the Next js middleware function
// as it is not eval safe

import { truthyOrZeroOrEmptyString } from './math';

const identityFunction = _ => _;

const defaultsDeepPredicate = (x) => x === null || x === undefined;

export const isObject = obj => obj instanceof Object;

export const isObjectButNotArrayOrFunction = (obj) => isObject(obj) && !Array.isArray(obj) && typeof obj !== 'function';

// Taken from https://gist.github.com/stefanmaric/86b7bcb7398ba018109a9a8497e3443f
export const defaultsDeep = (target, defaults, predicate = defaultsDeepPredicate) => {
  if (Object(target) !== target) return { ...defaults };

  return Object.keys(defaults).reduce((acc, key) => {
    const defaultValue = defaults?.[key];
    const targetValue = target?.[key];

    if ((typeof defaultValue !== 'function') && !Array.isArray(defaultValue) && Object(defaultValue) === defaultValue) {
      acc[key] = defaultsDeep(targetValue || {}, defaultValue, predicate);
    } else {
      acc[key] = predicate(targetValue, key) ? defaultValue : targetValue;
    }

    return acc;
  }, { ...target });
};

export const map = (collection = [], iterateeFunction) => {
  if (!collection) {
    return [];
  }

  const iteratee = iterateeFunction || identityFunction;

  if (Array.isArray(collection)) {
    return collection.map(iteratee);
  }

  return Object.entries(collection).map(([key, value]) => iteratee(value, key, collection));
};

export const filter = (collection = [], iterateeFunction) => {
  if (!collection) {
    return [];
  }

  const iteratee = iterateeFunction || identityFunction;

  if (Array.isArray(collection)) {
    return collection.filter(iteratee);
  }

  return Object.entries(collection)
    .filter(([key, value]) => iteratee(value, key, collection))
    .map(([, value]) => value);
};

export const mapValues = (object, iterateeFunction) => {
  if (!object) {
    return {};
  }
  
  const newObject = {};
  const iteratee = iterateeFunction || identityFunction;

  Object
    .entries(object)
    .forEach(([key, value]) => {
      newObject[key] = iteratee(value, key, object);
    });

  return newObject;
};

export const get = (object, path) => {
  if (!object || !truthyOrZeroOrEmptyString(path)) {
    return undefined;
  }

  const fields = path.split('.');

  let value = object;

  fields.every((field) => {
    if (!isObjectButNotArrayOrFunction(value)) {
      value = value?.[field];
      return false;
    }

    value = value?.[field];
    return true;
  });

  return truthyOrZeroOrEmptyString(value) ? value : undefined;
};

export function isEqual(obj1, obj2) {
  if (!arguments.length) { return false; }
  if (obj1 === obj2) { return true; }

  if (
    typeof obj1 !== 'object'
      || typeof obj2 !== 'object'
      || obj1 == null
      || obj2 == null
  ) {
    return false;
  }

  const keysA = Object.keys(obj1);
  const keysB = Object.keys(obj2);

  if (keysA.length !== keysB.length) {
    return false;
  }

  let result = true;

  keysA.forEach((key) => {
    if (!keysB.includes(key)) {
      result = false;
    }

    if (
      typeof obj1[key] === 'function'
          || typeof obj2[key] === 'function'
    ) {
      if (obj1[key].toString() !== obj2[key].toString()) {
        result = false;
      }
    }

    if (!isEqual(obj1[key], obj2[key])) {
      result = false;
    }
  });

  return result;
}

export const size = item => {
  if (!item) {
    return 0;
  }

  return (item.constructor === Object ? Object.keys(item || {}).length : (item || []).length) || 0;
};

export const reduce = (collection, iterateeFunction, initialValue) => {
  let array = collection;
  const isArray = Array.isArray(collection);
  const iteratee = iterateeFunction || identityFunction;

  if (!isArray) {
    array = Object.entries(collection);
  }
  
  return array.reduce(
    (previousValue, value, currentIndex) => {
      const [
        keyFromObject,
        valueFromObject
      ] = isArray ? [] : value;

      return iteratee(
        previousValue,
        isArray ? value : valueFromObject,
        isArray ? currentIndex : keyFromObject,
        collection
      );
    },
    initialValue
  );
};