import cloneDeep from 'lodash.clonedeep';
import omit from 'lodash.omit';
import set from 'lodash.set';
import throttle from 'lodash.throttle';
import watch from 'redux-watch';
import {
  isEqual, mapValues, get
} from '@/helpers/lodash';
import {
  clearLocalStorage, getLocalStorageItem, setLocalStorageItem
} from '@/helpers/localStorage';
import { initialStatusObject } from '@/helpers/state';
import CONFIG from '@/config/global';

const audioDefaults = {
  paused: true,
  metaLoaded: false,
  error: null,
  stalled: false,
  canPlayFired: false,
  seekOverrideKey: 0,
  audioInitialised: false,
  readyState: 0
};

const {
  localStorage: {
    enableCacheKeys,
    cacheVersion,
    localStorageTimeouts
  }
} = CONFIG;

// Resets any status objects that have loading, error or notFound states.
// This makes sure, when state is rehydrated from the cache, that these calls are retriggered
export const resetCachedStatusObject = (key = null, statusObject = {}) => {
  const {
    loading, error, notFound
  } = statusObject;

  // If the status object is in a loading, error or not found state, reset it
  const newStatusObject = (loading || error || notFound)
    ? initialStatusObject()
    : statusObject;

  if (!key) {
    return newStatusObject;
  }

  return { [key]: newStatusObject };
};

const statusHasChangedToSuccess = ({
  oldStatus,
  newStatus
  // objectPath
}) => {
  return (!oldStatus || (oldStatus && !oldStatus.success))
  && (newStatus && newStatus.success);
};

// Resets status objects as above but for all objects one level deep that contain 'status' in their field name.
// Eg for { field1Status: { ... }, field2: { ... } }, resetCachedStatusObject is run only on field1Status.
export const resetAllCachedStatusObjects = (state) => {
  return mapValues(state, (value, key) => {
    // If the field name contains status, reset it

    if (key.toLowerCase().includes('status')) {
      return resetCachedStatusObject(null, value);
    }

    // Otherwise do not touch the field
    return value;
  });
};

// Key to use in lcoal storage for storing state
const cacheKey = 'keakie-cache';
// Key to use in local storage for the cache version
const cacheVersionKey = 'keakie-cache-version';
// Key to use in local storage to hold expiry timestamps for different segments of state
const cacheExpiryKey = 'keakie-cache-expiry';

// Cache config which handles:
// (1) - which segments of state to store in local storage
// (2) - How to manipulate the segments when they are saved to/retrieved from local storage
// (3) - How long to hold segments in state before they expire
// (4) - Whether to update the expiry timestamp for a segment
const cacheConfig = [
  // Example
  // {
  //   key: 'audio',
  //   updater: (state) => {
  //     return state;
  //   },
  //   getter: (state) => {
  //     return {
  //       ...state,
  //       episodes: resetAllCachedStatusObjects(state.episodes)
  //     };
  //   },
  //   expiryKey: 'audio',
  //   ttl: 10,
  //   shouldReplaceExpiryKeyOnUpdate: (newVal, oldVal, objectPath) => {
  //     return true;
  //   }
  // }
  {
    key: 'audio',
    updater: (state) => state,
    getter: (state) => {
      return {
        ...state,
        // Reset status objects for episodes in case they were in a transitioning state (loading/error etc)
        episodes: resetAllCachedStatusObjects(
          mapValues(state.episodes, (episodeObject) => {
            if (episodeObject.manifestNotFound) {
              // If the episode manifest was previously not found,
              // Remove the full episode information to make sure we re-call the API for any potential fixes
              return omit(episodeObject, ['fullEpisode', 'fullEpisodeStatus', 'manifestNotFound']);
            }
            
            return episodeObject;
          })
        ),
        // Reset certain audio states to their default state
        ...audioDefaults,
        resumeFromSeek: state.seek || 0
      };
    },
    ...localStorageTimeouts.audio,
    getterOnExpiry: ({ state, initialState }) => {
      const { currentlyPlayingEpisode, episodes } = state || {};

      const currentEpisode = episodes?.[currentlyPlayingEpisode];

      return {
        ...initialState,
        ...state,
        episodes: (currentlyPlayingEpisode && currentEpisode)
          ? resetAllCachedStatusObjects({ [currentlyPlayingEpisode]: currentEpisode }) : {},
        // Reset certain audio states to their default state
        ...audioDefaults
        
      };
    },
    shouldReplaceExpiryKeyOnUpdate: ({ expiryInCache }) => !expiryInCache
  },
  {
    key: 'genres.parents',
    updater: (state) => state,
    getter: (state) => state,
    ...localStorageTimeouts.parentGenres,
    shouldReplaceExpiryKeyOnUpdate: () => false
  },
  {
    key: 'genres.parentsStatus',
    updater: (state) => state,
    getter: (state) => resetCachedStatusObject(null, state),
    ...localStorageTimeouts.parentGenres,
    shouldReplaceExpiryKeyOnUpdate: ({
      oldVal: oldStatus, newVal: newStatus, objectPath
    }) => statusHasChangedToSuccess({
      oldStatus,
      newStatus,
      objectPath
    })
  },
  {
    key: 'genres.all',
    updater: (state) => state,
    getter: (state) => state,
    ...localStorageTimeouts.allGenres,
    shouldReplaceExpiryKeyOnUpdate: () => false
  },
  {
    key: 'genres.allGenresFlattened',
    updater: (state) => state,
    getter: (state) => state,
    ...localStorageTimeouts.allGenres,
    shouldReplaceExpiryKeyOnUpdate: () => false
  },
  {
    key: 'genres.allStatus',
    updater: (state) => state,
    getter: (state) => resetCachedStatusObject(null, state),
    ...localStorageTimeouts.allGenres,
    shouldReplaceExpiryKeyOnUpdate: ({
      oldVal: oldStatus, newVal: newStatus, objectPath
    }) => statusHasChangedToSuccess({
      oldStatus,
      newStatus,
      objectPath
    })
  }
].filter((o) => enableCacheKeys.includes(o.key));

// Retrieve the current expiry object from local storage
const getExpiryFromCache = () => {
  return getLocalStorageItem(cacheExpiryKey) || {};
};

// Set a new timestamp expiry for a given key in local storage
const setExpiryTimestampForKey = (key, value = Date.now()) => {
  const expiryInCache = getExpiryFromCache();

  setLocalStorageItem({
    key: cacheExpiryKey,
    value: {
      ...expiryInCache,
      [key]: value
    }
  });
};

// Update local storage with our current state object
const updateCache = (state) => {
  // For each item in the cache config, retrieve it's value from state and transform it using it's respective updater function
  const newState = (cacheConfig || []).reduce((
    currentNewState,
    { key, updater }
  ) => {
    return set(
      { ...currentNewState },
      key,
      updater(get(state, key))
    );
  }, {});

  // Set the new state in local storage using our cache key
  setLocalStorageItem({
    key: cacheKey,
    value: newState
  });
};

// Retrieve the cached state from local storage
export const getCache = ({ initialState = {} } = {}) => {
  const initialStateCopy = cloneDeep(initialState);

  // Get the state from local storage
  let cachedState = getLocalStorageItem(cacheKey);
  // Get the caching version from local storage
  const cachedVersion = getLocalStorageItem(cacheVersionKey) || '';
  // Get the expiry timestamp object from local storage
  const cachedExpiryKeys = getExpiryFromCache();
  
  const defaultReturnValues = {
    state: undefined,
    cacheVersion: cachedVersion
  };
  
  // If the cache version has been updated, we need to reset local storage for the app.
  // This avoids any differences in state structure or how we handle getting/setting cached state.
  if (cachedVersion !== cacheVersion) {
    // Clear all local storage
    clearLocalStorage();

    // Set the new cache key
    setLocalStorageItem({
      key: cacheVersionKey,
      value: cacheVersion
    });

    const majorCacheChange = cachedVersion.split('.')[0] !== cacheVersion.split('.')[0];

    // When clearing cache due to a minor cache version change, keep the audio information cached.
    // We don't want people to lose what they were listening to
    const clearedCacheState = majorCacheChange ? {} : { audio: (cachedState || {}).audio };

    setLocalStorageItem({
      key: cacheKey,
      value: clearedCacheState
    });

    cachedState = clearedCacheState;
  }

  // If there is no cache config or no cached state, there is nothing to restore
  if (!cacheConfig || !cachedState) {
    return defaultReturnValues;
  }

  // Otherwise proceed to restore state from local storage for all fields in the cache config.
  // Start with the initialState object as a base.
  return {
    cacheVersion: cachedVersion,
    state: cacheConfig.reduce((
      restoredState,
      {
        key,
        getter,
        expiryKey,
        ttl,
        getterOnExpiry
      }
    ) => {
      // Get the initial state for this key
      const initialStateForKey = get(initialStateCopy, key) || {};
      // Get the cached state for this key
      const cachedStateForKey = get(cachedState, key);
      // Get the exprity timestamp for this key
      const cachedExpiry = cachedExpiryKeys[expiryKey];

      // If there is no cached state, simply return the initial state
      if (!cachedStateForKey) {
        return restoredState;
      }

      const getStateAfterExpiry = () => {
        setExpiryTimestampForKey(expiryKey, null);

        if (getterOnExpiry) {
          set(
            restoredState,
            key,
            {
              ...initialStateForKey,
              ...getterOnExpiry({
                state: cachedStateForKey,
                initialState: initialStateForKey
              })
            }
          );
        }

        return restoredState;
      };

      // If this key is expirable (has an expiryKey and time to live value), but has no current timestamp in local storage,
      // treat it as expired and simply return the initial state
      if (expiryKey && ttl && !cachedExpiry) {
        return getStateAfterExpiry();
      }

      // If this key has expired, reset it's expiry key and return the result of the expired function
      if (
        expiryKey
        && ttl
        && cachedExpiry
        && (((Date.now() - cachedExpiry) / 1000) > ttl)
      ) {
        return getStateAfterExpiry();
      }

      // Otherwise, we can restore this key's state from local storage and should do so using it's getter function
      set(
        restoredState,
        key,
        {
          ...initialStateForKey,
          ...getter(cachedStateForKey)
        }
      );

      return restoredState;
    }, initialStateCopy)
  };
};

// Create a store subscription to update the cache every 5 seconds
const cachingSubscription = (store) => throttle(() => {
  const state = store.getState() || {};

  updateCache(state);
}, 3000);

// Attach the caching subscription to the store to initialise local storage caching with the store
export const attachCachingSubscription = (store) => {
  // Add the subscription so that we will update state in local storage every 5 seconds
  store.subscribe(cachingSubscription(store));

  // For every key in the cache config, add a subscription to the store to regularly check for state expiry in local storage
  // This way we regularly flush out expired state
  cacheConfig.forEach(({
    key,
    expiryKey = null,
    ttl = null,
    shouldReplaceExpiryKeyOnUpdate = null
  }) => {
    const watchFunction = watch(store.getState, key, isEqual);

    store.subscribe(
      throttle(
        watchFunction(
          (newVal, oldVal, objectPath) => {
            if (expiryKey && ttl) {
              if (
                shouldReplaceExpiryKeyOnUpdate
                  ? shouldReplaceExpiryKeyOnUpdate({
                    newVal,
                    oldVal,
                    objectPath,
                    expiryInCache: getExpiryFromCache()[expiryKey]
                  })
                  : true
              ) {
                setExpiryTimestampForKey(expiryKey);
              }
            }
          }
        ),
        2500
      )
    );
  }, []);
};