import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const {
  api: {
    HOST,
    EVENTS_CHANNEL
    // EVENTS_KEAKIE
  }
} = CONFIG;

// Send an event to notify the API that a channel has been viewed
// and why/how it was viewed
const sendChannelViewEventLegacy = ({
  channelId,
  recommendationId,
  recommendationType
}) => {
  return callApi({
    url: HOST(EVENTS_CHANNEL) + EVENTS_CHANNEL,
    method: 'POST',
    body: {
      cid: channelId,
      rt: recommendationType,
      rid: recommendationId
    }
  });
};

// const sendChannelViewEvent = ({
//   channelId,
//   recommendationId,
//   recommendationType
// }) => {
//   return callApi({
//     url: HOST(EVENTS_KEAKIE) + EVENTS_KEAKIE,
//     method: 'POST',
//     body: {
//       event_name: 'show_view',
//       context: {
//         from: recommendationType,
//         from_id: recommendationId
//       },
//       event_timestamp: new Date().toISOString(),
//       resource: {
//         id: channelId,
//         type: 'show'
//       }
//     }
//   });
// };

export default sendChannelViewEventLegacy;