import {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import EpisodeMenu from '@/components/app/EpisodeMenu';
import Overlay from '@/components/app/Overlay';
import useWindowSize from '@/hooks/browser/useWindowSize';

const OVERLAY_STYLE = {
  background: 'none',
  pointerEvents: 'all'
};

const EpisodeMenuOverlayDesktop = ({
  excludeMenuOptionKeys,
  episode,
  episodeQueueKey,
  onClose,
  onGoToChannel,
  forceLibraryRemove,
  forceQueueRemove,
  forceQueueAdd,
  boundingClientRect,
  calculateEpisodeMenuStyle,
  onClickNavigationOption
}) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize({ debounceTime: 250 });

  const episodeOverlayRef = useRef(null);

  const [overlayClientRect, setOverlayClientRect] = useState({});

  useEffect(() => {
    const newOverlayClientRect = episodeOverlayRef.current
      ? episodeOverlayRef.current.getBoundingClientRect()
      : {};

    setOverlayClientRect(newOverlayClientRect);
  }, [episodeOverlayRef]);

  const style = calculateEpisodeMenuStyle ? calculateEpisodeMenuStyle({
    windowWidth,
    windowHeight,
    episodeMenuBoundingClientRect: {
      ...overlayClientRect,
      // Defining these values fixes an issue where episodeMenuBoundingClientRect had an invalid height
      height: 317,
      width: 317
    },
    episodeBoundingClientRect: boundingClientRect
  }) : {};

  useEffect(() => {
    if (episodeOverlayRef.current) {
      episodeOverlayRef.current.focus();
    }
  }, []);

  const handleClickModal = useCallback((e) => {
    e.stopPropagation();

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Overlay
      commonStyle={ OVERLAY_STYLE }
      onClick={ handleClickModal }
      zIndex={ 102 }
    >
      <div
        className='episode-menu-overlay-desktop'
        ref={ episodeOverlayRef }
        style={ style }
      >
        <EpisodeMenu
          episode={ episode }
          episodeQueueKey={ episodeQueueKey }
          excludeMenuOptionKeys={ excludeMenuOptionKeys }
          forceLibraryRemove={ forceLibraryRemove }
          forceQueueAdd={ forceQueueAdd }
          forceQueueRemove={ forceQueueRemove }
          onClickNavigationOption={ onClickNavigationOption }
          onClose={ onClose }
          onGoToChannel={ onGoToChannel }
        />
      </div>
    </Overlay>
  );
};

EpisodeMenuOverlayDesktop.propTypes = {
  boundingClientRect: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  episode: PropTypes.object,
  episodeQueueKey: PropTypes.string,
  onGoToChannel: PropTypes.func,
  excludeMenuOptionKeys: PropTypes.array,
  forceLibraryRemove: PropTypes.bool,
  forceQueueAdd: PropTypes.bool,
  forceQueueRemove: PropTypes.bool,
  calculateEpisodeMenuStyle: PropTypes.func,
  onClickNavigationOption: PropTypes.func
};

EpisodeMenuOverlayDesktop.defaultProps = {
  episodeQueueKey: null,
  onGoToChannel: undefined,
  episode: null,
  excludeMenuOptionKeys: [],
  forceLibraryRemove: false,
  forceQueueRemove: false,
  forceQueueAdd: false,
  boundingClientRect: {},
  calculateEpisodeMenuStyle: undefined,
  onClickNavigationOption: undefined
};

export default EpisodeMenuOverlayDesktop;