import PropTypes from 'prop-types';
import EpisodeMenuOverlayDesktop from './Desktop';
import EpisodeMenuOverlayMobile from './Mobile';
import useShouldRenderForMobile from '@/hooks/layout/useShouldRenderForMobile';

const EpisodeMenuOverlay = ({ open, ...props }) => {
  const shouldRenderForMobile = useShouldRenderForMobile();

  return shouldRenderForMobile
    ? (
      <EpisodeMenuOverlayMobile { ...{
        ...props,
        open
      } }
      />
    ) : !!open && (
      <EpisodeMenuOverlayDesktop { ...props } />
    );
};

EpisodeMenuOverlay.propTypes = { open: PropTypes.bool };

EpisodeMenuOverlay.defaultProps = { open: false };

export default EpisodeMenuOverlay;