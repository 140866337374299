export const HYDRATE_STORE_FROM_LOCAL_STORAGE = 'caching/HYDRATE_STORE_FROM_LOCAL_STORAGE';
export const IS_HYDRATED_KEY = 'is_hydrated';

export const reducerWithHydration = ({
  reducer, initialState, reducerKey
}) => {
  return (state = { ...initialState }, action) => {
    if (!state || !action) { return state; }
  
    const { type, payload } = action;

    if (type === HYDRATE_STORE_FROM_LOCAL_STORAGE) {
      const { hydratedState } = payload;

      if (hydratedState && hydratedState[reducerKey]) {
        return {
          ...hydratedState[reducerKey],
          [IS_HYDRATED_KEY]: true
        };
      }
    }

    return reducer(state, action);
  };
};

export const attachCacheHydraterToReducers = ({ reducersObject, initialState }) => {
  const reducers = {};

  Object.keys(reducersObject || {}).forEach((reducerKey) => {
    reducers[reducerKey] = reducerWithHydration({
      reducer: reducersObject[reducerKey],
      initialState: initialState[reducerKey],
      reducerKey
    });
  });

  return reducers;
};

export const hydrateStoreFromCache = ({ hydratedState }) => (dispatch) => {
  return dispatch({
    type: HYDRATE_STORE_FROM_LOCAL_STORAGE,
    payload: { hydratedState }
  });
};