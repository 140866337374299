import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { isBrowser } from '@/helpers/browser';
import useInitialRender from '@/hooks/browser/useInitialRender';

const Portal = ({ selectorId, children }) => {
  const { isFirstRender } = useInitialRender();

  const selector = isBrowser() && document.querySelector(`#${ selectorId }`);

  if (isFirstRender || (selector === undefined)) {
    return null;
  }

  return ReactDOM.createPortal(children, selector);
};

Portal.propTypes = {
  selectorId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Portal;