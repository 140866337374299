import fetch from 'isomorphic-fetch';
import { size } from '@/helpers/lodash';
import { store } from '@/state/store';
import { logout } from '@/state/account/actions';
import { selectLogin } from '@/state/selectors/user';
import { getProcessEnv } from '@/helpers/env';
import { isBrowser } from '@/helpers/browser';
import { promiseWithTimeout } from '@/helpers/utility';
import { createErrorWithObj } from '@/helpers/errors';
import { generateQueryString } from '@/helpers/routing';

const { SSR_TOKEN } = getProcessEnv();

// These headers are sent with every api request
const universalHeaders = {
  'keakie-api-version': '1.0.0',
  'Content-Type': 'application/json'
};

const throwApiError = ({ message, status } = {}) => {
  throw createErrorWithObj({
    message,
    obj: { status }
  });
};

// App wide api call handler that returns a promise
// If successful returns { status: Number, json: Object, notFound: Bool }
export const callApi = ({
  url, // base url for the call
  method = 'GET',
  headers = {},
  queryParams = null, // Object of key value pairs to convert to a query string
  body = null,
  keepalive = false, // Boolean to indicate whether to keep the request alive,
  mode = null, // Mode for the request
  errorOn404 = false, // Boolean to indicate whether to throw an error if a 404 is encountered
  notFoundOnEmptyNodes = false,
  isServer = false, // Use this to set a server side credential (SSR_TOKEN)
  refreshTokenOn401 = true
}) => {
  // Timeout api calls after this many miliseconds
  // Sever side api calls should be timed out quicker to avoid waiting for content
  const callTimeout = isBrowser() ? 12000 : 3000;
  
  // If the base url already contains a question mark, do not add another in the query string
  const excludeQuestionMark = url ? url.includes('?') : false;

  // Append query parameters onto the url
  const urlWithParams = queryParams
    ? `${ url }${ generateQueryString({
      queryParams,
      excludeQuestionMark
    }) }`
    : url;

  return promiseWithTimeout(
    fetch(urlWithParams, {
      method,
      headers: {
        ...universalHeaders,
        ...headers,
        ...((isServer && SSR_TOKEN) ? { 'Service-Access-Token': SSR_TOKEN } : {})
      },
      credentials: 'include',
      ...(body ? { body: JSON.stringify(body) } : {}),
      ...(mode ? { mode } : {}),
      keepalive,
      'allow-sync-xhr-in-page-dismissal': true
    }),
    callTimeout
  ).then((res) => {
    const {
      status, ok, headers: resHeaders
    } = res;
    const is404 = status === 404;
    const isUnauthorized = status === 401;

    // If the resource is not found (404), throw an error IF errorOn404 is true
    if (is404 && errorOn404) {
      throwApiError({ status });
    }

    // If the call was unauthorised, log the user out
    if (!is404 && isUnauthorized && refreshTokenOn401) {
      const currentLoginState = selectLogin(store.getState());

      if (currentLoginState.type) {
        store.dispatch(logout({ shouldCallApi: false }));
      }

      throwApiError({ status });
    }

    // If the call errors for some other reason, throw an error
    if (!is404 && !ok) {
      throwApiError({ status });
    }

    // If the call was successful or a 404, return the results
    return res.json()
      .then((json) => {
        // If a nodes field exists, we classify it as a 404 if nodes is falsey or empty
        const nodesAreEmpty = Object.prototype.hasOwnProperty.call(json, 'nodes')
          && (!json.nodes || !size(json.nodes));

        return {
          status,
          json,
          headers: resHeaders,
          notFound: (status === 404) || (notFoundOnEmptyNodes ? nodesAreEmpty : false)
        };
      })
      .catch(() => {
        return ({
          status,
          json: null,
          notFound: (status === 404)
        });
      });
  });
};

// Check an api status object to determine whether it's timestamp is out of date
// This can be used to decide whether to re-attempt an API call
export const hasStatusObjectExpired = ({ status, timeoutDelay }) => {
  // Extract the timestamp from the status object
  const { timestamp } = status || {};

  // If there is no timestamp then this status object doesn't use timeouts
  if (!timestamp) { return false; }

  // Use a default retry delay if the one provided is falsey
  const retryDelay = timeoutDelay || 10;
  // Calculate the elapsed time since the timestamp
  const timeDifference = (Date.now() - timestamp) / 1000;

  // If the elapsed time is greater than the retry delay, return true
  return (timeDifference > retryDelay);
};

// Uses the standardised status object to decide whether to make an API call
// Returns { should: Bool, hasExpired: Bool }
export const shouldCall = ({
  status,
  timeoutDelay = null,
  ignoreCacheExpiry = false // Useful if the cached data was cleared, but the expiry was key left in tact
}) => {
  // Extract details from the status object
  const {
    loading,
    success,
    error,
    notFound,
    timestamp
  } = status || {};

  // Check whether the status object has expired
  const hasExpired = hasStatusObjectExpired({
    status,
    timeoutDelay
  });

  const useCacheExpiry = (timestamp && timeoutDelay && !ignoreCacheExpiry);
  
  return {
    should: !!(
      !loading
        && (
          useCacheExpiry
            ? hasExpired
            : !success
        )
        && (
          error
            ? (useCacheExpiry && hasExpired)
            : true
        )
        && !notFound
    ),
    hasExpired
  };
};

// Checks an API status object for a loading status
export const isStatusObjectLoading = ({
  status,
  showLoadingWhenIdle = true
}) => {
  return showLoadingWhenIdle
    ? (shouldCall({ status }).should || status.loading) // Show a loading state if the status object is in a non loading state that will trigger an API call
    : status.loading; // Show a loading state only if the status object is explicitly loading
};

// Extracts all useful information from a status object
export const getStatusDetails = ({
  status = {},
  page = 0,
  showLoadingWhenIdle = true, // Show a loading state if the status object is in a non loading state that will trigger an API call
  timeoutDelay = null // After what duration should this status object expire
}) => {
  const { should: shouldMakeCall, hasExpired } = shouldCall({
    status,
    timeoutDelay
  });
  const loading = isStatusObjectLoading({
    status,
    showLoadingWhenIdle
  });
  const loadingInitialPage = loading && !page;
  const loadingMore = loading && !loadingInitialPage;

  return {
    shouldCall: shouldMakeCall,
    loading,
    loadingInitialPage,
    loadingMore,
    error: status.error,
    success: status.success,
    notFound: status.notFound,
    hasExpired
  };
};

export const sendBeaconRequest = ({
  url,
  body = {},
  headers = {}
}) => {
  if (isBrowser()) {
    callApi({
      url,
      method: 'POST',
      body,
      headers,
      keepalive: true
    }).catch(() => {});
  }
};