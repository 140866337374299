import {
  FEATURE_KEY_ADMIN_STATUS,
  FEATURE_KEY_UNLIMITED_SKIPS,
  FEATURE_KEY_DIAGNOSTICS_STATUS,
  FEATURE_KEY_CUSTOM_LANGUAGE
} from './config';

const featureToSearchCommandMap = {
  [FEATURE_KEY_ADMIN_STATUS]: 'keakie admin access',
  [FEATURE_KEY_UNLIMITED_SKIPS]: 'keakie unlimited crew',
  [FEATURE_KEY_DIAGNOSTICS_STATUS]: 'keakie diagnostics',
  [FEATURE_KEY_CUSTOM_LANGUAGE]: 'keakie language'
};

export const featureToSearchCustomPropertiesFunctions = {};

export default featureToSearchCommandMap;