import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Provider } from './context';
import { extractStreamedTimeRanges } from '@/helpers/audio';
import useAudioElement from '@/hooks/audio/useAudioElement';
import {
  selectCurrentEpisodeKey, selectAudioPaused, selectAudioReadyForPlayback,
  selectKeakiePlaybackMode
} from '@/state/selectors/audio';

const DEFAULT_STATS = {
  initialBufferTimeSeconds: 0,
  totalBufferTimeSeconds: 0
};

const selector = (state) => ({
  currentlyPlayingEpisodeKey: selectCurrentEpisodeKey(state),
  paused: selectAudioPaused(state),
  audioReadyForPlayback: selectAudioReadyForPlayback(state),
  keakiePlaybackMode: selectKeakiePlaybackMode(state)
});

const ShakaPlayerProvider = ({ children }) => {
  const shakaPlayerRef = useRef(null);
  const reloadAudioRef = useRef(null);
  const previousTrackStatsRef = useRef(DEFAULT_STATS);
  const currentTrackStatsRef = useRef(DEFAULT_STATS);

  const [, setShakaPlayerReady] = useState(false);
  const [loadedTrackKey, setLoadedTrackKey] = useState();

  useEffect(() => {
    return () => {
      if (shakaPlayerRef.current) {
        // Destroy the player on dismount. This is especially important
        // as otherwise music will continue playing when changing language (changing route)
        shakaPlayerRef.current.destroy();
      }
    };
  }, []);

  const {
    currentlyPlayingEpisodeKey,
    audioReadyForPlayback,
    paused,
    keakiePlaybackMode: trackKeyPrefix
  } = useSelector(selector, shallowEqual);
  const trackKey = `${ trackKeyPrefix || '' }${ trackKeyPrefix ? ':' : '' }${ currentlyPlayingEpisodeKey }`;

  const shakaReadyForPlayback = !paused
    && audioReadyForPlayback
    && (loadedTrackKey === currentlyPlayingEpisodeKey); // Make sure the current track has been loaded by Shaka

  const { audioRef, audioRefReady } = useAudioElement();

  const [audioInteracted, setAudioInteracted] = useState(false);

  const checkAndHandleInitialPlayInteraction = useCallback(() => {
    if (audioRefReady && !audioInteracted) {
      // We need a play event explicitly inside the click listener to support Safari mobile.
      // Otherwise, it will throw an error to say that the user has not given permission.
      // DELETING THIS WILL BREAK SAFARI MOBILE
      // The setTimeout delay is to ensure that the app audio state has been set before we force user interaction playback
      setTimeout(() => audioRef.current.play(), 250);
      setAudioInteracted(true);
    }
  }, [audioRefReady, audioInteracted, audioRef]);

  const extractBufferingTimeFromPlayer = useCallback(() => (shakaPlayerRef?.current?.getStats() || {}).bufferingTime || 0, []);

  const handleInitialisePlayer = useCallback(({ player, reloadAudio }) => {
    shakaPlayerRef.current = player;
    reloadAudioRef.current = reloadAudio;
    setShakaPlayerReady(true);
  }, []);

  const getCurrentTrackStats = useCallback(() => ({
    ...currentTrackStatsRef.current,
    totalBufferTimeSeconds: extractBufferingTimeFromPlayer()
      || currentTrackStatsRef.current.totalBufferTimeSeconds
      || 0,
    ...(shakaPlayerRef?.current?.getMediaElement() ? extractStreamedTimeRanges({ mediaElement: shakaPlayerRef.current.getMediaElement() }) : {})
  }), [extractBufferingTimeFromPlayer]);

  const setCurrentTrackStats = useCallback((stats) => {
    currentTrackStatsRef.current = {
      ...currentTrackStatsRef.current,
      ...stats
    };
  }, []);

  const resetCurrentTrackStats = useCallback(() => {
    currentTrackStatsRef.current = DEFAULT_STATS;
  }, []);

  const getPreviousTrackStats = useCallback(() => previousTrackStatsRef.current, []);

  const setPreviousTrackStats = useCallback((stats) => {
    previousTrackStatsRef.current = {
      ...previousTrackStatsRef.current,
      ...stats
    };
  }, []);

  const resetPreviousTrackStats = useCallback(() => {
    previousTrackStatsRef.current = DEFAULT_STATS;
  }, []);

  const reloadAudio = useCallback((...args) => reloadAudioRef.current(...args), []);

  const value = useMemo(() => ({
    shakaPlayerRef,
    trackKey,
    loadedTrackKey,
    handleInitialisePlayer,
    reloadAudio,
    extractBufferingTimeFromPlayer,
    getCurrentTrackStats,
    setCurrentTrackStats,
    resetCurrentTrackStats,
    getPreviousTrackStats,
    setPreviousTrackStats,
    resetPreviousTrackStats,
    setLoadedTrackKey,
    shakaReadyForPlayback,
    audioInteracted,
    checkAndHandleInitialPlayInteraction
  }), [
    shakaPlayerRef,
    trackKey,
    loadedTrackKey,
    handleInitialisePlayer,
    reloadAudio,
    extractBufferingTimeFromPlayer,
    getCurrentTrackStats,
    setCurrentTrackStats,
    resetCurrentTrackStats,
    getPreviousTrackStats,
    setPreviousTrackStats,
    resetPreviousTrackStats,
    setLoadedTrackKey,
    shakaReadyForPlayback,
    audioInteracted,
    checkAndHandleInitialPlayInteraction
  ]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

ShakaPlayerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ShakaPlayerProvider;