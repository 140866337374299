import { iconDefaultProps, iconPropTypes } from '../../propTypes';
import Add from '../Add';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const Close = (props) => {
  const { className } = props;
  
  return (
    <Add
      { ...props }
      className={ classes(className, styles.closeIcon) }
    />
  );
};

Close.propTypes = { ...iconPropTypes };

Close.defaultProps = { ...iconDefaultProps };

export default Close;