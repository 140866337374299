import React from 'react';
import PropTypes from 'prop-types';
import EpisodeMenuOption from './EpisodeMenuOption';
import useEpisodeMenuState from './state/useEpisodeMenuState';
import { classes } from '@/helpers/styling';
import CloseIcon from '@/components/component-library/Icon/types/Close';
import AddIcon from '@/components/component-library/Icon/types/Add';
import BinIcon from '@/components/component-library/Icon/types/Bin';
import ShareIcon from '@/components/component-library/Icon/types/Share';
import GoToShowIcon from '@/components/component-library/Icon/types/GoToShow';
import QueueIcon from '@/components/component-library/Icon/types/Queue';

const EpisodeMenu = ({
  onClose,
  episode,
  episodeQueueKey,
  excludeMenuOptionKeys,
  forceLibraryRemove,
  forceQueueRemove,
  forceQueueAdd,
  style,
  className,
  showCloseButton,
  onClickNavigationOption
}) => {
  const {
    handleAddToLibrary,
    handleRemoveFromLibrary,
    handleAddToQueue,
    handleGoToChannel,
    handleRemoveFromQueue,
    handleShare,
    edge,
    edgeStatus
  } = useEpisodeMenuState({
    episode,
    episodeQueueKey,
    onClose,
    skipLibraryCall: forceLibraryRemove,
    onClickNavigationOption
  });

  // Use this to conditionally display menu options
  const hasOption = React.useCallback(
    (key) => !excludeMenuOptionKeys.includes(key),
    [excludeMenuOptionKeys]
  );

  // ------------------- CONDITIONALLY RENDER ITEMS -------------------
  const libraryRemove = (
    <EpisodeMenuOption
      disabled={forceLibraryRemove ? edgeStatus.loading : !edge.id || edgeStatus.loading}
      icon={<BinIcon />}
      onClick={handleRemoveFromLibrary}
      text="Remove from library"
    />
  );

  const libraryAdd = (
    <EpisodeMenuOption
      disabled={!edge.id || edgeStatus.loading}
      icon={<AddIcon />}
      onClick={handleAddToLibrary}
      text="Add to library"
    />
  );

  let libraryOption = libraryAdd;

  if (!hasOption('library')) {
    libraryOption = null;
  } else if (forceLibraryRemove) {
    libraryOption = libraryRemove;
  } else if (edgeStatus.success || edgeStatus.loading) {
    libraryOption = edge.in_library ? libraryRemove : libraryAdd;
  }

  const queueRemove = (
    <EpisodeMenuOption
      icon={<QueueIcon />}
      onClick={handleRemoveFromQueue}
      text="Remove from queue"
    />
  );

  const queueAdd = (
    <EpisodeMenuOption
      icon={<QueueIcon />}
      onClick={handleAddToQueue}
      text="Add to queue"
    />
  );

  let queueOption = queueAdd;

  const episodeIsInQueue = false; // Modify this logic based on your use case

  if (!hasOption('queue')) {
    queueOption = null;
  } else if (forceQueueAdd) {
    queueOption = queueAdd;
  } else if (forceQueueRemove || (!forceQueueAdd && episodeIsInQueue)) {
    queueOption = queueRemove;
  }

  return (
    <div className={classes('episode-menu', className)} style={style}>
      {hasOption('goToChannel') && (
        <EpisodeMenuOption
          icon={<GoToShowIcon />}
          onClick={handleGoToChannel}
          text="Go to show"
        />
      )}
      {queueOption}
      {hasOption('share') && (
        <EpisodeMenuOption
          icon={<ShareIcon />}
          onClick={handleShare}
          text="Share"
       />
      )}
      {libraryOption}
      {showCloseButton && (
        <div className="episode-menu-close" onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

EpisodeMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  episode: PropTypes.object.isRequired,
  episodeQueueKey: PropTypes.string,
  excludeMenuOptionKeys: PropTypes.array,
  forceLibraryRemove: PropTypes.bool,
  forceQueueRemove: PropTypes.bool,
  forceQueueAdd: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClickNavigationOption: PropTypes.func
};

EpisodeMenu.defaultProps = {
  excludeMenuOptionKeys: [],
  episodeQueueKey: null,
  forceLibraryRemove: false,
  forceQueueRemove: false,
  forceQueueAdd: false,
  style: {},
  className: '',
  showCloseButton: false,
  onClickNavigationOption: undefined
};

export default EpisodeMenu;
