import { isBrowser } from '../browser';
import { map, reduce } from '@/helpers/lodash';
import {
  DISCOVER_KEY,
  GENRE_KEY,
  LIBRARY_KEY,
  PROFILE_KEY,
  SEARCH_KEY
} from '@/config/routeKeys';
import CONFIG from '@/config/global';
import { T_LOCALE_BASE, T_LOCALE_NEXT_DEFAULT } from '@/config/translations/languages.mjs';

const { routes } = CONFIG;

// Split a route up (by /) into it's valid segments
const getPathSegments = (path = '') => (path || '').toString()
  .split('/')
  .filter(a => a); // Filter segments that are falsey

const getPathFromUrl = (url) => {
  return url?.split(/[?#]/)[0];
};

// Convert a url path from the URL bar into it's respective route object from CONFIG (if it exists)
export const getRouteObject = (path = '') => {
  const params = {};

  // Split the path (by /) into it's segments
  const pathSegments = getPathSegments(getPathFromUrl(path) || '');

  let displayPath = null;

  // If the path has segments then attempt a route with all segments matching
  if (pathSegments.length) {
    // List of all routes
    let routeStrings = map(routes, ({ key }) => key);
    
    // For each segment in the url path, filter out any route possibilities that no longer match
    pathSegments.forEach((segment, index) => {
      routeStrings = routeStrings.filter((routeString) => {
        const comparison = getPathSegments(routeString)[index];

        if (!comparison) { return false; }

        // If the segments match exactly then the route is still a valid match
        if (comparison === segment) {
          return true;
        }
        // If the route segment is a parameter (:...) then a
        // truthy value means the route is still a valid match
        if (comparison.substring(0, 1) === ':') {
          params[comparison.substring(1)] = segment;
          return !!segment;
        }
        // Otherwise the route no longer matches
        return false;
      });
    });

    // Exclude any routes that have MORE segments than the url path
    displayPath = routeStrings
      .filter(
        (r) => (getPathSegments(r).length <= pathSegments.length)
      )[0] || null;
  } else {
    // Otherwise check for equality with a route string
    displayPath = Object.values(routes).find((v) => (v.key === path));
  }

  return displayPath
    ? {
      ...Object.values(routes).find((v) => (v.key === displayPath)),
      params
    } : null;
};

// Generate a path based on route and params
export const generatePath = ({ key = DISCOVER_KEY, params = {} }) => reduce(
  params,
  (url, paramValue, paramKey) => url.replace(`:${ paramKey }`, paramValue),
  key
);

export const removeQueryStringFromUrl = (url) => (url || '').split(/[?#]/)[0];

export const pathnameStartsWithLocale = ({ asPath, locale = '' }) => {
  if (!locale || !asPath) {
    return false;
  }

  return (asPath === `/${ locale }` || asPath.startsWith(`/${ locale }/`));
};

export const removeLocaleFromPathname = ({ asPath = '', locale = '' }) => {
  if (!locale) {
    return asPath;
  }

  return asPath.replace(`/${ locale }`, '');
};

export const addLocaleToPathname = ({ asPath = '', locale = '' }) => {
  if (!locale) {
    return asPath;
  }

  return `/${ locale }${ asPath }`;
};

export const replaceLocaleInPathname = ({
  asPath, oldLocale = '', newLocale = T_LOCALE_BASE
}) => {
  if (!oldLocale || !pathnameStartsWithLocale({
    asPath,
    locale: oldLocale
  })) {
    return asPath;
  }

  return asPath.replace(oldLocale, newLocale);
};

export const convertUrlToDesiredLocale = ({
  oldLocale = '', asPath = '', newLocale = ''
}) => {
  if (!newLocale) {
    return asPath;
  }

  if (!oldLocale) {
    return addLocaleToPathname({
      asPath,
      locale: newLocale
    });
  }

  if (pathnameStartsWithLocale({
    asPath,
    locale: oldLocale
  })) {
    return replaceLocaleInPathname({
      asPath,
      newLocale
    });
  }

  return asPath;
};

export const routerPushLocale = ({
  router,
  locale = T_LOCALE_BASE
} = {}) => {
  if (router) {
    const {
      pathname,
      asPath,
      query,
      push: routerPush
    } = router;

    routerPush(
      {
        pathname,
        query
      },
      asPath,
      {
        locale: (
          locale === T_LOCALE_BASE
            ? T_LOCALE_NEXT_DEFAULT // IMPORTANT: This ensures that the 'en' will not appear in the URL. Do not remove
            : locale
        ) || T_LOCALE_NEXT_DEFAULT
      }
    );
  }
};

// Generate a path based on route and params
export const generateCanonicalUrl = ({ asPath, locale = '' } = {}) => {
  const addLocale = !!locale && (locale !== T_LOCALE_BASE) && !pathnameStartsWithLocale({
    asPath,
    locale
  });

  return asPath
    ? removeQueryStringFromUrl(`https://www.keakie.com${ addLocale ? `/${ locale }` : '' }${ asPath }`)
    : null;
};

// Generate a full href inclkuding protocol, host and path
export const generateHref = (routeKey, params) => {
  const path = generatePath({
    key: routeKey,
    params
  });

  const { protocol, host } = window.location;

  return `${ protocol }//${ host }${ path }`;
};

// Opens a link in a new tab/window
export const openInNewTab = (href) => {
  if (href) {
    const win = isBrowser() && window.open(href, '_blank');
    if (win) { win.focus(); }
  }
};

// Force refresh the page
export const refreshPage = () => {
  if (isBrowser()) {
    window.location.reload();
  }
};

// Compute which route is active so that we can highlight the current route
// EG on the burger menu or desktop header bar
export const getActiveRouteForNavigation = ({ routeKey }) => {
  return {
    genresActive: (routeKey === GENRE_KEY),
    libraryActive: (routeKey === LIBRARY_KEY),
    profileActive: (routeKey === PROFILE_KEY),
    searchActive: (routeKey === SEARCH_KEY),
    discoverActive: (routeKey === DISCOVER_KEY)
  };
};

export const getQueryStringParams = () => {
  const urlSearchParams = new URLSearchParams(isBrowser() ? window.location.search : '');

  return Object.fromEntries(urlSearchParams.entries());
};

export const addCurrentPathToHref = ({ href = DISCOVER_KEY, asPath = '' } = {}) => {
  if (!asPath) {
    return href;
  }

  return `${ href }?href=${ encodeURIComponent(asPath) }`;
};

// This generates a query string from an object of key value pairs
// Eg { field1: 'value1', field2: 'value2' } => ?field1=value1&field2=value2
// excludeQuestionMark will skip the ? at the beginning - useful if generating a query string to append onto an existing one
export const generateQueryString = ({
  queryParams = {},
  excludeQuestionMark = false
}) => {
  // The first parameter in a querystring is preceded by a ?
  // This flag denotes whether we already have one param set in the query string
  let prefixQuestionMark = !excludeQuestionMark;

  return reduce(
    queryParams,
    (acc, field, key) => {
      // If the field is falsey then do not append this query param
      if (!field) { return acc; }

      if (!prefixQuestionMark) {
        return `${ acc }&${ key }=${ field }`;
      }

      prefixQuestionMark = false;
      return `${ acc }?${ key }=${ field }`;
    },
    ''
  );
};