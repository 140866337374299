import { computeTracklistHistoryEpisodes, computeTracklistQueueEpisodes } from '@/helpers/audio';
import { setStatus } from '@/helpers/state';

// Helper reducer function for fetching an episode
export const updateEpisodes = ({
  state,
  episodes,
  status,
  isFullEpisode,
  notFound = false,
  clearExistingEpisodes = false
}) => {
  const episodeStateUpdates = {};
  const { currentEpisodeId } = state;

  episodes.forEach((episode) => {
    const episodeId = episode.id;
    const oldEpisode = (state.episodes[episodeId] || {}).episode || {};
    const shouldUpdateEpisodeObject = (status === 'success') && !notFound;
  
    const newEpisode = shouldUpdateEpisodeObject
      ? {
        ...oldEpisode,
        ...episode
      } : oldEpisode;
  
    episodeStateUpdates[episodeId] = isFullEpisode
      ? {
        ...(state.episodes[episodeId] || {}),
        ...(shouldUpdateEpisodeObject ? { episode: newEpisode } : {}),
        ...setStatus('status', status),
        ...(shouldUpdateEpisodeObject ? { fullEpisode: newEpisode } : {}),
        ...setStatus('fullEpisodeStatus', status, { notFound })
      } : {
        ...(state.episodes[episodeId] || {}),
        ...(shouldUpdateEpisodeObject ? { episode: newEpisode } : {}),
        ...setStatus('status', status)
      };
  });

  return {
    ...state,
    episodes: {
      ...(clearExistingEpisodes ? {} : state.episodes),
      ...(currentEpisodeId ? { [currentEpisodeId]: state.episodes[currentEpisodeId] } : {}), // Do not overwrite currently playing episode
      ...episodeStateUpdates
    }
  };
};

export const overrideAudioSeek = ({ state, seek }) => ({
  ...state,
  seek: seek || 0,
  seekOverrideKey: state.seekOverrideKey + 1,
  resumeFromSeek: seek || 0
});

export const getTracklistIndex = ({ tracklist, episodeId }) => {
  const tracklistIndex = tracklist.findIndex(({ id: tracklistId }) => tracklistId === episodeId);

  if (tracklistIndex >= 0) {
    return tracklistIndex;
  }

  return null;
};

export const getTracklistQueue = ({ state }) => {
  const tracklistEpisodes = state.tracklist.map(({ id }) => {
    const { episode, fullEpisode } = state.episodes[id] || {};

    return fullEpisode || episode;
  }).filter(_ => _);

  const currentTracklistIndex = state.tracklistPosition.index;

  return currentTracklistIndex >= 0
    ? computeTracklistQueueEpisodes({
      episodePreviews: tracklistEpisodes,
      currentIndex: currentTracklistIndex
    })
    : [];
};

export const getTracklistHistory = ({ state }) => {
  const tracklistEpisodes = state.tracklist.map(({ id }) => {
    const { episode, fullEpisode } = state.episodes[id] || {};

    return fullEpisode || episode;
  }).filter(_ => _);

  const currentTracklistIndex = state.tracklistPosition.index;
  const currentEpisodeQueueType = state.currentlyPlayingTrack.playbackType;
  
  return currentTracklistIndex >= 0
    ? computeTracklistHistoryEpisodes({
      episodePreviews: tracklistEpisodes,
      currentIndex: currentEpisodeQueueType === 'custom'
        ? currentTracklistIndex + 1
        : currentTracklistIndex
    })
    : [];
};

export const getEpisodeById = ({ state, id }) => {
  const { episodes } = state;
  const { episode, fullEpisode } = episodes[id] || {};

  return episode || fullEpisode || {};
};