import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { shouldCall } from '@/helpers/api';
import useCallApiSimple from '@/hooks/api/useCallApiSimple';
import useUser from '@/hooks/api/useUser';
import { getEpisodeUserEdge } from '@/state/account/actions';

const useLibraryUserEdge = ({
  episodeId,
  episodeEdges = {},
  shouldCall: shouldCallLibraryEdge = true
} = {}) => {
  const dispatch = useDispatch();

  let edgeStatus = {};
  let edge = {};

  if (episodeEdges && episodeEdges[episodeId]) {
    ({ status: edgeStatus = {}, edge = {} } = episodeEdges[episodeId]);
  }

  const { userStatus } = useUser();

  const handleGetEpisodeUserEdge = useCallback(() => dispatch(getEpisodeUserEdge({ episodeId })), [dispatch, episodeId]);
  
  // API call to get the user episode edge
  // This should only be fired if we haven't already retrieved this info
  useCallApiSimple({
    key: `${ episodeId }-${ edge.id }`,
    shouldRetry: true,
    hasError: edgeStatus.error,
    infiniteRetry: true,
    shouldCall: shouldCallLibraryEdge && episodeId && userStatus.success && shouldCall({ status: edgeStatus }).should,
    call: handleGetEpisodeUserEdge
  });

  return {
    edge,
    edgeStatus
  };
};

export default useLibraryUserEdge;
