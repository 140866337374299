import CONFIG from '@/config/global';
import { callApi } from '@/helpers/api';
import { isBrowser } from '@/helpers/browser';

const { env, api: { HOST, EVENTS_ERROR } } = CONFIG;

// Send an event to notify the API that an error has occured
const sendErrorEvent = ({
  key = '',
  info = '',
  err = {}
}) => {
  return callApi({
    url: HOST(EVENTS_ERROR) + EVENTS_ERROR,
    method: 'POST',
    body: {
      cd: key || '', // Error code - String
      str: err.stack || '', // Stack trace - String
      tsp: Date.now(), // Timestamp - String
      env, // Environment - String
      url: isBrowser() ? window.location.href : '', // Url - String
      msg: err.message || '', // Message - String,
      i: info || '', // Info - String
      p: 'edna'
    }
  });
};

export default sendErrorEvent;