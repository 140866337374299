const initialState = {
  searchFilters: {
    genre: {
      id: 'all',
      value: 'All genres'
    },
    format: {
      id: 'all',
      value: 'All formats'
    }
  },
  textSearchFilter: '',
  keys: {},
  audioQueueSearchKey: ''
};

export default initialState;