import {
  useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import {
  getBrowser,
  isBrowser,
  isDesktopDevice,
  isTabletDevice,
  isTouchDevice
} from '@/helpers/browser';
import useWindowSize from '@/hooks/browser/useWindowSize';
import {
  BP_WIDTH_MEDIUM_DESKTOP,
  BP_WIDTH_MOBILE,
  BP_WIDTH_SMALL_DESKTOP,
  BP_WIDTH_SMALL_MOBILE,
  BP_WIDTH_TABLET
} from '@/config/breakpoints';
import CONFIG from '@/config/global';

const SMALL_MOBILE_KEY = 'small-mobile';
const MOBILE_KEY = 'mobile';
const TABLET_KEY = 'tablet';
const SMALL_DESKTOP_KEY = 'small-desktop';
const MEDIUM_DESKTOP_KEY = 'medium-desktop';
const LARGE_DESKTOP_KEY = 'large-desktop';

const getBrowserSizeDetails = (browserSize) => {
  const details = {
    isSmallMobile: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isSmallDesktop: false,
    isMediumDesktop: false,
    isLargeDesktop: false
  };

  if (browserSize === SMALL_MOBILE_KEY) {
    details.isMobile = true;
    details.isSmallMobile = true;
  } else if (browserSize === MOBILE_KEY) {
    details.isMobile = true;
  } else if (browserSize === TABLET_KEY) {
    details.isTablet = true;
  } else {
    details.isDesktop = true;

    if (browserSize === SMALL_DESKTOP_KEY) {
      details.isSmallDesktop = true;
    } else if (browserSize === MEDIUM_DESKTOP_KEY) {
      details.isMediumDesktop = true;
    } else if (browserSize === LARGE_DESKTOP_KEY) {
      details.isLargeDesktop = true;
    }
  }

  return details;
};

const AppLayoutProvider = ({ children }) => {
  const inBrowserMode = isBrowser();
  const { width, height } = useWindowSize({ debounceTime: CONFIG.layoutShiftDebounceMs });

  const [browser, setBrowser] = useState(null);
  const [browserType, setBrowserType] = useState(null);
  const [desktopDevice, setDesktopDevice] = useState(null);
  const [tabletDevice, setTabletDevice] = useState(null);
  const [touchDevice, setTouchDevice] = useState(null);
  const [browserSize, setBrowserSize] = useState(null);
  const [updater, setUpdater] = useState({});

  useEffect(() => {
    if (inBrowserMode) {
      const currentBrowser = getBrowser();
      const currentBrowserType = currentBrowser.platform.type;

      setBrowser(currentBrowser);
      setBrowserType(currentBrowserType);
      setDesktopDevice(isDesktopDevice());
      setTabletDevice(isTabletDevice());
      setTouchDevice(isTouchDevice());

      if (width <= BP_WIDTH_SMALL_MOBILE) {
        setBrowserSize(SMALL_MOBILE_KEY);
      } else if (width <= BP_WIDTH_MOBILE) {
        setBrowserSize(MOBILE_KEY);
      } else if (width <= BP_WIDTH_TABLET) {
        setBrowserSize(TABLET_KEY);
      } else if (width <= BP_WIDTH_SMALL_DESKTOP) {
        setBrowserSize(SMALL_DESKTOP_KEY);
      } else if (width <= BP_WIDTH_MEDIUM_DESKTOP) {
        setBrowserSize(MEDIUM_DESKTOP_KEY);
      } else {
        setBrowserSize(LARGE_DESKTOP_KEY);
      }

      setUpdater({});
    }
  }, [inBrowserMode, width, height, browserSize]);

  const value = useMemo(() => ({
    width,
    height,
    browser,
    browserType,
    desktopDevice,
    touchDevice,
    tabletDevice,
    browserSize,
    ...getBrowserSizeDetails(browserSize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [updater]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

AppLayoutProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default AppLayoutProvider;