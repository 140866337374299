// Config of options for the account page
export const featuresConfig = {
  autoplay: {
    title: 'Auto play',
    key: 'au'
  },
  notify_favourite_channels: {
    title: 'Favourite channel notifications',
    key: 'nfc'
  },
  notify_new_recommendations: {
    title: 'Story notifications',
    key: 'nnr'
  },
  tailored_ads: {
    title: 'Tailored adverts',
    key: 'ta'
  },
  omnio: {
    title: 'Omnio optimisation',
    key: 'om'
  },
  data_saving: {
    title: 'Data saving',
    key: 'ds'
  }
};