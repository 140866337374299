import { getProcessEnv } from '../../helpers/env';
import API_CONFIG from './api.js';
import ROUTES_CONFIG from './routes.js';
import AUDIO_CONFIG from './audio.js';
import LOCAL_STORAGE_CONFIG from './localStorage.js';
import { DEFAULT_PORT } from './urls';
import { defaultsDeep } from '@/helpers/lodash';

const {
  DISABLE_AUTHENTICATION,
  ENABLE_TRACK_SEEK,
  FB_APP_ID,
  FB_PAGE_ID,
  APPLE_CLIENT_ID
} = getProcessEnv();
const { NODE_ENV } = process.env;

const CONFIG = {
  base: {
    defaultPort: DEFAULT_PORT,
    appName: 'Keakie',
    logoUrl: 'https://www.keakie.com/static/icons/android-chrome-256x256.png',
    facebookAppId: FB_APP_ID,
    facebookPageId: FB_PAGE_ID,
    appleClientId: 'com.keakie.edna.v2.web-app.client',
    // Toggle features
    enableFullscreenMode: false,
    disableRightClickGlobally: false,
    disableAllGenresOnMobile: true,
    disableTrackSeek: !ENABLE_TRACK_SEEK,
    enableAppleLogin: true,
    disableAccountFeatures: true,
    enableMultiLanguageSupport: true,
    // Parameters
    notificationDuration: 2500,
    debouncedReduxActionDelay: 1000,
    layoutShiftDebounceMs: 0,
    search: { minimumSearchCharacters: 3 },
    snapSeekBackToTracklistThresholdSeconds: 15,
    snapSeekForwardToTracklistThresholdSeconds: 10
  },
  development: {
    env: 'development',
    intercomAppId: 'c2yxq8zh',
    // Toggle features
    allowAppUsageWithoutLogin: DISABLE_AUTHENTICATION,
    enableAppleLogin: true
  },
  production: {
    env: 'production',
    intercomAppId: 'yexou83n',
    // Toggle features
    allowAppUsageWithoutLogin: false,
    disableRightClickGlobally: true,
    enableAppleLogin: true
  }
};

// Merge the base config with that of the current NODE_ENV value,
// as well as the routes and api config
const ENV_CONFIG = defaultsDeep(
  defaultsDeep(
    CONFIG[NODE_ENV],
    CONFIG.base
  ),
  {
    api: API_CONFIG,
    routes: ROUTES_CONFIG,
    audio: AUDIO_CONFIG,
    localStorage: LOCAL_STORAGE_CONFIG
  }
);

export default ENV_CONFIG;