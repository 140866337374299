import {
  useCallback, useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import CONFIG from '@/config/global';

const { allowAppUsageWithoutLogin } = CONFIG;

const ConnectivityProvider = ({ children }) => {
  const [online, setOnline] = useState(false);
  const onlineRef = useRef(false);

  const handleUpdateOnlineStatus = useCallback(() => {
    setOnline(navigator.onLine);
    onlineRef.current = navigator.onLine;
  }, [onlineRef]);

  useEffect(() => {
    // Update the online status icon based on connectivity
    window.addEventListener('online', handleUpdateOnlineStatus);
    window.addEventListener('offline', handleUpdateOnlineStatus);
    
    handleUpdateOnlineStatus();

    return () => {
      window.removeEventListener('online', handleUpdateOnlineStatus);
      window.removeEventListener('offline', handleUpdateOnlineStatus);
    };
  }, [handleUpdateOnlineStatus]);

  const value = useMemo(() => ({
    online: !allowAppUsageWithoutLogin ? online : true,
    onlineRef
  }), [online, onlineRef]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

ConnectivityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default ConnectivityProvider;