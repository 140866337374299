import PropTypes from 'prop-types';
import React from 'react';

const Span = React.forwardRef(({ enableSEOSnippet, ...props }, ref) => (
  <span
    ref={ ref }
    { ...props }
    { ...(enableSEOSnippet ? {} : { 'data-nosnippet': '' }) }
  />
));

Span.propTypes = { enableSEOSnippet: PropTypes.bool };

Span.defaultProps = { enableSEOSnippet: false };

export default Span;