import shakaPlayerConfig from '../shaka-player';
import { defaultsDeep } from '@/helpers/lodash';
import { getProcessEnv } from '@/helpers/env.mjs';

const { NODE_ENV } = getProcessEnv();

// Config for shaka player streaming. There are two settings, short and long.
// Long mode buffers content further in advance and activates when the user streams an episode for a certain amount of time
const productionStreamingConfig = {
  short: {
    bufferingGoal: 300,
    rebufferingGoal: 1,
    bufferBehind: 300
  },
  long: {
    bufferingGoal: 600,
    rebufferingGoal: 1,
    bufferBehind: 600
  }
};

const AUDIO_CONFIG = {
  base: { shakaPlayerConfig },
  development: {},
  production: {
    shakaPlayerConfig: {
      ...shakaPlayerConfig,
      streaming: {
        ...shakaPlayerConfig.streaming,
        ...productionStreamingConfig.short
      }
    }
  }
};

// Merge the base config with that of the current NODE_ENV value
const ENV_AUDIO_CONFIG = defaultsDeep(
  AUDIO_CONFIG[NODE_ENV],
  AUDIO_CONFIG.base
);

export default ENV_AUDIO_CONFIG;