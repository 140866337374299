import {
  useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import { isBrowser } from '@/helpers/browser';

const InitialRenderProvider = ({ children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const inBrowserMode = isBrowser();

  useEffect(() => {
    if (inBrowserMode && isFirstRender) {
      setIsFirstRender(false);
    }
  }, [inBrowserMode, isFirstRender]);

  const value = useMemo(() => ({ isFirstRender }), [isFirstRender]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

InitialRenderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default InitialRenderProvider;