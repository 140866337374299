import {
  stringContainsOther,
  combineWordsIntoListString,
  combineSentences,
  addFullStopIfNoPunctionationAtEnd
} from './common';
import CONFIG from '@/config/global';
import { generateSeasonEpisodeString } from '@/helpers/pages/channel';

const createHosts = ({ filterText = '', hosts } = {}) => {
  const hostsList = (hosts || [])
    .filter((host) => (host || '').trim());
  const hostsListFiltered = hostsList
    .filter((host) => !stringContainsOther(filterText, host)); // Only include hosts which aren't already in the description
  const hostsExist = !!hostsListFiltered.length;
  
  const hostsListText = combineWordsIntoListString({
    words: hostsList,
    useConnective: true
  });
  const hostsListFilteredText = combineWordsIntoListString({
    words: hostsListFiltered,
    useConnective: true
  });

  const filterTextContainedAHost = hostsListFiltered.length !== hostsList.length;

  return {
    hostsExist,
    filterTextContainedAHost,
    filterTextContainedAllHosts: filterTextContainedAHost && (hostsListFiltered.length === 0),
    hostsList,
    hostsListText,
    hostsListFiltered,
    hostsListFilteredText
  };
};

export const createShowPageTitle = ({
  show,
  episode,
  addHosts = false
} = {}) => {
  const { title, hosts } = show || {};

  const formattedTitle = (title || '').trim();

  if (!formattedTitle) { return ''; }

  const {
    hostsList,
    hostsListText,
    filterTextContainedAHost: hostsAreMentionedInTitle,
    filterTextContainedAllHosts: allHostsAreMentionedInTitle
  } = createHosts({
    hosts,
    filterText: title
  });

  const hostedByText = (
    hostsListText
      && addHosts
      && (!hostsAreMentionedInTitle || (allHostsAreMentionedInTitle ? false : (hostsList.length > 1)))
  )
    ? ` - Hosted by ${ hostsListText }`
    : '';

  const showPageTitle = `${ formattedTitle } on ${ CONFIG.appName }${ hostedByText }`;

  if (episode) {
    const seasonEpisodeText = generateSeasonEpisodeString({ episode });

    return `${ seasonEpisodeText ? `${ seasonEpisodeText } from ` : '' }${ showPageTitle }`;
  }

  return showPageTitle;
};

const createHostsDescriptionText = ({ description = '', hosts = [] } = {}) => {
  const {
    hostsList,
    hostsListText,
    filterTextContainedAHost: hostsAreMentionedInDescription,
    filterTextContainedAllHosts: allHostsAreMentionedInDescription
  } = createHosts({
    filterText: description,
    hosts
  });

  const hostsDescription = (hostsListText
    && (!hostsAreMentionedInDescription || (allHostsAreMentionedInDescription ? false : (hostsList.length > 1))))
    ? `Hosted by ${ hostsListText }.`
    : '';

  return hostsDescription;
};

const createGuestsDescriptionText = ({ description, guests } = {}) => {
  // Guests
  const guestList = (guests || [])
    .filter((guest) => (guest || '').trim());
  const guestListFiltered = guestList
    .filter((guest) => !stringContainsOther(description, guest)); // Only include guests which aren't already in the description
  const guestsExist = !!guestListFiltered.length;
  const guestsListText = combineWordsIntoListString({
    words: guestListFiltered,
    useConnective: true
  });
  const guestsAreMentionedInDescription = guestListFiltered.length !== guestList.length;
  const guestWord = guestListFiltered.length > 1 ? 'guests' : 'guest';
  const specialGuestsText = guestsAreMentionedInDescription
    ? `Also with special ${ guestWord }`
    : `With special ${ guestWord }`;
  const guestsDescription = guestsExist
    ? `${ specialGuestsText } ${ guestsListText }.`
    : '';

  return guestsDescription;
};

const createGenresDescriptionText = ({
  description,
  genres,
  prefix = 'Expect to hear'
} = {}) => {
  if (!description) {
    return '';
  }

  const genreTitlesList = (genres || [])
    .map((genre) => genre.title)
    .filter((title) => (title || '').trim());
  const genreTitlesListFiltered = genreTitlesList
    .filter((title) => !stringContainsOther(description, title)); // Only include genres which aren't already in the description
  const genreTitlesExist = !!genreTitlesListFiltered.length;
  const genreTitlesListText = combineWordsIntoListString({
    words: genreTitlesListFiltered,
    useConnective: true
  });
  const genresAreMentionedInDescription = genreTitlesListFiltered.length !== genreTitlesList.length;
  const expectToHearText = genresAreMentionedInDescription
    ? `${ prefix } ` // In case we want to change the prefix when at least one genre is mentioned in the description
    : `${ prefix } `;
  const genresDescription = genreTitlesExist
    ? `${ expectToHearText }${ genreTitlesListText }.`
    : '';

  return genresDescription;
};

const createLocationDescriptionText = ({ description, origin } = {}) => {
  const originFormatted = (origin || '').trim();

  if (!originFormatted) {
    return '';
  }

  const originIsMentionedInDescription = stringContainsOther(description, originFormatted);
  const locationDescription = originIsMentionedInDescription
    ? ''
    : `Streaming from ${ originFormatted }.`;

  return locationDescription;
};

export const createShowPageDescription = ({
  show,
  episode,
  augmentDescription = false
} = {}) => {
  const {
    genres,
    hosts,
    origin,
    description: showDescription,
    format
  } = show || {};

  const isPodcast = format === 'podcast';
  const {
    guest: guests,
    description: episodeDescription
  } = episode || {};
  const description = (episodeDescription || showDescription || '').trim();

  if (!description) {
    return '';
  }
  
  const formattedDescription = addFullStopIfNoPunctionationAtEnd(description);

  if (!augmentDescription) {
    return formattedDescription;
  }

  // Hosts - only show for show links
  const hostsDescriptionText = createHostsDescriptionText({
    description: formattedDescription,
    hosts
  });
  // Guests - only show for episode links
  const guestsDescriptionText = createGuestsDescriptionText({
    description: formattedDescription,
    guests
  });
  // Location - only show for show links
  const locationDescriptionText = createLocationDescriptionText({
    description: formattedDescription,
    origin
  });
  // Genres - show for both show and episode links
  const genresDescriptionText = createGenresDescriptionText({
    description: formattedDescription,
    genres,
    prefix: isPodcast ? 'Expect topics around' : undefined
  });

  const combinedDescription = combineSentences([
    formattedDescription,
    hostsDescriptionText,
    guestsDescriptionText,
    locationDescriptionText,
    genresDescriptionText
  ]);

  return combinedDescription;
};

export const createImageAltTextForShow = ({ show } = {}) => {
  const { title, hosts } = show || {};

  if (!(title || '').trim()) {
    return '';
  }

  const formattedTitle = addFullStopIfNoPunctionationAtEnd(title);
  const hostsDescriptionText = createHostsDescriptionText({ hosts });

  return combineSentences([
    formattedTitle,
    hostsDescriptionText
  ]);
};