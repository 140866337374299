// These funtions handle fetching/success/failure status in state.
// The structure is standardised for easy re-use when using
// actions for async tasks such as API calls

const defaultStatusObject = {
  loading: false,
  success: false,
  error: false,
  notFound: false
};

const loadingStatusObject = {
  loading: true,
  success: false,
  error: false,
  notFound: false
};

const successStatusObject = {
  loading: false,
  success: true,
  error: false,
  notFound: false
};

const failureStatusObject = {
  loading: false,
  success: false,
  error: true,
  notFound: false
};

// Return the initial status object
export const initialStatusObject = (options = {}) => ({
  ...defaultStatusObject,
  ...options
});

// Check whether a status object is in it's default state
export const isDefaultStatus = (status) => {
  const {
    loading,
    success,
    error,
    notFound
  } = status;

  return !loading && !success && !error && !notFound;
};

// Return the status object for a FETCHING action
export const setStatusLoading = (key) => ({ [key]: loadingStatusObject });

// Return the status object for a SUCCESS action
export const setStatusSuccess = (key) => ({ [key]: successStatusObject });

// Return the status object for a FAILURE action
export const setStatusFailure = (key) => ({ [key]: failureStatusObject });

// Return a status object for a given key and status
export const setStatus = (key = null, status, options = {}) => {
  let newStatusObject = null;

  switch (status) {
    case 'loading': {
      newStatusObject = {
        ...loadingStatusObject,
        ...options
      };
      break;
    }
    case 'success': {
      newStatusObject = {
        ...successStatusObject,
        ...options
      };
      break;
    }
    case 'failure': {
      newStatusObject = {
        ...failureStatusObject,
        ...options
      };
      break;
    }
    default:
      newStatusObject = initialStatusObject(options);
  }

  if (!key) {
    return newStatusObject;
  }

  return { [key]: newStatusObject };
};

// Conditionally call an action only if two values are different.
// This avoids dispatching actions if the state is already the same as the new value
export const callActionIfChanged = (action, newVal, oldVal) => {
  if (newVal !== oldVal) {
    action();
  }
};