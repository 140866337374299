// PAGE VIEWS
export const GA_ACT_VIEWED_PAGE_DISCOVER = 'page_viewed_discover';
export const GA_ACT_VIEWED_PAGE_LIBRARY = 'page_viewed_library';
export const GA_ACT_VIEWED_PAGE_SHOW = 'page_viewed_show';
export const GA_ACT_VIEWED_PAGE_GENRE = 'page_viewed_genre';

// AUDIO
export const GA_ACT_STREAMED_EPISODE_START = 'streamed_episode_start';
export const GA_ACT_STREAMED_EPISODE = 'streamed_episode';
export const GA_ACT_STREAMED_GENRE_START = 'streamed_genre_start';
export const GA_ACT_STREAMED_GENRE = 'streamed_genre';
export const GA_ACT_USER_SEEKED_AUDIO = 'user_seeked_audio';
export const GA_ACT_STREAMED_TRACK = 'streamed_track';

// SEARCH
export const GA_ACT_PERFORMED_SEARCH = 'searched';

// AUTH
export const GA_ACT_LOGGED_IN = 'logged_in';
export const GA_ACT_LOGGED_OUT = 'logged_out';

// NAVIGATION
export const GA_ACT_OPENED_GENRES_DROPDOWN = 'opened_genres_dropdown';
export const GA_ACT_CLOSED_GENRES_DROPDOWN = 'closed_genres_dropdown';

// FEATURE ENGAGEMENT
export const GA_ACT_PLAYED_STORY = 'played_story';
export const GA_ACT_CLICKED_STORY = 'clicked_story';