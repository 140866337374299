import { mapValues } from '@/helpers/lodash';
import { initialStatusObject } from '@/helpers/state';
import { featuresConfig } from '@/config/account';

const initialState = {
  user: null,
  userStatus: initialStatusObject(),
  featuresStatus: mapValues(featuresConfig, () => initialStatusObject()),
  keakieCheckLoginStatus: initialStatusObject(),
  externalLoginStatus: initialStatusObject(),
  keakieCreateLoginStatus: initialStatusObject(),
  login: {
    type: null,
    loggedIn: false,
    logoutLoading: false,
    info: {}
  },
  episodeEdges: {},
  showEdges: {}
};

export default initialState;