import sortBy from 'lodash.sortby';
import { truthyOrZero } from './math';
import { formatTimedRanges } from '@/helpers/utility';
import { getProcessEnv } from '@/helpers/env';
import {
  isBrowser, isIOS, satisfiesBrowser
} from '@/helpers/browser';

const { NODE_ENV, IP } = getProcessEnv();

export const importShakaDynamically = async () => {
  const shaka = await (isBrowser() ? import('shaka-player') : Promise.resolve({}));

  return shaka;
};

let shakaPlayer;

const loadShaka = async () => {
  shakaPlayer = await importShakaDynamically();
  return shakaPlayer;
};

shakaPlayer = await loadShaka();

export const shaka = shakaPlayer;

export const shakaStreamingSupported = () => {
  // CHROME ENFORCES HTTPS FOR MEDIA STREAMING UNLESS ON LOCALHOST
  // See: https://shaka-player-demo.appspot.com/docs/api/tutorial-drm-config.html
  // To support running dev mode over a local IP, add an extra allowance
  // when in dev mode.

  if (!isBrowser()) {
    return false;
  }

  const browserIsChromeV23 = satisfiesBrowser({ chrome: '>23' });
  const isDevelopmentMode = (NODE_ENV === 'development');
  const isLocalIP = IP === 'localhost';
  const isSupported = shakaPlayer.Player ? shakaPlayer.Player.isBrowserSupported() : false;

  return (isDevelopmentMode || isLocalIP)
    ? (isSupported || browserIsChromeV23)
    : isSupported;
};

// Check whether the browser supports native HLS
export const nativeHlsIsSupported = () => {
  const isSupported = isBrowser()
    ? document.createElement('video').canPlayType('application/vnd.apple.mpegURL')
    : null;

  return (isSupported === 'maybe')
    || (isSupported === 'probably');
};

// Check whether we should use native HLS streaming instead of shaka player
export const shouldUseNativeHls = () => (
  shakaStreamingSupported()
    ? false
    : nativeHlsIsSupported()
);

// Choose the HLS url if we are using native HLS playback
// OR the user is on an iOS device
export const shouldUseHlsUrl = () => {
  return (
    isBrowser()
      && (
        shakaStreamingSupported()
          ? isIOS()
          : nativeHlsIsSupported()
      )
  );
};

export const audioSupported = () => {
  return (
    isBrowser()
      && (
        shakaStreamingSupported()
        || shouldUseNativeHls()
      )
  );
};

// Extract the duration of an episode.
// Use the audio tag as the primary source and use the episode as the secondary source
export const calculateEpisodeDuration = ({ episode = null, audioRef = null }) => {
  return audioRef?.current?.duration
    || episode?.duration
    || 0;
};

export const computeTracklistQueueEpisodes = ({
  episodePreviews,
  currentIndex
}) => episodePreviews.slice(currentIndex + 1);

export const computeTracklistHistoryEpisodes = ({
  episodePreviews,
  currentIndex
}) => episodePreviews.slice(0, currentIndex);

export const extractManifestUrlsFromEpisode = (episode) => {
  const streams = (episode || {}).streams || [];

  // DASH STREAMING (For all browsers/devices except for iOS)
  // ------------------------------------------------------
  const dash = streams.find((s) => (s.protocol || '').toLowerCase() === 'dash');
  const dashUrl = dash ? dash.url : null;

  // HLS STREAMING (For all iOS devices or devices with HLS that don't support shaka)
  // ------------------------------------------------------
  const hls = streams.find((s) => (s.protocol || '').toLowerCase() === 'hls');
  const hlsUrl = hls ? hls.url : null;

  return {
    hls: hlsUrl,
    dash: dashUrl
  };
};

export const seekHasPassedThresholdForPreviousTrack = (seek) => seek >= 5;

// Extract the streamed timed ranges from an audio element
// TimeRanges is an array of non overlapping time periods (in seconds) which the user has listened to
// https://developer.mozilla.org/en-US/docs/Web/API/TimeRanges
export const extractStreamedTimeRanges = ({ mediaElement }) => {
  // Convert TimeRanges from the audio ref into the format we want
  const streamedTimeRanges = formatTimedRanges(mediaElement.played);
  // Calculate the total streaming time by adding the length (in seconds) of all streamed segments
  const totalStreamingTimeSeconds = streamedTimeRanges.reduce((total, tr) => (total + (tr.e - tr.s)), 0);

  const { duration } = mediaElement;
  const percentageProgress = Number(((totalStreamingTimeSeconds / duration) * 100).toFixed(2));

  return {
    streamedTimeRanges,
    totalStreamingTimeSeconds,
    percentageProgress
  };
};

export const getTrackTextForTrack = ({ track } = {}) => {
  if (track) {
    const { title, artist } = track;

    return `${ title || '' }${ (title && artist) ? ' - ' : '' }${ artist || '' }`;
  }

  return '';
};

export const getArtistAndTitleForTrack = ({ track } = {}) => {
  let title = '';
  let artist = '';

  if (track) {
    title = (track.title || '').trim();
    artist = (track.artist || '').trim();
  }

  return {
    title,
    artist
  };
};

export const getTrackFromTracklistByTime = ({ episode, timeSeconds }) => {
  const tracklist = (episode || {}).tracklist || [];

  const matchingTracks = sortBy(
    tracklist.filter((t) => (
      (timeSeconds >= t.start_time)
      && (timeSeconds < t.end_time)
    )),
    (t) => t.start_time
  );

  let track;
  let trackIndex;

  if (matchingTracks.length) {
    track = matchingTracks[matchingTracks.length - 1];
    trackIndex = tracklist.findIndex((t) => (
      t.end_time === track.end_time
        && t.start_time === track.start_time
        && t.title === track.title
        && t.artist === track.artist
    ));
  }

  const trackText = getTrackTextForTrack({ track });

  return {
    track,
    trackText,
    isHidden: track ? track.is_hidden : false,
    trackIndex: track ? trackIndex : undefined
  };
};

export const getTrackFromTracklistByIndex = ({ episode, trackIndex } = {}) => {
  const tracklist = sortBy(((episode || {}).tracklist || []), (t) => t.start_time);

  let track;
  let trackText = '';

  if (truthyOrZero(trackIndex) && trackIndex !== -1) {
    const foundTrack = tracklist[trackIndex];

    if (foundTrack) {
      track = foundTrack;
      trackText = getTrackTextForTrack({ track });
    }
  }

  return {
    track,
    trackText
  };
};

export const getNextTrackFromTracklist = ({ episode, currentTrackIndex } = {}) => {
  const nextTrack = getTrackFromTracklistByIndex({
    episode,
    trackIndex: currentTrackIndex + 1
  });

  return nextTrack;
};