import { createRoot } from 'react-dom/client';
import Text from '@/components/component-library/Text';

// Counter to create a unique id for each tooltip that is shown
let tooltipCount = 0;
// Duration to keep each tooltip open
const tooltipDuration = 2000;
const borderHighlightDurationMs = 1500;
// Duration for the animation transitioning in and out
const transitionDuration = 0.3;
const transitionDurationMs = transitionDuration * 1000;

// Creates a handler to show a tooltip
export const createHandleTooltip = ({
  text
  // e, // Event - if this is passed it can be used to retrieve the element to display the tooltip over
  // tooltipElement // Element to display the tooltip over.
}) => () => {
  // Iterate the tooltip count to maintain unique tooltip ids
  tooltipCount += 1;
  const tooltipId = `tooltip-${ tooltipCount }`;

  // Create a container in the document for the tooltip
  const container = document.createElement('div');
  container.id = `${ tooltipId }-container`;
  const root = createRoot(container);
  document.body.appendChild(container);

  // Create a handler to remove the tooltip
  const removeTooltip = () => {
    // Find the tooltip element by id
    const element = document.getElementById(tooltipId);

    // Transition the opacity and remove the tooltip after the animation completes
    if (element) {
      element.style.opacity = 0;

      setTimeout(() => {
        root.unmount();
        container.remove();
      }, transitionDurationMs);
    }
  };

  // FUTURE: Mount the tooltip at a position relative to the 'tooltipElementToUse' dom node
  // When choosing where to display the tooltip, display at:
  // (1) - The passed element
  // (2) - The target of the passed event
  // (3) - Default position
  // const tooltipElementToUse = tooltipElement || (e || {}).target || null;

  // Render the tooltip with it's id
  root.render(
    <div
      className='tooltip'
      id={ tooltipId }
      style={ { transition: `all ${ transitionDuration }s` } }
    >
      <Text fixed>{ text }</Text>
    </div>
  );

  // Set timeouts for fading in the opacity of the tooltip and removing it
  setTimeout(() => {
    const element = document.getElementById(tooltipId);
    element.style.opacity = 1;

    setTimeout(() => {
      element.style.border = '1px solid rgba(0, 0, 0, 0)';
    }, borderHighlightDurationMs);
  }, 50);

  setTimeout(() => {
    removeTooltip();
  }, tooltipDuration);
};