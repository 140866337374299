import isObject from 'lodash.isobject';
import { reduce } from '@/helpers/lodash';

const appendClass = (className, newClass) => {
  return `${ className }${ (className && newClass) ? ' ' : '' }${ newClass }`;
};

// Merges all class arguments into one class string.
// Arguments can be a string or an object { 'a-class-name-here': true/false }
export const classes = (...args) => {
  return reduce(args, (compositeClassName, arg = '') => {
    let classArg = arg || '';

    // If the argument is an object, we need to extract the values
    if (isObject(classArg)) {
      classArg = reduce(arg, (compositeClassNameFromObjects, classEnabled, className) => {
        return classEnabled
          ? appendClass(compositeClassNameFromObjects, className)
          : compositeClassNameFromObjects;
      }, '');
    }
    
    return appendClass(compositeClassName, classArg);
  }, '');
};