import { addZeroes } from '@/helpers/utility';

// Convert milliseconds to seconds
export const convertMsToS = (timeMs = 0) => {
  return Number((timeMs / 1000).toFixed(2));
};

// Converts a time in seconds to other formats
export const getTimeFromSeconds = (duration = 0) => {
  const durationInMinutes = (duration === 0)
    ? 0
    : Math.floor(duration / 60);

  const hours = (durationInMinutes > 59)
    ? Math.floor(durationInMinutes / 60)
    : 0;

  const minutes = durationInMinutes % 60;
  const minutesWith2Digits = addZeroes(minutes, 2);

  const seconds = addZeroes(Math.floor(duration % 60), 2);
  
  return {
    hours,
    minutes: minutesWith2Digits,
    seconds,
    timeString: `${ hours ? `${ hours }:` : '' }${ minutesWith2Digits }:${ seconds }`,
    timeStringMinutes: `${ durationInMinutes }m`
  };
};

// Converts a hexadecimal colour into an rgba
export const hexToRGB = (hex, alpha = 1, asPercentage = false) => {
  const digits = hex.slice(1);
  const hexFull = (digits.length === 3)
    ? `#${ digits.split('').map((digit) => `${ digit }${ digit }`).join('') }`
    : hex;

  const r = parseInt(hexFull.slice(1, 3), 16);
  const g = parseInt(hexFull.slice(3, 5), 16);
  const b = parseInt(hexFull.slice(5, 7), 16);

  return `rgb(${ r } ${ g } ${ b } / ${ asPercentage ? `${ alpha }%` : alpha })`;
};

// Returns true if a value is either truthy OR zero
export const truthyOrZero = (value) => {
  return !!value || (value === 0);
};

export const truthyOrZeroOrEmptyString = (value) => (truthyOrZero(value) || value === '');

// Returns true if the number equivalents of two parameters are equal.
// This is useful if we are potentially comparing strings to numbers
export const isEqualAsNumber = (a, b) => {
  return Number(a) === Number(b);
};

export const calculatePercentage = ({ numerator, denominator } = {}) => {
  return (100 / (parseFloat(denominator) || 1)) * (parseFloat(numerator) || 0);
};