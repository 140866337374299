import Bowser from 'bowser';
import isTouch from 'is-touch-device';
import { getProcessEnv } from './env';

const { NODE_ENV } = getProcessEnv();
const isProduction = (NODE_ENV === 'production');

// Returns true if the current environment is the browser
// This is used to stop certain actions being run on the server
export const isBrowser = () => !!(
  (typeof window !== 'undefined')
    && (window.document && window.document.createElement)
);

const defaultBrowserParser = isBrowser()
  ? Bowser.getParser(window.navigator.userAgent)
  : null;

// Returns the current browser parser from bowser
export const getBrowserParser = (ua) => {
  return (ua ? Bowser.getParser(ua) : defaultBrowserParser) || null;
};

// Returns the current browser information as an object
// See https://github.com/lancedikson/bowser
export const getBrowser = (ua) => {
  const browserParser = getBrowserParser(ua);

  return browserParser
    ? browserParser.getResult()
    : {};
};

// Uses a test object to decide if the browser meets certain conditions
// See https://github.com/lancedikson/bowser
export const satisfiesBrowser = (testObj, ua) => {
  const browserParser = getBrowserParser(ua);

  return browserParser
    ? browserParser.satisfies(testObj)
    : false;
};

// Returns true if the device is a desktop device
export const isDesktopDevice = () => {
  const browser = getBrowser();
  const platformType = browser?.platform?.type;

  return platformType ? (platformType === 'desktop') : null;
};

export const isTouchDevice = () => {
  return isTouch();
};

export const isSafari = () => {
  return satisfiesBrowser({ safari: '>=0' });
};

export const isDesktopSafari = () => {
  return isDesktopDevice() && isSafari();
};

// Returns true if the user is on macOS.
export const isMacOS = () => {
  const browserParser = getBrowserParser();

  return browserParser
    ? (browserParser.getOSName() === 'macOS')
    : false;
};

// Returns true if the user is on iOS. This is used for disabling/tweaking certain
// features. For example, the audio playback mechanism is different on iOS
export const isIOS = (ua) => {
  const browserParser = getBrowserParser(ua);

  return browserParser
    ? (browserParser.getOSName() === 'iOS')
    : false;
};

// Returns true if the user is on Android. This is used for disabling/tweaking certain
// features. For example, when displaying an android app download link only to android users.
export const isAndroid = (ua) => {
  const browserParser = getBrowserParser(ua);

  return browserParser
    ? (browserParser.getOSName() === 'Android')
    : false;
};

// Returns true if the device is a tablet device
export const isTabletDevice = () => {
  const browser = getBrowser();
  const platformType = browser?.platform?.type;

  return platformType
    ? ((platformType === 'tablet') || (isTouchDevice() && isMacOS()))
    : null;
};

export const isTabletIOS = () => {
  return isTabletDevice() && isMacOS();
};

export const isIOSWebView = () => {
  if (!isBrowser() || !isIOS()) { return false; }

  const standalone = window?.navigator?.standalone;

  return !standalone && !isSafari();
};

export const getWidth = () => {
  if (!isBrowser()) {
    return 0;
  }

  return (isIOS() ? 0 : window?.innerWidth)
    || document?.documentElement?.clientWidth
    || document?.body?.clientWidth
    || 0;
};

export const getHeight = () => {
  if (!isBrowser()) {
    return 0;
  }

  return (
    (isIOS() ? 0 : window?.innerHeight)
      || document?.documentElement?.clientHeight
      || document?.body?.clientHeight
      || 0
  );
};

// Returns the width and height of the window
export const getWindowSizes = () => {
  if (!isBrowser()) {
    return {
      width: 0,
      height: 0,
      screenHeight: 0,
      screenWidth: 0
    };
  }

  return {
    width: getWidth(),
    height: getHeight(),
    screenHeight: window.screen.height,
    screenWidth: window.screen.width
  };
};

export const activateQuantcastTag = () => {
  if (isProduction && isBrowser()) {
    // eslint-disable-next-line
    window._qevents = window._qevents || [];
    const elem = document.createElement('script');
    elem.src = `${ document.location.protocol === 'https:' ? 'https://secure' : 'http://edge' }.quantserve.com/quant.js`;
    elem.async = true;
    elem.type = 'text/javascript';
    const scpt = document.getElementsByTagName('script')[0];
    scpt.parentNode.insertBefore(elem, scpt);
  
    // eslint-disable-next-line
    window._qevents.push({
      qacct: 'p-KpV2zKBP2-9bK' });
  }
};

export const intersectionObserverSupported = () => isBrowser()
  && 'IntersectionObserver' in window
  && 'IntersectionObserverEntry' in window
  && 'intersectionRatio' in window.IntersectionObserverEntry.prototype;

export const smoothScrollIsSupported = () => isBrowser()
  && 'scrollBehavior' in (document?.documentElement?.style || {});

export const shouldAttemptCustomSchemeUrlChange = () => {
  if (!isBrowser()) { return false; }

  return (
    isAndroid()
      || isIOSWebView() // Only attempt in webview on iOS to avoid error message in safari
  );
};