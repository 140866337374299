import {
  useCallback, useEffect, useState
} from 'react';
import CONFIG from '@/config/global';

// Load the apple SDK using a script tag
const setupAppleSDK = ({ onSdkLoaded }) => {
  const id = 'apple-jssdk';
  const element = document.getElementsByTagName('script')[0];
  const firstScriptInDOM = element;
  let js = element;

  // if (document.getElementById(id)) { return; }

  // Add the script tag for loading the Apple SDK
  js = document.createElement('script');
  js.type = 'text/javascript';
  js.id = id;
  js.onload = onSdkLoaded;
  js.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  // Insert the script in the DOM as the first script
  firstScriptInDOM.parentNode.insertBefore(js, firstScriptInDOM);
};

const useAppleLogin = ({ loggedIn = false }) => {
  const [sdkIsLoaded, setSdkIsLoaded] = useState(false);
  const [sdkInitialised, setSdkInitialised] = useState(false);

  // Login is loading if we are loading/initialising the SDK or checking the current login status
  const loading = !sdkInitialised || !sdkIsLoaded;

  // Initialise the apple SDK
  const initialiseAppleSDK = useCallback(() => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: CONFIG.appleClientId,
        scope: 'name email',
        redirectURI: `${ window.location.origin }/api/apple-redirect`,
        responseMode: 'form_post',
        responseType: 'id_token code'
      });
  
      setSdkInitialised(true);
    }
  }, []);

  // Login to apple
  const onLogin = useCallback(() => {
    window.AppleID.auth.signIn({ state: encodeURIComponent(window.location.pathname + window.location.search) });
  }, []);

  // Login to apple
  const onLogout = useCallback(() => {

  }, []);

  // On mounting the provider:
  // 1. Setup the SDK
  // 2. Add the initialisation function for the SDK
  useEffect(() => {
    if (CONFIG.enableAppleLogin) {
      if (!sdkIsLoaded) {
        setupAppleSDK({
          onSdkLoaded: () => {
            setSdkIsLoaded(true);
          }
        });
      } else if (!loggedIn) {
        initialiseAppleSDK();
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, sdkIsLoaded]);

  return {
    sdkIsLoaded,
    onLogin,
    onLogout,
    loading,
    initialiseAppleSDK
  };
};

export default useAppleLogin;