import {
  LARGE,
  TILESMALL,
  screenSizeToAssetKeyMap,
  screenWidths
} from '@/config/assets';
import { isBrowser } from '@/helpers/browser';

// Retrieve browser width config object based on the screen width
export const getBrowserWidthObject = (screenWidth) => {
  if (!screenWidth) { return null; }

  const screenWidthObject = screenWidths.find(
    (sw) => (
      (screenWidth >= sw.range.min)
      && (
        !sw.range.max || (screenWidth <= sw.range.max)
      )
    )
  );

  return screenWidthObject;
};

// Retrieve browser width key (eg XLARGE) based on the screen width
export const getassetSizeForScreenWidth = (screenWidth) => {
  if (!screenWidth) { return ''; }

  return getBrowserWidthObject(screenWidth).key;
};

// Retrieve the asset key (eg hero_xl) based on the screen size and image type
export const getAssetKeyForScreenWidth = ({
  assetSizeKey, imageType, isInitialRender = false
}) => {
  if (!assetSizeKey) { return ''; }

  // Get the device pixel density
  const pixelDensity = (isBrowser() && !isInitialRender)
    ? window.devicePixelRatio
    : 1;

  // Extract the ass
  const asset = screenSizeToAssetKeyMap[imageType][assetSizeKey];

  return {
    key: (pixelDensity >= 2)
      ? (asset.highDensity || asset.default)
      : asset.default
  };
};

export const extractSpecificImageFromAssetObj = ({ assets, size } = {}) => {
  const imageObject = (assets || {})[size] || {};

  // Use jpg over webp
  // In the future when webp is available, we should use webp over jpg
  const image = imageObject.jpg || imageObject.webp || '';

  return image;
};

// Extract the image url from an assets object
export const extractImgFromAssetObj = ({
  assetSizeKey = LARGE, // The asset size eg XLARGE
  assetsObj, // An assets object from the API
  imageType, // The image type eg SQUARE
  useTile = false, // Forces the use of a tile image (low quality)
  forceAssetSizeKey = null, // Forces the use of the image with a certain asset size eg LARGE
  forceHighDensity = false, // Force the use of the high density image
  isInitialRender = false // In order for server and client render to be equal, avoid browser specific image rendering on initial render
}) => {
  // If there is information missing then return an empty string
  if ((!assetSizeKey && !forceAssetSizeKey) || !assetsObj) { return ''; }

  const density = forceHighDensity ? 'highDensity' : 'default';

  let imageObject = null;

  if (useTile) {
    imageObject = assetsObj[screenSizeToAssetKeyMap[imageType][TILESMALL][density]] || {};
  } else if (forceAssetSizeKey) {
    imageObject = assetsObj[screenSizeToAssetKeyMap[imageType][forceAssetSizeKey][density]] || {};
  } else {
    const { key } = getAssetKeyForScreenWidth({
      assetSizeKey,
      imageType,
      isInitialRender
    });

    imageObject = assetsObj[key] || {};
  }

  // Use jpg over webp
  // In the future when webp is available, we should use webp over jpg
  const image = (imageObject.jpg || imageObject.webp || '');

  return { image };
};