import { GA_ACT_LOGGED_IN, GA_ACT_LOGGED_OUT } from '@/config/events-ga/actions';
import { GA_CAT_LOGIN } from '@/config/events-ga/categories';
import { GA_DIM_AUTH_TYPE } from '@/config/events-ga/dimensions';

export const GAloggedIn = ({ type }) => ({
  eventCategory: GA_CAT_LOGIN,
  eventAction: GA_ACT_LOGGED_IN,
  dimensions: { [GA_DIM_AUTH_TYPE]: type }
});

export const GAloggedOut = () => ({
  eventCategory: GA_CAT_LOGIN,
  eventAction: GA_ACT_LOGGED_OUT
});