import PropTypes from 'prop-types';
import Text from '../../Text';
import Spinner from '@/components/component-library/Spinner';
import Link from '@/components/component-library/Link';
import { classes } from '@/helpers/styling';

const ButtonCTA = ({
  children,
  loading,
  disabled,
  style,
  className,
  onClick,
  enableLoadingSpinner,
  isRectangular,
  href,
  ...props
}) => {
  const commonClasses = [
    className,
    'button-cta',
    isRectangular ? '--is-rectangular' : ''
  ];

  const button = (
    <Link
      className={ classes(
        ...commonClasses,
        { '--is-disabled': disabled }
      ) }
      href={ href }
      onClick={ (!disabled && !loading) ? onClick : undefined }
      style={ style }
      { ...props }
    >
      <Text bold className='button-cta-contents' useHeaderFont>
        { children }
      </Text>
    </Link>
  );

  const loadingButton = (
    <div
      className={ classes(
        '--is-loading',
        ...commonClasses
      ) }
      style={ style }
      { ...props }
    >
      { enableLoadingSpinner ? (
        <Spinner
          isCentered
        />
      ) : null }
    </div>
  );

  return loading ? loadingButton : button;
};

ButtonCTA.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  enableLoadingSpinner: PropTypes.bool,
  href: PropTypes.string,
  isRectangular: PropTypes.bool
};

ButtonCTA.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  onClick: undefined,
  style: {},
  enableLoadingSpinner: false,
  href: '',
  isRectangular: false
};

export default ButtonCTA;