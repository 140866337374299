import sortBy from 'lodash.sortby';
import Router from 'next/router';
import { size } from '@/helpers/lodash';
import pageKeys from '@/helpers/routing/constants';
import { truthyOrZero } from '@/helpers/math';
import sendChannelViewEvent from '@/helpers/api/events/sendChannelViewEvent';

// Generates a standard key/id for a season number
// This key is used to store the season episodes in state
export const generateSeasonKey = (seasonNumber) => `season-${ seasonNumber || '1' }`;

export const extractParametersFromUrl = ({ query } = {}) => {
  const slug = query?.channel_slug;
  const episodeNumber = Number(query?.episode_number) || null;
  const seasonNumber = Number(query?.season) || null;

  return {
    slug,
    episodeNumber,
    seasonNumber
  };
};

// Navigates to the given channel and sends a channel view event
export const goToChannel = ({
  channelId,
  channelSlug,
  recommendationId,
  recommendationType
}) => {
  Router.push(pageKeys.shows(channelSlug));

  sendChannelViewEvent({
    channelId,
    recommendationId,
    recommendationType
  }).catch(_ => _);
};

// Navigates to the given channel and episode number and sends a channel view event
export const goToEpisode = ({
  episodeNumber,
  channelId,
  channelSlug,
  seasonNumber,
  recommendationId,
  recommendationType
}) => {
  Router.push(pageKeys.episode(channelSlug, seasonNumber, episodeNumber));

  sendChannelViewEvent({
    channelId,
    recommendationId,
    recommendationType
  }).catch(_ => _);
};

// Checks the query string object from the route for an episode_number field.
// If it exists, then the route is a channel/episode rather than just a channel
export const isEpisodePage = ({ query = {} }) => {
  return Object.prototype.hasOwnProperty.call(query, 'episode_number') || false;
};

export const orderEpisodesForShow = ({ episodes = [] } = {}) => sortBy(episodes, ['season', 'episode_number']);

export const getFirstEpisodeForShow = ({ episodes }) => {
  if (!size(episodes)) { return null; }

  const seasons = episodes.reduce((seasonsAcc, episode) => (seasonsAcc.includes(Number(episode.season))
    ? seasonsAcc
    : [...seasonsAcc, Number(episode.season)]), [])
    .map((season) => Number(season) || 0)
    .sort((a, b) => a - b); // Sort ascending

  let firstEpisode;

  seasons.some((seasonNumber) => {
    const episodesForSeason = episodes.filter((episode) => Number(episode.season) === Number(seasonNumber))
      .sort((a, b) => a.episode_number - b.episode_number); // Sort ascending by episode number

    if (size(episodesForSeason)) {
      [firstEpisode] = episodesForSeason; // Get the first episode
      return true;
    }

    return false;
  });

  return firstEpisode;
};

export const generateSeasonEpisodeString = ({ episode, useFullWords = false } = {}) => {
  const { episode_number, season } = episode || {};

  const seasonPrefix = useFullWords ? 'Season ' : 'S';
  const episodePrefix = useFullWords ? 'Episode ' : 'EP';

  return (truthyOrZero(episode_number) && season) ? `${ seasonPrefix }${ season } ${ episodePrefix }${ episode_number }` : '';
};