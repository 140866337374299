import initialState from './initialState';
import {
  ADD_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION
} from './types';
import { addNotification } from './utility';

const reducer = (state = { ...initialState }, action) => {
  if (!state || !action) { return state; }
  
  const { type: actionType, payload } = action;

  switch (actionType) {
    case ADD_NOTIFICATION: {
      const {
        id,
        title,
        assets,
        type,
        success
      } = payload;

      return addNotification({
        state,
        id,
        title,
        assets,
        type,
        success
      });
    }
    case REMOVE_NOTIFICATION: {
      const { id } = payload;

      return {
        ...state,
        notifications: state.notifications.filter((n) => n.id !== id)
      };
    }
    case CLEAR_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: []
      };
    }
    default:
      return state;
  }
};

export default reducer;