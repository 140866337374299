import {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Provider } from './context';
import { shouldAttemptCustomSchemeUrlChange } from '@/helpers/browser';
import { APP_DOWNLOAD_KEY, APP_DOWNLOAD_PARTNER_PROGRAM_KEY } from '@/config/routeKeys';
import { getRouteObject } from '@/helpers/routing';

const shouldTryDeepLink = shouldAttemptCustomSchemeUrlChange();

const DeepLinksProvider = ({ children }) => {
  const router = useRouter();
  const hasAttemptedDeepLinkOnceRef = useRef(false);
  const { asPath, isReady } = router;
  const routeKey = (getRouteObject(router.asPath) || {}).key;
  const shouldAttemptDeepLink = shouldTryDeepLink
    && (routeKey !== APP_DOWNLOAD_KEY)
    && (routeKey !== APP_DOWNLOAD_PARTNER_PROGRAM_KEY);

  const [deepLinkComplete, setDeepLinkComplete] = useState(!shouldAttemptDeepLink);
  
  const killPopup = useCallback(() => {
    window.removeEventListener('pagehide', killPopup);
  }, []);
  
  useEffect(() => {
    window.addEventListener('pagehide', killPopup);
  }, [killPopup]);
  
  useEffect(() => {
    if (isReady && !hasAttemptedDeepLinkOnceRef.current) {
      const asPathFormatted = asPath[0] === '/' ? asPath.substring(1) : asPath;
      const app = `keakie://${ asPathFormatted || 'discover' }`;
      
      if (
        shouldAttemptDeepLink
      ) {
        hasAttemptedDeepLinkOnceRef.current = true;
        window.location = app;
  
        setTimeout(() => {
          setDeepLinkComplete(true);
        }, 25);
      } else {
        hasAttemptedDeepLinkOnceRef.current = true;
      }
    }
  }, [asPath, isReady, shouldAttemptDeepLink]);

  const value = useMemo(() => ({
    shouldAttemptDeepLink,
    deepLinkComplete
  }), [deepLinkComplete, shouldAttemptDeepLink]);

  return <Provider value={ value }>{ children }</Provider>;
};

DeepLinksProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default DeepLinksProvider;