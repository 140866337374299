import {
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';

const SimpleAppStateProvider = ({ children }) => {
  const [volumeBarOpen, setVolumeBarOpen] = useState(false);
  const [latestSearchTerm, setLatestSearchTerm] = useState('');

  const value = useMemo(() => ({
    volumeBarOpen,
    setVolumeBarOpen,
    latestSearchTerm,
    setLatestSearchTerm
  }), [
    volumeBarOpen,
    setVolumeBarOpen,
    latestSearchTerm,
    setLatestSearchTerm
  ]);

  return <Provider value={ value }>{ children }</Provider>;
};

SimpleAppStateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default SimpleAppStateProvider;