import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';

const MainScrollElementProvider = ({ children }) => {
  const ref = useRef();

  const value = useMemo(() => ({ ref }), [ref]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

MainScrollElementProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default MainScrollElementProvider;