import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import CONFIG from '@/config/global';
import { isBrowser } from '@/helpers/browser';

const { localStorage: { cacheVersion } } = CONFIG;

const maxAge = 1000 * 60 * 60 * 24; // 24 hours

export const createQueryClient = () => {
  const queryClient = new QueryClient({ defaultOptions: { queries: { cacheTime: maxAge } } });

  const localStoragePersistor = isBrowser()
    ? createSyncStoragePersister({ storage: window.localStorage })
    : undefined;
  
  return {
    client: queryClient,
    persister: localStoragePersistor,
    maxAge,
    buster: (cacheVersion || '').split('.')[1] || '0'
  };
};